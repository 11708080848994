import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MainCard from "../../components/MainCard";
import { COLOR } from "../../styles/color";
import { formatNumber } from "../../utils/number";

const DistributorInfo = ({ distributor, wallet, isDisplayWallet }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={isDisplayWallet ? 7 : 12}>
        <MainCard title={t("distributorInfo")} sx={{ height: "100%" }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{ padding: "4px 12px 12px", width: "50%" }}
                  >
                    <Typography color={COLOR.black[64]} fontSize="14px">
                      {t("fullName")}
                    </Typography>
                    <Typography fontWeight={500}>
                      {distributor.metadata?.name || distributor.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ padding: "4px 12px 12px" }}>
                    <Typography color={COLOR.black[64]} fontSize="14px">
                      {t("citizenIdentification")}
                    </Typography>
                    <Typography fontWeight={500}>
                      {distributor.metadata?.citizenIdentification || "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" sx={{ padding: "12px" }}>
                    <Typography color={COLOR.black[64]} fontSize="14px">
                      {t("phoneNumber")}
                    </Typography>
                    <Typography fontWeight={500}>
                      {distributor.phoneNumber}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ padding: "12px" }}>
                    <Typography color={COLOR.black[64]} fontSize="14px">
                      {t("email")}
                    </Typography>
                    <Typography fontWeight={500}>
                      {distributor.metadata?.email}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={2} sx={{ padding: "12px" }}>
                    <Typography color={COLOR.black[64]} fontSize="14px">
                      {t("address")}
                    </Typography>
                    <Typography fontWeight={500}>
                      {[
                        distributor.metadata?.address?.addressDetail,
                        distributor.metadata?.address?.ward,
                        distributor.metadata?.address?.district,
                        distributor.metadata?.address?.province,
                      ]
                        .filter((item) => item)
                        .join(", ")}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" sx={{ padding: "12px" }}>
                    <Typography color={COLOR.black[64]} fontSize="14px">
                      {t("region")}
                    </Typography>
                    <Typography fontWeight={500}>
                      {t("region")} {distributor.region}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ padding: "12px" }}>
                    <Typography color={COLOR.black[64]} fontSize="14px">
                      {t("supervisor")}
                    </Typography>
                    <Typography fontWeight={500}>
                      {distributor.supervisor?.metadata?.name ||
                        distributor.supervisor?.name ||
                        "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      </Grid>
      {isDisplayWallet && (
        <Grid item sm={12} md={5}>
          <MainCard title={t("wallet")} sx={{ height: "100%" }}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ padding: "4px 12px 12px", width: "50%" }}
                    >
                      <Typography color={COLOR.black[64]} fontSize="14px">
                        {t("paymentWallet")}
                      </Typography>
                      <Typography fontWeight={500} fontSize="14px">
                        {t("creditLimit")}:{" "}
                        {formatNumber(distributor.creditLimit || 0)}
                      </Typography>
                      <Typography fontWeight={500}>
                        {formatNumber(wallet.paymentBalance)} VND
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" sx={{ padding: "12px" }}>
                      <Typography color={COLOR.black[64]} fontSize="14px">
                        {t("freightCompensationWallet")}
                      </Typography>
                      <Typography fontWeight={500}>
                        {formatNumber(wallet.freightCompensationBalance)} VND
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" sx={{ padding: "12px" }}>
                      <Typography color={COLOR.black[64]} fontSize="14px">
                        {t("bonusWallet")}
                      </Typography>
                      <Typography fontWeight={500}>
                        {formatNumber(wallet.bonusBalance)} VND
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" sx={{ padding: "12px" }}>
                      <Typography color={COLOR.black[64]} fontSize="14px">
                        {t("commodityCompensationWallet")}
                      </Typography>
                      <Typography fontWeight={500}>
                        {formatNumber(wallet.commodityCompensationBalance)} VND
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </MainCard>
        </Grid>
      )}
    </Grid>
  );
};

export default DistributorInfo;
