import { useTranslation } from "react-i18next";
import { Edit, LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import { DIALOG_TYPE } from "../../constants";
import ImagePreview from "../../components/ImagePreview";

const CategoryList = ({
  loading,
  paging,
  handleChangePage,
  categories,
  handleOpenDialog,
  handleOpenPopup,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("image"),
      align: "center",
      field: "image",
      render: (row) => (
        <ImagePreview
          galleryID="custom-image-gallery-12345"
          images={[
            {
              url: row.image,
            },
          ]}
        />
      ),
    },

    {
      title: t("name"),
      align: "left",
      field: "name",
      sortable: true,
    },
    {
      title: t("createReport"),
      align: "left",
      render: (row) => (
        <>
          {row.noReport ? (
            <Typography sx={{ color: COLOR.error.base }}>{t("n")}</Typography>
          ) : (
            <Typography sx={{ color: COLOR.success.base }}>{t("y")}</Typography>
          )}
        </>
      ),
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => (
        <>
          {row.active ? (
            <Typography sx={{ color: COLOR.success.base }}>
              {t("active")}
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.error.base }}>
              {t("inactive")}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  onClick={() => handleOpenDialog(DIALOG_TYPE.UPDATE, row)}
                  color="edit"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={row.active ? t("lock") : t("unlock")}>
              <span>
                <IconButton
                  onClick={() => handleOpenPopup(row)}
                  color={row.active ? "error" : "success"}
                >
                  {row.active ? <LockOutlined /> : <LockOpenOutlined />}
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={categories}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default CategoryList;
