import { API_URL } from "../configs";
import { omitIsNil } from "../utils/object";

export const downloadFile = async ({ url, params, headers, fileName }) => {
  const response = await fetch(
    `${API_URL}/api/v1${url}?${new URLSearchParams(
      omitIsNil(params)
    ).toString()}`,
    {
      headers: new Headers(headers),
    }
  );
  const blob = await response.blob();

  const downloadLink = document.createElement("a");
  const downloadUrl = URL.createObjectURL(blob);

  downloadLink.style.display = "none";
  downloadLink.href = downloadUrl;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  URL.revokeObjectURL(downloadUrl);
  downloadLink.remove();
};
