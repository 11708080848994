import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

import apis from "../../../apis";
import { StyledTable } from "./index.style";
import { Add, DeleteOutlineOutlined, SaveOutlined } from "@mui/icons-material";
import { validateRequired } from "../../../utils/validate";
import CurrencyInput from "../../../components/CurrencyInput";

const INIT_RATE = {
  productId: null,
  price: "",
  discountRate: "",
  systemSalaryRate: "",
  freightCompensation: "",
  freightCollect: "",
};

const DistributorRate = ({ distributor }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsError, setItemsError] = useState([]);
  const [products, setProducts] = useState([]);
  const [updating, setUpdating] = useState(false);

  const renderProductOptions = (id) => {
    const product = products.find((d) => d.id === id);
    if (!product) return "";
    return `[${product.code}] ${product.name}`;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const newItem = { ...items[index], [field]: value };
    if (field === "productId" && value) {
      const product = products.find((d) => d.id === value);
      if (product) newItem.price = product.price;
    }
    const newItems = [...items];
    newItems[index] = newItem;
    setItems(newItems);

    let newItemError = { ...itemsError[index], [field]: false };
    if (Object.values(newItem).every((value) => !validateRequired(value)))
      newItemError = INIT_RATE;
    const newItemsError = [...itemsError];
    newItemsError[index] = newItemError;
    setItemsError(newItemsError);
  };

  const handleAddRate = () => {
    setItems((prev) => [...prev, INIT_RATE]);
    setItemsError((prev) => [...prev, INIT_RATE]);
  };

  const handleRemoveRate = (index) => {
    const itemsTmp = [...items];
    itemsTmp.splice(index, 1);
    const itemsErrorTmp = [...itemsError];
    itemsErrorTmp.splice(index, 1);
    setItems(itemsTmp);
    setItemsError(itemsErrorTmp);
  };

  const validateItem = (event, initName) => {
    const name = event ? event.target.name : initName;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const value = items[index][field];
    let isError = false;
    if (!Object.values(items[index]).every((value) => !validateRequired(value)))
      if (!validateRequired(value)) {
        isError = true;
      } else {
        if (["discountRate", "systemSalaryRate"].includes(field)) {
          const v = +value;
          if (v > 100) isError = true;
        }
      }

    const newItemsErrors = [...itemsError];
    newItemsErrors[index] = { ...itemsError[index], [field]: isError };
    setItemsError(newItemsErrors);

    return !isError;
  };

  const validate = () =>
    items.every(
      (item, index) =>
        Object.values(item).every((value) => !validateRequired(value)) ||
        (validateItem(null, index + ".productId") &&
          validateItem(null, index + ".price") &&
          validateItem(null, index + ".discountRate") &&
          validateItem(null, index + ".systemSalaryRate") &&
          validateItem(null, index + ".freightCollect") &&
          validateItem(null, index + ".freightCompensation"))
    );

  const handleUpdateRates = async () => {
    if (!validate()) {
      toast.error(t("dataInvalid"));
      return;
    }

    setUpdating(true);
    try {
      const data = {
        distributorId: distributor.id,
        rates: items.filter((item) =>
          Object.values(item).every((value) => validateRequired(value))
        ),
      };
      await apis.exceptionRate.updateExceptionRates(data);
      toast.success(t("updateRateSuccess"));
      handleInit();
    } catch (error) {
      toast.error(t(error.message));
    }
    setUpdating(false);
  };

  const getExceptionRates = async () => {
    try {
      const { result = [] } = await apis.exceptionRate.getExceptionRates(
        distributor.id
      );
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const getProducts = async () => {
    try {
      const { result = [] } = await apis.product.getProducts();
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const handleInit = async () => {
    const [initProducts, rates] = await Promise.all([
      getProducts(),
      getExceptionRates(),
    ]);
    if (!initProducts || !rates) {
      setError(true);
      return;
    }

    setProducts(initProducts);

    const initItems = rates.map((rate) => ({
      productId: rate.productId,
      price: rate.price,
      discountRate: rate.discountRate,
      systemSalaryRate: rate.systemSalaryRate,
      freightCompensation: rate.freightCompensation,
      freightCollect: rate.freightCollect,
    }));

    if (!initItems.length) initItems.push(INIT_RATE);

    const initItemsError = initItems.map(() => INIT_RATE);

    setItems(initItems);
    setItemsError(initItemsError);
  };

  const init = async () => {
    setLoading(true);
    await handleInit();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  if (loading)
    return (
      <Box padding={2}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box padding={2}>
        <Typography variant="body1" textAlign="center">
          {t("noData")} !
        </Typography>
      </Box>
    );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        mb={2}
      >
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          startIcon={<SaveOutlined />}
          color="success"
          onClick={handleUpdateRates}
          loading={updating}
        >
          {t("save")}
        </LoadingButton>
      </Stack>
      <StyledTable>
        <Table className="table">
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="header-cell" align="center">
                {t("no")}
              </TableCell>
              <TableCell className="header-cell cell-product" align="center">
                {t("product")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("price")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("discountRate")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("systemSalaryRate")}
              </TableCell>

              <TableCell className="header-cell" align="center">
                {t("freightCollect")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("freightCompensation")}
              </TableCell>
              <TableCell className="header-cell" align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {items.map((item, index, currItems) => (
              <TableRow
                key={index}
                className="body-row"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="body-cell cell-no" align="center">
                  {index + 1}
                </TableCell>
                <TableCell className="body-cell cell-product">
                  <Autocomplete
                    size="small"
                    value={item.productId}
                    options={products
                      .filter(
                        (product) =>
                          !currItems
                            .map(({ productId }) => productId)
                            .includes(product.id) && product.active
                      )
                      .map((product) => product.id)
                      .concat(item.productId ? [item.productId] : [])}
                    getOptionLabel={(option) => renderProductOptions(option)}
                    filterSelectedOptions
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: index + ".productId", value: newValue },
                      })
                    }
                    onBlur={() => validateItem(null, index + ".productId")}
                    renderOption={(props, key) => (
                      <MenuItem value={key} {...props}>
                        {renderProductOptions(key)}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        error={!!itemsError[index]?.productId}
                        helperText={itemsError[index]?.productId}
                        fullWidth
                      />
                    )}
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.price}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.price}
                    error={!!itemsError[index]?.price}
                    name={index + ".price"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">&#8363;</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.discountRate}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.discountRate}
                    error={!!itemsError[index]?.discountRate}
                    name={index + ".discountRate"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.systemSalaryRate}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.systemSalaryRate}
                    error={!!itemsError[index]?.systemSalaryRate}
                    name={index + ".systemSalaryRate"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.freightCollect}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.freightCollect}
                    error={!!itemsError[index]?.freightCollect}
                    name={index + ".freightCollect"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">&#8363;</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.freightCompensation}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.freightCompensation}
                    error={!!itemsError[index]?.freightCompensation}
                    name={index + ".freightCompensation"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">&#8363;</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-action" align="center">
                  <Tooltip title={t("delete")}>
                    <span>
                      <IconButton
                        onClick={() => handleRemoveRate(index)}
                        color="error"
                      >
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTable>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={2.5}
      >
        <Button variant="outlined" onClick={handleAddRate} startIcon={<Add />}>
          {t("addException")}
        </Button>
      </Stack>
    </>
  );
};

export default DistributorRate;
