import { useTranslation } from "react-i18next";

import Table from "../../components/Table";

const WorkReportList = ({ loading, paging, handleChangePage, data }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      align: "left",
      render: (row) => row.name,
    },
    {
      title: t("phoneNumber"),
      align: "left",
      render: (row) => row.phoneNumber,
    },
    {
      title: t("role"),
      align: "left",
      render: (row) => t(row.role?.toLowerCase()),
    },
    {
      title: t("totalVisitStores"),
      align: "right",
      render: (row) => row.stats?.totalVisitStores?.toLocaleString() || 0,
    },
    {
      title: t("totalRegisterPosm"),
      align: "right",
      render: (row) => row.stats?.totalRegisterPosm?.toLocaleString() || 0,
    },
    {
      title: t("totalMaintainPosm"),
      align: "right",
      render: (row) => row.stats?.totalMaintainPosm?.toLocaleString() || 0,
    },
    {
      title: t("totalRegisterBanner"),
      align: "right",
      render: (row) => row.stats?.totalRegisterBanner?.toLocaleString() || 0,
    },
    {
      title: t("totalNewDevelopStore"),
      align: "right",
      render: (row) => row.stats?.totalNewDevelopStore?.toLocaleString() || 0,
    },
    {
      title: t("totalRegisterStore"),
      align: "right",
      render: (row) => row.stats?.totalRegisterStore?.toLocaleString() || 0,
    },
    {
      title: t("averageSellingProducts"),
      align: "right",
      render: (row) => row.stats?.averageSellingProducts?.toLocaleString() || 0,
    },
    {
      title: t("totalNewDistributor"),
      align: "right",
      render: (row) => row.stats?.totalNewDistributor?.toLocaleString() || 0,
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        total={paging.total}
        page={paging.page}
        limit={paging.limit}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default WorkReportList;
