import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import ImagePreview from "../../components/ImagePreview";
import {
  MAINTAIN_POSM_PROBLEMS,
  MAINTAIN_POSM_PROBLEMS_LABEL,
  MAINTAIN_POSM_PRODUCT_STATUS_LABEL,
  MAINTAIN_POSM_STATUS,
  MAINTAIN_POSM_STATUS_LABEL,
} from "../../constants/storeAction";
import { COLOR } from "../../styles/color";
import { validateRequired as checkRequired } from "../../utils/validate";

const MaintainPOSMDialog = ({
  open,
  handleClose,
  data,
  reload,
  users,
  stores,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");

  const renderStoreOptions = (id) => {
    const store = stores.find((s) => s.id === id);
    return store?.name || "";
  };

  const renderUserOptions = (id) => {
    const user = users.find((u) => u.id === id);
    if (!user) return "";
    return `${user.metadata?.name || user.name}`;
  };

  const handleCloseDialog = () => {
    handleClose();
    setStatus("");
    setStatusError("");
  };

  const validateStatusRequired = () => {
    if (!checkRequired(status)) {
      setStatusError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validate = () => validateStatusRequired();

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.storeAction.changeStatusStoreAction(data.id, {
        status,
      });
      handleCloseDialog();
      reload();
      toast.success(t("reviewMaintainPosmSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  return (
    <Dialog
      title={t("reviewMaintainPosm")}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(data).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("problem")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {t(
                  MAINTAIN_POSM_PROBLEMS_LABEL[
                    data.detail.problem || MAINTAIN_POSM_PROBLEMS.NO_PROBLEM
                  ]
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("detail")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {data.detail.detail}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500}>{t("productStatus")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {t(
                  MAINTAIN_POSM_PRODUCT_STATUS_LABEL[data.detail.productStatus]
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("store")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {renderStoreOptions(data.storeId)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("createdBy")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {renderUserOptions(data.userId)}
              </Typography>
            </Grid>
            {!!data.detail.images && !!data.detail.images?.length && (
              <Grid item xs={12} sm={12}>
                <Typography fontWeight={500} mb={1}>
                  {t("image")}:
                </Typography>
                <ImagePreview
                  galleryID="custom-image-gallery-12345"
                  images={
                    data.detail?.images?.map((url) => ({
                      url,
                    })) || []
                  }
                  height="100px"
                  previewWidth={900}
                  previewHeight={1600}
                />
              </Grid>
            )}
            {[
              MAINTAIN_POSM_STATUS.PROCESSED || MAINTAIN_POSM_STATUS.PROCESSING,
            ].includes(data.detail.status) && (
              <Grid item xs={12} sm={12}>
                <Typography fontWeight={500}>{t("status")}:</Typography>
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {t(MAINTAIN_POSM_STATUS_LABEL[data.detail.status])}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {[MAINTAIN_POSM_STATUS.NEW, MAINTAIN_POSM_STATUS.SEEN].includes(
          data.detail?.status
        ) && (
          <>
            <Divider />
            <Box mt={3}>
              <Typography fontWeight={500} mb={1}>
                {t("review")} <span style={{ color: COLOR.error.base }}>*</span>
                :
              </Typography>
              <FormControl fullWidth error={!!statusError}>
                <Select
                  value={status}
                  size="small"
                  onChange={(event) => {
                    setStatus(event.target.value);
                    setStatusError("");
                  }}
                >
                  <MenuItem value={MAINTAIN_POSM_STATUS.PROCESSED}>
                    {t(
                      MAINTAIN_POSM_STATUS_LABEL[MAINTAIN_POSM_STATUS.PROCESSED]
                    )}
                  </MenuItem>
                  <MenuItem value={MAINTAIN_POSM_STATUS.PROCESSING}>
                    {t(
                      MAINTAIN_POSM_STATUS_LABEL[
                        MAINTAIN_POSM_STATUS.PROCESSING
                      ]
                    )}
                  </MenuItem>
                </Select>
                <FormHelperText>{statusError}</FormHelperText>
              </FormControl>
            </Box>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {[MAINTAIN_POSM_STATUS.NEW, MAINTAIN_POSM_STATUS.SEEN].includes(
          data.detail?.status
        ) && (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdate}
          >
            {t("confirm")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MaintainPOSMDialog;
