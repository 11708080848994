import { useTranslation } from "react-i18next";
import { Edit, VisibilityOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import dayjs from "dayjs";

import Table from "../../components/Table";
import {
  DATE_TIME_FORMAT,
  DIALOG_TYPE,
  OA_MESSAGE_STATUS,
  OA_MESSAGE_STATUS_LABEL,
  SEND_OA_MESSAGE_MODE,
  SEND_OA_MESSAGE_MODE_LABEL,
} from "../../constants";

const OaMessageList = ({
  loading,
  paging,
  handleChangePage,
  messages,
  handleOpenDialog,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("oaMessageTemplate"),
      align: "left",
      render: (row) => row.template.title,
    },
    {
      title: t("timeToSend"),
      align: "left",
      render: (row) => t(SEND_OA_MESSAGE_MODE_LABEL[row.sendMode]),
    },
    {
      title: t("scheduledAt"),
      align: "left",
      render: (row) =>
        row.sendMode === SEND_OA_MESSAGE_MODE.SCHEDULER
          ? dayjs(row.scheduledAt).format(DATE_TIME_FORMAT)
          : "-",
    },
    {
      title: t("numberOfReceivers"),
      align: "left",
      render: (row) =>
        row.status === OA_MESSAGE_STATUS.COMPLETED ? row.receivers.length : "-",
    },
    {
      title: t("numberOfFailedReceivers"),
      align: "left",
      render: (row) =>
        row.status === OA_MESSAGE_STATUS.COMPLETED
          ? row.failedReceivers.length
          : "-",
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => t(OA_MESSAGE_STATUS_LABEL[row.status]),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            {[
              OA_MESSAGE_STATUS.SENDING,
              OA_MESSAGE_STATUS.COMPLETED,
              OA_MESSAGE_STATUS.FAILED,
            ].includes(row.status) ? (
              <Tooltip title={t("detail")}>
                <span>
                  <IconButton
                    onClick={() => handleOpenDialog(DIALOG_TYPE.UPDATE, row)}
                    color="primary"
                  >
                    <VisibilityOutlined />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={t("edit")}>
                <span>
                  <IconButton
                    onClick={() => handleOpenDialog(DIALOG_TYPE.UPDATE, row)}
                    color="edit"
                  >
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={messages}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default OaMessageList;
