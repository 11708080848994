import React from "react";
import { useTranslation } from "react-i18next";

import {
  DISTRIBUTOR_ORDER_STATUS_COLORS,
  DISTRIBUTOR_ORDER_STATUS_LABEL,
} from "../constants/order";

const DistributorOrderStatus = ({ status }) => {
  const { t } = useTranslation();

  const color = DISTRIBUTOR_ORDER_STATUS_COLORS[status];

  return (
    <span
      style={{
        fontSize: "14px",
        fontWeight: 600,
        color,
        border: `1px solid ${color}`,
        borderRadius: "5px",
        padding: "4px 8px",
        backgroundColor: "white",
      }}
    >
      {t(DISTRIBUTOR_ORDER_STATUS_LABEL[status])}
    </span>
  );
};

export default DistributorOrderStatus;
