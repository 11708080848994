import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import queryString from "query-string";
import { toast } from "react-toastify";
import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import { ALL, INITIAL_PAGING } from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import OrderList from "./OrderList";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import debounce from "../../utils/debounce";
import { ORDER_STATUS, ORDER_STATUS_MAPPING } from "../../constants/order";

const initialPaging = {
  ...INITIAL_PAGING,
  limit: 20,
};

const Order = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const [paging, setPaging] = useState(initialPaging);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ search: "" });
  const [stores, setStores] = useState([]);
  const [users, setUsers] = useState([]);

  const { addParams } = useSearchParams();

  console.log("stores === ", stores);

  const renderUserOptions = (userId) => {
    const user = users.find((item) => item.id === userId);
    if (!user) return "";
    return user.name;
  };

  const renderStoreOptions = (storeId) => {
    const store = stores.find((item) => item.id === storeId);
    return store?.name;
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: initialPaging.page }));
    addParams({ search: value, page: initialPaging.page });
  };

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleChangeFilter = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
    setPaging((prev) => ({ ...prev, page: initialPaging.page }));
    addParams({ [key]: value, page: initialPaging.page });
  };

  const fetchStores = async () => {
    try {
      const res = await apis.store.getStores();
      const { result = [] } = res;
      setStores(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchSalePeople = async () => {
    try {
      const { result } = await apis.user.getUsers();
      setUsers(result || []);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchOrders = async (newFilter = {}) => {
    setLoading(true);
    try {
      const { page, search, status } = newFilter;
      const condition = {
        page: page || paging.page,
        limit: paging.limit,
        search: search || undefined,
        status: !status || status === ALL ? undefined : status,
        storeId: newFilter.storeId === ALL ? undefined : newFilter.storeId,
        creatorId:
          newFilter.creatorId === ALL ? undefined : newFilter.creatorId,
      };
      const { result } = await apis.order.getOrders(condition);
      setOrders(result.items);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchStores();
    fetchSalePeople();
  }, []);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      search = "",
      page = initialPaging.page,
      ...filterQuery
    } = searchParams;
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchOrders({ search, page: parseInt(page, 10), ...filterQuery });
  }, [location.search]);

  return (
    <>
      <PageTitle title={t("orderManagement")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("orderSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <Autocomplete
              size="small"
              value={filter.storeId}
              options={stores.map((asm) => asm.id)}
              getOptionLabel={(option) => renderStoreOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) =>
                handleChangeFilter("storeId", newValue)
              }
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderStoreOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder={t("store")} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <Autocomplete
              size="small"
              value={filter.creatorId}
              options={users.map((asm) => asm.id)}
              getOptionLabel={(option) => renderUserOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) =>
                handleChangeFilter("creatorId", newValue)
              }
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderUserOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder={t("salePerson")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.status}
                onChange={(e) => handleChangeFilter("status", e.target.value)}
                size="small"
                label={t("status")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {Object.values(ORDER_STATUS).map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(ORDER_STATUS_MAPPING[item])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <OrderList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          orders={orders}
          users={users}
        />
      </MainCard>
    </>
  );
};

export default Order;
