import { useEffect, useState } from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Grid, InputAdornment, Stack, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import { ALL, INITIAL_PAGING } from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import GroupStatementList from "./GroupStatementList";
import debounce from "../../utils/debounce";

const INIT_FILTER = {
  search: "",
  status: ALL,
};

const Bonus = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [loading, setLoading] = useState(false);
  const [groupStatements, setGroupStatements] = useState([]);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const fetchGroupStatements = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        search: newFilter.search,
      };

      const { result } = await apis.groupStatement.getGroupStatements(
        condition
      );
      setGroupStatements(result.groupStatements);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { page = INITIAL_PAGING.page, search = INIT_FILTER.search } =
      searchParams;
    setFilter({ search });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchGroupStatements({ search });
  }, [location.search]);

  return (
    <>
      <PageTitle title={t("statement")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("searchTitle")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            ></Stack>
          </Grid>
        </Grid>
        <GroupStatementList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          groupStatements={groupStatements}
        />
      </MainCard>
    </>
  );
};

export default Bonus;
