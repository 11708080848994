import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import Dialog from "../../components/Dialog";
import apis from "../../apis";
import { COLOR } from "../../styles/color";
import { validateRequired as checkRequired } from "../../utils/validate";
import ROUTE from "../../constants/route";

const CreateOrderDialog = ({ open, handleClose, distributors, categories }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [processing, setProcessing] = useState(false);
  const [distributorId, setDistributorId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [distributorIdError, setDistributorIdError] = useState("");
  const [categoryIdError, setCategoryIdError] = useState("");

  const handleCloseDialog = () => {
    handleClose();
    setDistributorId(null);
    setCategoryId(null);
    setDistributorIdError("");
    setCategoryIdError("");
  };

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.code}. ${
      distributor.metadata?.name || distributor.name
    }`;
  };

  const renderCategoryOptions = (id) => {
    const category = categories.find((c) => c.id === id);
    if (!category) return "";
    return category.name;
  };

  const validateRequired = (field) => {
    if (field === "distributorId" && !checkRequired(distributorId)) {
      setDistributorIdError(t("fieldRequired"));
      return false;
    }

    if (field === "categoryId" && !checkRequired(categoryId)) {
      setCategoryIdError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validate = () =>
    validateRequired("distributorId") && validateRequired("categoryId");

  const handleCreate = async () => {
    if (!validate()) return;
    setProcessing(true);
    try {
      const { result: order } =
        await apis.distributorOrder.createDistributorOrder({
          distributorId,
          categoryId,
        });
      handleCloseDialog();
      history.push(`${ROUTE.DISTRIBUTOR_ORDER}/${order.id}`);
      toast.success(t("addDistributorOrderSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setProcessing(false);
  };

  return (
    <Dialog
      title={t("addDistributorOrder")}
      maxWidth="xs"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Box mb={2}>
          <Typography fontWeight={500} mb={1}>
            {t("chooseDistributors")}{" "}
            <span style={{ color: COLOR.error.base }}>*</span>:
          </Typography>
          <Autocomplete
            size="small"
            value={distributorId}
            options={distributors.filter((d) => d.active).map((d) => d.id)}
            getOptionLabel={(option) => renderDistributorOptions(option)}
            filterSelectedOptions
            onChange={(event, newValue) => {
              setDistributorId(newValue);
              setDistributorIdError("");
            }}
            onBlur={() => validateRequired("distributorId")}
            renderOption={(props, key) => (
              <MenuItem value={key} {...props}>
                {renderDistributorOptions(key)}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!distributorIdError}
                helperText={distributorIdError}
                fullWidth
              />
            )}
          />
        </Box>
        <Box>
          <Typography fontWeight={500} mb={1}>
            {t("category")} <span style={{ color: COLOR.error.base }}>*</span>:
          </Typography>
          <Autocomplete
            size="small"
            value={categoryId}
            options={categories
              .filter((category) => !category.noReport)
              .map((category) => category.id)}
            getOptionLabel={(option) => renderCategoryOptions(option)}
            filterSelectedOptions
            onChange={(event, newValue) => {
              setCategoryId(newValue);
              setCategoryIdError("");
            }}
            onBlur={() => validateRequired("categoryId")}
            renderOption={(props, key) => (
              <MenuItem value={key} {...props}>
                {renderCategoryOptions(key)}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!categoryIdError}
                helperText={categoryIdError}
                fullWidth
              />
            )}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("close")}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={processing}
          onClick={handleCreate}
        >
          {t("confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrderDialog;
