import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Autorenew,
  CalculateOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import apis from "../../apis";
import PageTitle from "../../components/PageTitle";
import ROUTE from "../../constants/route";
import MainCard from "../../components/MainCard";
import CurrencyInput from "../../components/CurrencyInput";
import { StyledTable } from "./index.style";
import {
  GROUP_TRANSACTION_TYPE,
  GROUP_TRANSACTION_TYPE_LABEL,
  TRANSACTION_STATUS,
} from "../../constants/wallet";
import NoData from "../../components/NoData";
import { validateRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";

const INIT_TRANSACTION_ERROR = {
  amount: "",
};

const BonusDetail = () => {
  const { t } = useTranslation();
  const { bonusId } = useParams();

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [originalTransactions, setOriginalTransactions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionsError, setTransactionsError] = useState([]);
  const [error, setError] = useState(false);
  const [groupTransaction, setGroupTransaction] = useState({});
  const [categories, setCategories] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([
    { title: t("bonus"), path: ROUTE.BONUS },
  ]);
  const [calculating, setCalculating] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const newTransaction = { ...transactions[index], [field]: value };
    const newTransactions = [...transactions];
    newTransactions[index] = newTransaction;
    setTransactions(newTransactions);

    let newTransactionError = { ...transactionsError[index], [field]: false };
    const newTransactionsError = [...transactionsError];
    newTransactionsError[index] = newTransactionError;
    setTransactionsError(newTransactionsError);
  };

  const validateItem = (event, initName) => {
    const name = event ? event.target.name : initName;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const value = transactions[index][field];
    let isError = false;
    if (!validateRequired(value)) {
      isError = true;
    }

    const newTransactionsError = [...transactionsError];
    newTransactionsError[index] = {
      ...transactionsError[index],
      [field]: isError,
    };
    setTransactionsError(newTransactionsError);

    return !isError;
  };

  const validate = () =>
    transactions.every((_, index) => validateItem(null, index + ".amount"));

  const handleReset = () => {
    setTransactions(originalTransactions);
    setTransactionsError(
      originalTransactions.map(() => INIT_TRANSACTION_ERROR)
    );
  };

  const renderTitle = (groupTransaction) => {
    const { title, month, quarter, year } = groupTransaction;
    if (title) return title;
    if (quarter) return `${t("quarter")} ${quarter}/${year}`;
    if (month) return `${t("month")} ${month}/${year}`;
    return `${t("year")} ${year}`;
  };

  const handleApprove = async () => {
    if (!validate()) {
      toast.error(t("dataInvalid"));
      return;
    }

    setUpdating(true);
    try {
      await apis.transaction.approveBonus({
        groupTransactionId: bonusId,
        transactions: transactions.map(
          ({ id: transactionId, userId, amount }) => ({
            transactionId,
            userId,
            amount,
          })
        ),
      });
      getGroupTransaction();
      toast.success(t("approveBonusSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setUpdating(false);
  };

  const handleReCalculate = async () => {
    setCalculating(true);
    try {
      await apis.groupTransaction.reCalculate(bonusId);
      getGroupTransaction();
      toast.success(t("reCalculateBonusSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setCalculating(false);
  };

  const getGroupTransaction = async () => {
    try {
      const { result = {} } = await apis.groupTransaction.getGroupTransaction(
        bonusId
      );
      const { transactions: ts, ...gt } = result;
      setGroupTransaction(gt);
      setOriginalTransactions(ts);
      setTransactions(ts);
      setTransactionsError(ts.map(() => INIT_TRANSACTION_ERROR));
      setBreadcrumbs((prev) => [prev[0], { title: renderTitle(result) }]);
    } catch (error) {
      toast.error(t(error.message));
      setError(true);
      setBreadcrumbs([{ title: t("back"), path: ROUTE.BONUS }]);
    }
  };

  const getCategories = async () => {
    try {
      const { result = [] } = await apis.category.getCategories();
      setCategories(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const init = async () => {
    setLoading(true);
    await Promise.all([getGroupTransaction(), getCategories()]);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const isCompleted = useMemo(
    () => groupTransaction.status === TRANSACTION_STATUS.COMPLETED,
    [groupTransaction]
  );

  const renderCategory = (id) => {
    const category = categories.find((c) => c.id === id);
    return category?.name || "-";
  };

  const renderTransactionStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.PENDING:
        return (
          <Typography
            fontWeight={600}
            fontSize="18px"
            color={COLOR.warning.base}
          >
            {t(status)}
          </Typography>
        );
      case TRANSACTION_STATUS.COMPLETED:
        return (
          <Typography
            fontWeight={600}
            fontSize="18px"
            color={COLOR.success.base}
          >
            {t(status)}
          </Typography>
        );
      default:
        return <Typography>{t(status)}</Typography>;
    }
  };

  const renderContent = () => (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
        mb={2}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Typography fontWeight={500} fontSize="18px">
            {t("source")}:
          </Typography>
          <Typography fontWeight={600} fontSize="18px" color="primary">
            {t(GROUP_TRANSACTION_TYPE_LABEL[groupTransaction.type])}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          mb={2}
        >
          <Typography fontWeight={500} fontSize="18px">
            {t("status")}:
          </Typography>
          {renderTransactionStatus(groupTransaction.status)}
        </Stack>
      </Stack>
      <StyledTable>
        <Table className="table">
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="header-cell cell-no" align="center">
                {t("no")}
              </TableCell>
              <TableCell
                className="header-cell cell-distributor"
                align="center"
              >
                {t("distributor")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("category")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("bonusTarget")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("sales")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("bonusRate")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("bonusAmount")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {!!transactions.length &&
              transactions
                .sort((t1, t2) => {
                  const code1 = t1?.user?.code ? +t1?.user?.code : 0;
                  const code2 = t2?.user?.code ? +t2?.user?.code : 0;
                  return code1 - code2;
                })
                .map((transaction, index) => (
                  <TableRow
                    key={index}
                    className="body-row"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="body-cell" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell className="body-cell">
                      {transaction?.user?.code}.{" "}
                      {transaction?.user?.metadata?.name ||
                        transaction?.user?.name ||
                        "-"}
                    </TableCell>
                    <TableCell className="body-cell cell-amount" align="center">
                      {renderCategory(transaction.categoryId)}
                    </TableCell>
                    <TableCell className="body-cell cell-amount">
                      <CurrencyInput
                        customInput={Input}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        inputProps={{ min: 0, style: { textAlign: "center" } }}
                        value={transaction.target}
                        disableUnderline={true}
                        variant="standard"
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            &#8363;
                          </InputAdornment>
                        }
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell className="body-cell cell-amount">
                      <CurrencyInput
                        customInput={Input}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        inputProps={{ min: 0, style: { textAlign: "center" } }}
                        value={transaction.totalSales}
                        disableUnderline={true}
                        variant="standard"
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            &#8363;
                          </InputAdornment>
                        }
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell className="body-cell cell-rate">
                      <CurrencyInput
                        customInput={Input}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        inputProps={{ min: 0, style: { textAlign: "center" } }}
                        value={transaction.rate}
                        disableUnderline={true}
                        variant="standard"
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell className="body-cell cell-amount .cell-hover">
                      <CurrencyInput
                        customInput={Input}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        inputProps={{ min: 0, style: { textAlign: "center" } }}
                        value={transaction.amount}
                        variant="standard"
                        disableUnderline={!transactionsError[index]?.amount}
                        error={!!transactionsError[index]?.amount}
                        name={index + ".amount"}
                        fullWidth
                        onChange={handleChange}
                        onBlur={validateItem}
                        endAdornment={
                          <InputAdornment position="end">
                            &#8363;
                          </InputAdornment>
                        }
                        disabled={isCompleted}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </StyledTable>
      {!transactions.length && <NoData />}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        mt={5}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={handleReset}
          startIcon={<Autorenew />}
          disabled={isCompleted}
        >
          {t("refresh")}
        </Button>
        {groupTransaction.type === GROUP_TRANSACTION_TYPE.SCHEDULER && (
          <LoadingButton
            variant="outlined"
            loadingPosition="start"
            startIcon={<CalculateOutlined />}
            color="primary"
            onClick={handleReCalculate}
            loading={calculating}
            disabled={isCompleted}
          >
            {t("reCalculate")}
          </LoadingButton>
        )}
        <LoadingButton
          variant="contained"
          loadingPosition="start"
          startIcon={<SaveOutlined />}
          color="success"
          onClick={handleApprove}
          loading={updating}
          disabled={isCompleted}
        >
          {t("save")}
        </LoadingButton>
      </Stack>
    </>
  );

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} />
      <MainCard>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Box padding={2}>
            <Typography variant="body1" textAlign="center">
              {t("bonusNotFound")} !
            </Typography>
          </Box>
        ) : (
          renderContent()
        )}
      </MainCard>
    </>
  );
};

export default BonusDetail;
