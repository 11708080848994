import { SERVICE } from "../constants";
import api from "./api";

const getWallet = async (userId) => {
  const res = await api({
    method: "GET",
    url: `/wallets/users/${userId}`,
    source: SERVICE.VELA_API,
  });
  return res;
};

export { getWallet };
