const WALLET_TYPE = {
  PAYMENT: "PAYMENT",
  FREIGHT_COMPENSATION: "FREIGHT_COMPENSATION",
  BONUS: "BONUS",
  COMMODITY_COMPENSATION: "COMMODITY_COMPENSATION",
};

const TRANSACTION_STATUS = {
  PENDING: "PENDING",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
  COMPLETED: "COMPLETED",
};

const TRANSACTION_TYPE = {
  PAY_IN: "PAY_IN",
  PAY_OUT: "PAY_OUT",
  REFUND: "REFUND",
  WITHDRAW: "WITHDRAW",
};

const PAYMENT_METHOD = {
  BANK: "BANK",
  CASH: "CASH",
};

const GROUP_TRANSACTION_TYPE = {
  UPLOAD: "UPLOAD",
  SCHEDULER: "SCHEDULER",
};

const GROUP_TRANSACTION_TYPE_LABEL = {
  [GROUP_TRANSACTION_TYPE.UPLOAD]: "upload",
  [GROUP_TRANSACTION_TYPE.SCHEDULER]: "autoScheduler",
};

module.exports = {
  WALLET_TYPE,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  PAYMENT_METHOD,
  GROUP_TRANSACTION_TYPE,
  GROUP_TRANSACTION_TYPE_LABEL,
};
