import { Add, DownloadOutlined, Search } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import { ALL, INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  WALLET_TYPE,
} from "../../constants/wallet";
import useSearchParams from "../../hooks/useSearchParams";
import debounce from "../../utils/debounce";
import TransactionList from "./TransactionList";
import CreateTransactionDialog from "./CreateTransaction";
import { LoadingButton } from "@mui/lab";
import { downloadFile } from "../../utils/download";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import DatePickerRange from "../../components/PickDateRange";

const INIT_FILTER = {
  search: "",
  bankBin: ALL,
  status: ALL,
  walletType: ALL,
  userId: ALL,
  startDate: dayjs().startOf("month"),
  endDate: dayjs().endOf("month"),
};

const Transaction = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [reload, setReload] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [distributors, setDistributors] = useState([]);

  const { addParams } = useSearchParams();

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.metadata?.name || distributor?.name}`;
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const fetchTransactions = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        type: TRANSACTION_TYPE.PAY_IN,
        limit: paging.limit,
        page: newFilter.page,
        search: newFilter.search || undefined,
        userId: newFilter.userId === ALL ? undefined : newFilter.userId,
        status: newFilter.status === ALL ? undefined : newFilter.status,
        walletType:
          newFilter.walletType === ALL ? undefined : newFilter.walletType,
        startTime: newFilter.startDate,
        endTime: newFilter.endDate,
      };

      const { result } = await apis.transaction.getTransactions(condition);
      setTransactions(result.transactions);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchDistributors = async () => {
    try {
      const { result } = await apis.user.getUsers({
        pageType: PAGE_TYPE.DISTRIBUTOR,
        sort: ["code_asc"],
      });
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleOpenDialog = () => setOpenDialog(true);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, status: value }));
    addParams({ status: value });
  };

  const handleChangeWalletType = (event) => {
    console.log("handle change wallet type", event.target);
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, walletType: value }));
    addParams({ walletType: value });
  };

  const handleChangeUserId = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, userId: value }));
    addParams({ userId: value });
  };

  const handleChangeDate = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      startDate: newValue[0],
      endDate: newValue[1],
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(newValue[0]).format("YYYY-MM-DD"),
      endDate: dayjs(newValue[1]).format("YYYY-MM-DD"),
    });
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const {
        search = INIT_FILTER.search,
        bankBin = INIT_FILTER.bankBin,
        status = INIT_FILTER.status,
        walletType = INIT_FILTER.walletType,
        userId = INIT_FILTER.userId,
        startDate = INIT_FILTER.startDate,
        endDate = INIT_FILTER.endDate,
      } = searchParams;

      await downloadFile({
        url: "/transactions/download",
        params: {
          type: TRANSACTION_TYPE.PAY_IN,
          search: search || undefined,
          bankBin: bankBin === ALL ? undefined : bankBin,
          status: status === ALL ? undefined : status,
          walletType: walletType === ALL ? undefined : walletType,
          userId: userId === ALL ? undefined : userId,
          startTime: startDate,
          endTime: endDate,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `transactions_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }
    setDownloading(false);
  };

  useEffect(() => {
    fetchDistributors();
  }, []);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      search = INIT_FILTER.search,
      status,
      walletType,
      userId,
      startDate = dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
      endDate = dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
    } = searchParams;
    setFilter({ search, status, walletType, userId, startDate, endDate });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchTransactions({
      search,
      status,
      walletType,
      userId,
      page: parseInt(page, 10),
      startDate,
      endDate,
    });
  }, [location.search, reload]);

  return (
    <>
      <PageTitle title={t("depositTicket")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={8} md={8} lg={2}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("transactionSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <Autocomplete
              size="small"
              value={filter.userId}
              options={distributors.map((asm) => asm.id)}
              getOptionLabel={(option) => renderDistributorOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) =>
                handleChangeUserId({ target: { value: newValue } })
              }
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderDistributorOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder={t("destinationWallet")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("walletType")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.walletType}
                onChange={handleChangeWalletType}
                size="small"
                label={t("walletType")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                <MenuItem value={WALLET_TYPE.PAYMENT}>
                  {t("paymentWallet")}
                </MenuItem>
                <MenuItem value={WALLET_TYPE.COMMODITY_COMPENSATION}>
                  {t("commodityCompensationWallet")}
                </MenuItem>
                <MenuItem value={WALLET_TYPE.FREIGHT_COMPENSATION}>
                  {t("freightCompensationWallet")}
                </MenuItem>
                <MenuItem value={WALLET_TYPE.BONUS}>
                  {t("bonusWallet")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.status}
                onChange={handleChangeStatus}
                size="small"
                label={t("status")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {Object.values(TRANSACTION_STATUS).map((status) => (
                  <MenuItem key={status} value={status}>
                    {t(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <DatePickerRange
              value={[filter.startDate, filter.endDate]}
              onChange={handleChangeDate}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleOpenDialog}
              >
                {t("createDepositTicket")}
              </Button>
              <LoadingButton
                loading={downloading}
                loadingPosition="start"
                variant="outlined"
                startIcon={<DownloadOutlined />}
                disabled={!transactions.length}
                onClick={handleDownload}
              >
                {t("download")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        <TransactionList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          transactions={transactions}
          reload={() => setReload((prev) => !prev)}
        />
      </MainCard>

      <CreateTransactionDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        reload={() => setReload((prev) => !prev)}
      />
    </>
  );
};

export default Transaction;
