import Info from "./Info";
import Sales from "./Sales";
import Rate from "./Rate";
import Wallet from "./Wallet";
import ExceptionRate from "./ExceptionRate";

const tabs = [
  {
    name: "basicInfo",
    value: "basic-info",
    component: <Info />,
  },
  {
    name: "sales",
    value: "sales",
    component: <Sales />,
  },
  {
    name: "rate",
    value: "rate",
    component: <Rate />,
  },
  {
    name: "exception",
    value: "exception-rate",
    component: <ExceptionRate />,
  },
  {
    name: "wallet",
    value: "wallet",
    component: <Wallet />,
  },
];

export default tabs;
