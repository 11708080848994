import { Block, ThumbUpAltOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apis from "../../apis";
import ImagePreview from "../../components/ImagePreview";
import Table from "../../components/Table";
import {
  PAYMENT_METHOD,
  TRANSACTION_STATUS,
  WALLET_TYPE,
} from "../../constants/wallet";
import { getPaymentQrLink } from "../../services/paymentQr";
import { COLOR } from "../../styles/color";
import Popup from "../../components/Popup";
import { useState } from "react";
import moment from "moment";

const POPUP_TYPE = {
  CONFIRM_TRANSACTION: "confirm",
  CANCEL_TRANSACTION: "cancel",
};

const TransactionList = ({
  loading,
  paging,
  handleChangePage,
  transactions,
  reload,
}) => {
  const { t } = useTranslation();

  const [popup, setPopup] = useState({
    open: false,
    type: "",
  });
  const [transaction, setTransaction] = useState({});

  const handleOpenPopup = (type, row) => {
    setTransaction(row);
    setPopup({ open: true, type });
  };

  const handleClosePopup = () => {
    setPopup({ open: false, type: "" });
  };

  const handleConfirmTransaction = async () => {
    try {
      await apis.transaction.confirmTransaction(transaction?.id);
      reload();
      toast.success(t("confirmTransactionSuccess"));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleCancelTransaction = async () => {
    try {
      await apis.transaction.cancelTransaction(transaction?.id);
      reload();
      toast.success(t("cancelTransactionSuccess"));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const renderTransactionStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.PENDING:
        return <Typography color={COLOR.warning.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.FAILED:
        return <Typography color={COLOR.error.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.CANCELED:
        return <Typography color={COLOR.error.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.COMPLETED:
        return <Typography color={COLOR.success.base}>{t(status)}</Typography>;
      default:
        return <Typography>{t(status)}</Typography>;
    }
  };

  const renderAutoConfirmStatus = (row) => {
    let text = "--";
    if (
      [
        TRANSACTION_STATUS.FAILED,
        TRANSACTION_STATUS.CANCELED,
        TRANSACTION_STATUS.COMPLETED,
      ].includes(row.status)
    ) {
      if (!row.confirmedBy) text = t("autoConfirm");
      else text = t("manualConfirm");
    }

    return <Typography>{text}</Typography>;
  };

  const renderTransactionBankAccount = (row) => {
    switch (row.paymentMethod) {
      case PAYMENT_METHOD.BANK:
        return (
          <div>
            <Typography>{row.paymentAccount?.bankNumber}</Typography>
            <Typography>{row.paymentAccount?.bankName || "-"}</Typography>
          </div>
        );
      case PAYMENT_METHOD.CASH:
        return <Typography>{t("cash")}</Typography>;
      default:
        return <Typography>-</Typography>;
    }
  };

  const columns = [
    {
      title: t("walletType"),
      align: "center",
      sortable: true,
      render: (row) => (
        <Typography>
          {row.walletType === WALLET_TYPE.PAYMENT ? t("paymentWallet") : null}
          {row.walletType === WALLET_TYPE.COMMODITY_COMPENSATION
            ? t("commodityCompensationWallet")
            : null}
          {row.walletType === WALLET_TYPE.FREIGHT_COMPENSATION
            ? t("freightCompensationWallet")
            : null}
          {row.walletType === WALLET_TYPE.BONUS ? t("bonusWallet") : null}
        </Typography>
      ),
    },
    {
      title: t("destinationWallet"),
      align: "center",
      sortable: true,
      render: (row) => <Typography>{row.user?.metadata?.name}</Typography>,
    },
    {
      title: t("totalMoney"),
      align: "right",
      sortable: true,
      render: (row) => (
        <Typography>{row.amount?.toLocaleString()} đ</Typography>
      ),
    },
    {
      title: t("createdBy"),
      align: "right",
      sortable: true,
      render: (row) => <Typography>{row.createdBy?.name}</Typography>,
    },
    {
      title: t("bankAccount"),
      align: "center",
      sortable: true,
      render: (row) => renderTransactionBankAccount(row),
    },
    {
      title: t("qr"),
      align: "center",
      sortable: true,
      render: (row) => (
        <ImagePreview
          galleryID="custom-image-gallery-12345"
          previewWidth={600}
          previewHeight={776}
          images={[
            {
              url: getPaymentQrLink(
                row.paymentAccount?.bankBin,
                row.paymentAccount?.bankHolder,
                row.paymentAccount?.bankNumber,
                row.amount,
                row.paymentNote
              ),
            },
          ]}
        />
      ),
    },
    {
      title: t("paymentNote"),
      align: "center",
      sortable: true,
      field: "paymentNote",
      // render: (row) => renderTransactionBankAccount(row),
    },
    {
      title: t("createdAt"),
      align: "center",
      sortable: true,
      width: "135px",
      render: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: t("completedAt"),
      align: "center",
      sortable: true,
      width: "135px",
      render: (row) =>
        row.completedAt
          ? moment(row.completedAt).format("DD/MM/YYYY HH:mm")
          : "-",
    },
    {
      title: t("isAutoConfirmed"),
      align: "right",
      sortable: true,
      render: renderAutoConfirmStatus,
    },
    {
      title: t("status"),
      align: "left",
      sortable: true,
      render: (row) => renderTransactionStatus(row.status),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("confirmTransaction")}>
              <span>
                <IconButton
                  onClick={() =>
                    handleOpenPopup(POPUP_TYPE.CONFIRM_TRANSACTION, row)
                  }
                  disabled={row.status !== TRANSACTION_STATUS.PENDING}
                  color="success"
                >
                  <ThumbUpAltOutlined />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("cancelTransaction")}>
              <span>
                <IconButton
                  onClick={() =>
                    handleOpenPopup(POPUP_TYPE.CANCEL_TRANSACTION, row)
                  }
                  disabled={row.status !== TRANSACTION_STATUS.PENDING}
                  color="error"
                >
                  <Block />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={transactions}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
      <Popup
        open={popup.open}
        onClose={handleClosePopup}
        onOk={
          popup.type === POPUP_TYPE.CONFIRM_TRANSACTION
            ? handleConfirmTransaction
            : handleCancelTransaction
        }
        okMessage={t("accept")}
        content={
          popup.type === POPUP_TYPE.CONFIRM_TRANSACTION
            ? t("areYouSureConfirmTransaction", {
                amount: transaction?.amount?.toLocaleString(),
                walletType:
                  transaction?.walletType === WALLET_TYPE.PAYMENT
                    ? t("paymentWallet")
                    : t("commodityCompensationWallet"),
                distributor: transaction?.user?.name,
              })
            : t("areYouSureCancelTransaction", {
                amount: transaction?.amount?.toLocaleString(),
                walletType:
                  transaction?.walletType === WALLET_TYPE.PAYMENT
                    ? t("paymentWallet")
                    : t("commodityCompensationWallet"),
                distributor: transaction?.user?.name,
              })
        }
        title={
          popup.type === POPUP_TYPE.CONFIRM_TRANSACTION
            ? t("confirmTransaction")
            : t("cancelTransaction")
        }
      />
    </>
  );
};

export default TransactionList;
