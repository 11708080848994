import { useEffect, useState } from "react";
import {
  Add,
  Delete,
  Article,
  TouchApp,
  ArrowForwardIos,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import { DIALOG_TYPE } from "../../constants";
import { COLOR } from "../../styles/color";
import { validateRequired as checkRequired } from "../../utils/validate";

const INIT_TEMPLATE_ERROR = {
  title: "",
  description: "",
  items: [],
};

const INIT_ITEM = {
  key: "",
  value: "",
};

const TemplateDialog = ({
  open,
  handleClose,
  dialogType,
  initTemplate,
  reload,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState({});
  const [templateError, setTemplateError] = useState({});

  const handleCloseDialog = () => {
    handleClose();
    setTemplate({});
    setTemplateError({});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const [field, indexString, field2] = name.split(".");
    if (!indexString) {
      setTemplate((prev) => ({ ...prev, [field]: value }));
      setTemplateError((prev) => ({ ...prev, [field]: "" }));
    } else {
      const index = +indexString;
      let fields = [...template[field]];
      const fieldIndex = { ...fields[index], [field2]: value };
      fields[index] = fieldIndex;
      setTemplate((prev) => ({
        ...prev,
        [field]: fields,
      }));

      let fieldsError = [...templateError[field]];
      const fieldErrorIndex = { ...fieldsError[index], [field2]: "" };
      fieldsError[index] = fieldErrorIndex;
      setTemplateError((prev) => ({
        ...prev,
        [field]: fieldsError,
      }));
    }
  };

  const validateRequired = (event, f) => {
    const [field, indexString, field2] = (event?.target?.name || f).split(".");
    if (!indexString) {
      if (!checkRequired(template[field])) {
        setTemplateError((prev) => ({ ...prev, [field]: t("fieldRequired") }));
        return false;
      }
    } else {
      const index = +indexString;
      if (!checkRequired(template[field][index][field2])) {
        let fieldsError = [...templateError[field]];
        const fieldErrorIndex = {
          ...fieldsError[index],
          [field2]: t("fieldRequired"),
        };
        fieldsError[index] = fieldErrorIndex;
        setTemplateError((prev) => ({
          ...prev,
          [field]: fieldsError,
        }));
        return false;
      }
    }

    return true;
  };

  const validate = () =>
    validateRequired(null, "title") &&
    validateRequired(null, "description") &&
    template.items.every(
      (item, index) =>
        Object.values(item).every((value) => checkRequired(value)) ||
        (validateRequired(null, "items." + index + ".key") &&
          validateRequired(null, "items." + index + ".value"))
    );

  const handleAddItem = () => {
    setTemplate((prev) => ({ ...prev, items: [...prev.items, INIT_ITEM] }));
    setTemplateError((prev) => ({
      ...prev,
      items: [...prev.items, INIT_ITEM],
    }));
  };

  const handleDeleteItem = (index) => {
    const itemsTmp = [...template.items];
    itemsTmp.splice(index, 1);
    setTemplate((prev) => ({
      ...prev,
      items: itemsTmp,
    }));
    const itemsErrorTmp = [...templateError.items];
    itemsErrorTmp.splice(index, 1);
    setTemplateError((prev) => ({
      ...prev,
      items: itemsErrorTmp,
    }));
  };

  const handleCreate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.oaMessageTemplate.createOaMessageTemplate(template);
      handleCloseDialog();
      reload();
      toast.success(t("addOaMessageTemplateSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      const { id, ...data } = template;
      await apis.oaMessageTemplate.updateOaMessageTemplate(id, data);
      handleCloseDialog();
      reload();
      toast.success(t("updateOaMessageTemplateSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      setTemplate(initTemplate);
      const initItemsError = initTemplate.items.map(() => INIT_ITEM);
      setTemplateError({ ...INIT_TEMPLATE_ERROR, items: initItemsError });
    }
  }, [open, initTemplate]);

  return (
    <Dialog
      title={
        dialogType === DIALOG_TYPE.CREATE ? t("addTemplate") : t("editTemplate")
      }
      maxWidth="lg"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(template).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={7}>
              <Typography fontWeight={600} mb={1} fontSize="18px">
                {t("typeContent")}:
              </Typography>
              <Box mb={3}>
                <Typography fontWeight={500} mb={1}>
                  {t("title")}{" "}
                  <span style={{ color: COLOR.error.base }}>*</span>:
                </Typography>
                <TextField
                  helperText={templateError.title}
                  error={!!templateError.title}
                  value={template.title}
                  size="small"
                  fullWidth
                  name="title"
                  onChange={handleChange}
                  onBlur={validateRequired}
                />
              </Box>
              <Box mb={3}>
                <Typography fontWeight={500} mb={1}>
                  {t("description")}{" "}
                  <span style={{ color: COLOR.error.base }}>*</span>:
                </Typography>
                <TextField
                  helperText={templateError.description}
                  error={!!templateError.description}
                  value={template.description}
                  size="small"
                  fullWidth
                  name="description"
                  onChange={handleChange}
                  onBlur={validateRequired}
                />
              </Box>
              <Box mb={3}>
                <Typography fontWeight={500} mb={1}>
                  {t("tableContent")} :
                </Typography>
                {template.items.map((item, index) => (
                  <Grid key={index} container spacing={1} mb={2}>
                    <Grid item xs={4}>
                      <TextField
                        helperText={templateError.items[index].key}
                        error={!!templateError.items[index].key}
                        value={item.key}
                        size="small"
                        fullWidth
                        name={`items.${index}.key`}
                        onChange={handleChange}
                        onBlur={validateRequired}
                        placeholder={t("title")}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        helperText={templateError.items[index].value}
                        error={!!templateError.items[index].value}
                        value={item.value}
                        size="small"
                        fullWidth
                        name={`items.${index}.value`}
                        onChange={handleChange}
                        onBlur={validateRequired}
                        placeholder={t("content")}
                      />
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "end" }}>
                      <Tooltip title={t("delete")}>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handleDeleteItem(index)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}
                {template.items.length < 5 && (
                  <Button
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={handleAddItem}
                  >
                    {t("add")}
                  </Button>
                )}
              </Box>
              <Box mb={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!template.openMiniApp}
                      onChange={(event) =>
                        setTemplate((prev) => ({
                          ...prev,
                          openMiniApp: event.target.checked,
                        }))
                      }
                    />
                  }
                  label={t("openMiniAppDesc")}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography fontWeight={600} mb={1} fontSize="18px">
                {t("previewContent")}:
              </Typography>
              <Box
                sx={{
                  backgroundColor: COLOR.lightBlue[64],
                  padding: 2,
                  height: "calc(100% - 50px)",
                }}
              >
                <Box
                  sx={{
                    width: "80%",
                    margin: "0 auto",
                    backgroundColor: COLOR.white[100],
                    borderRadius: "5px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    spacing={1}
                    paddingY={0.5}
                    paddingX={1.5}
                    sx={{
                      backgroundColor: COLOR.black[16],
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    <Article fontSize="small" />
                    <Typography
                      fontWeight={600}
                      mb={1}
                      fontSize="12px"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {t("Sự kiện")}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Box paddingY={1} paddingX={1.5}>
                    <Typography fontWeight={600} mb={1} fontSize="16px">
                      {template.title || t("title")}
                    </Typography>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      fontSize="14px"
                      sx={{ color: COLOR.secondary.base }}
                    >
                      {template.description || t("description")}
                    </Typography>
                    {!!template.items.length && (
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Typography
                            fontWeight={500}
                            fontSize="14px"
                            sx={{ color: COLOR.secondary.base }}
                          >
                            {t("distributor")}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography fontWeight={600} fontSize="14px">
                            {`< ${t("distributorName")} >`}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {template.items.map((item) => (
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Typography
                            fontWeight={500}
                            fontSize="14px"
                            sx={{ color: COLOR.secondary.base }}
                          >
                            {item.key || t("title")}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography fontWeight={600} fontSize="14px">
                            {item.value || t("content")}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                  {template.openMiniApp && (
                    <>
                      <Divider />
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        paddingY={1.5}
                        paddingX={1.5}
                      >
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={2}
                        >
                          <TouchApp color="primary" />
                          <Typography>{t("openMiniApp")}</Typography>
                        </Stack>
                        <ArrowForwardIos sx={{ color: COLOR.secondary.base }} />
                      </Stack>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {dialogType === DIALOG_TYPE.CREATE ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreate}
          >
            {t("addNew")}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdate}
          >
            {t("save")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TemplateDialog;
