import { SERVICE } from "../constants";
import api from "./api";

const getProducts = async (params) => {
  const res = await api({
    method: "GET",
    url: `/products`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

const createProduct = async (data) => {
  const res = await api({
    method: "POST",
    url: `/products`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const updateProduct = async (productId, data) => {
  const res = await api({
    method: "PUT",
    url: `/products/${productId}`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const changeStatusProduct = async (productId, active) => {
  const res = await api({
    method: "PUT",
    url: `/products/${productId}/change-status`,
    data: { active },
    source: SERVICE.VELA_API,
  });
  return res;
};

export { getProducts, createProduct, updateProduct, changeStatusProduct };
