import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import queryString from "query-string";
import { Autorenew, DownloadOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import PageTitle from "../../components/PageTitle";
import ROUTE from "../../constants/route";
import MainCard from "../../components/MainCard";
import { INITIAL_PAGING } from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import DatePickerRange from "../../components/PickDateRange";
import TimesheetList from "./TimesheetList";
import { downloadFile } from "../../utils/download";

const INIT_DATE = [dayjs().startOf("month"), dayjs().endOf("month")];

const TimesheetDetail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { userId } = useParams();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [breadcrumbs, setBreadcrumbs] = useState([
    { title: t("user"), path: ROUTE.TIMESHEET },
  ]);
  const [initializing, setInitializing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [date, setDate] = useState(INIT_DATE);
  const [downloading, setDownloading] = useState(false);
  const [user, setUser] = useState({});

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const handleChangeDate = (newValue) => {
    setDate(newValue);
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(newValue[0]).format("YYYY-MM-DD"),
      endDate: dayjs(newValue[1]).format("YYYY-MM-DD"),
    });
  };

  const handleRefresh = () => {
    setDate(INIT_DATE);
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(INIT_DATE[0]).format("YYYY-MM-DD"),
      endDate: dayjs(INIT_DATE[1]).format("YYYY-MM-DD"),
    });
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const {
        startDate = dayjs(INIT_DATE[0]).format("YYYY-MM-DD"),
        endDate = dayjs(INIT_DATE[1]).format("YYYY-MM-DD"),
      } = searchParams;

      await downloadFile({
        url: "/timesheets/download",
        params: {
          startDate: startDate,
          endDate: endDate,
          userId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `timesheets_${user.metadata?.name || user.name}_${
          user.role.name
        }_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }

    setDownloading(false);
  };

  const fetchTimesheets = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        startDate: newFilter.startDate,
        endDate: newFilter.endDate,
        userId,
      };

      const { result } = await apis.timesheet.getTimesheets(condition);
      setTimesheets(result.timesheets);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchUser = async () => {
    setInitializing(true);
    try {
      const res = await apis.user.getUser(userId);
      setUser(res.result);
      setBreadcrumbs((prev) => [
        prev[0],
        { title: res.result?.metadata?.name || res.result.name },
      ]);
    } catch (error) {
      toast.error(t(error.message));
      setBreadcrumbs([{ title: t("back"), path: ROUTE.TIMESHEET }]);
      setError(true);
    }
    setInitializing(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      startDate = dayjs(INIT_DATE[0]).format("YYYY-MM-DD"),
      endDate = dayjs(INIT_DATE[1]).format("YYYY-MM-DD"),
    } = searchParams;
    setPaging((prev) => ({
      ...prev,
      page: parseInt(page, 10),
    }));
    setDate([startDate, endDate]);
    fetchTimesheets({ page: parseInt(page, 10), startDate, endDate });
  }, [location.search]);

  useEffect(() => {
    fetchUser();
  }, []);

  const renderContent = () => (
    <MainCard>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={6}>
          <Box display="flex">
            <DatePickerRange value={date} onChange={handleChangeDate} />
            <Tooltip title={t("refresh")}>
              <IconButton aria-label="refresh" onClick={handleRefresh}>
                <Autorenew />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={12} sm={3}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <LoadingButton
              loading={downloading}
              loadingPosition="start"
              variant="outlined"
              startIcon={<DownloadOutlined />}
              disabled={!timesheets.length}
              onClick={handleDownload}
            >
              {t("download")}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      <TimesheetList
        loading={loading}
        paging={paging}
        handleChangePage={handleChangePage}
        timesheets={timesheets}
      />
    </MainCard>
  );

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} />
      {initializing ? (
        <CircularProgress />
      ) : error ? (
        <Box padding={2}>
          <Typography variant="body1" textAlign="center">
            {t("noData")} !
          </Typography>
        </Box>
      ) : (
        renderContent()
      )}
    </>
  );
};

export default TimesheetDetail;
