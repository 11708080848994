import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    addNotifications: (state, action) => {
      const existingIds = state.map((notification) => notification.id);
      const newNotifications = action.payload.filter(
        (notification) => !existingIds.includes(notification.id)
      );
      state.push(...newNotifications);
    },
    removeNotification: (state, action) => {
      return state.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const { addNotifications, removeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
