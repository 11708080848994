import axios from "axios";
import { SERVICE } from "../constants";
import api from "./api";
import { BANKS_URL } from "../configs";

const getBanks = async () => {
  const res = await axios({
    method: "GET",
    url: BANKS_URL,
  });
  return { status: 1, result: res.data?.data || [] };
};

const getPaymentAccounts = async (params) => {
  const res = await api({
    method: "GET",
    url: `/payment-accounts`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

const createPaymentAccount = async (data) => {
  const res = await api({
    method: "POST",
    url: `/payment-accounts`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const updatePaymentAccount = async (
  paymentAccountId,
  { bankBin, bankNumber, bankName, bankHolder, bankLogo }
) => {
  const res = await api({
    method: "PUT",
    url: `/payment-accounts/${paymentAccountId}`,
    data: {
      bankBin,
      bankNumber,
      bankName,
      bankHolder,
      bankLogo,
    },
    source: SERVICE.VELA_API,
  });
  return res;
};

const deletePaymentAccount = async (paymentAccountId) => {
  const res = await api({
    method: "DELETE",
    url: `/payment-accounts/${paymentAccountId}`,
    source: SERVICE.VELA_API,
  });
  return res;
};

export {
  getBanks,
  getPaymentAccounts,
  createPaymentAccount,
  updatePaymentAccount,
  deletePaymentAccount,
};
