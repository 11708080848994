import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

import apis from "../../../apis";
import { StyledTable } from "./index.style";
import { Add, SaveOutlined } from "@mui/icons-material";
import { validateRequired } from "../../../utils/validate";
import CurrencyInput from "../../../components/CurrencyInput";

const DEFAULT_RATE = {
  type: "A",
  discountRate: 0,
  systemSalaryRate: 0,
  freightCompensation: 0,
  freightCollect: 0,
};

const INIT_RATE = Object.keys(DEFAULT_RATE).reduce((acc, key) => {
  acc[key] = "";
  return acc;
}, {});

const DistributorRate = ({ distributor }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsError, setItemsError] = useState([]);
  const [updating, setUpdating] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const newItem = { ...items[index], [field]: value };
    const newItems = [...items];
    newItems[index] = newItem;
    setItems(newItems);

    let newItemError = { ...itemsError[index], [field]: false };
    if (Object.values(newItem).every((value) => !validateRequired(value)))
      newItemError = INIT_RATE;
    const newItemsError = [...itemsError];
    newItemsError[index] = newItemError;

    if (field === "type") {
      const oldValue = items[index][field];
      const indexes = [];
      newItems.forEach((item, index) => {
        if (item.type.toUpperCase() === oldValue.toUpperCase())
          indexes.push(index);
      });
      if (indexes.length === 1) {
        newItemsError[indexes[0]] = {
          ...itemsError[indexes[0]],
          [field]: false,
        };
      }
    }
    setItemsError(newItemsError);
  };

  const handleAddRate = () => {
    setItems((prev) => [...prev, INIT_RATE]);
    setItemsError((prev) => [...prev, INIT_RATE]);
  };

  const validateItem = (event, initName) => {
    const name = event ? event.target.name : initName;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const value = items[index][field];
    let isError = false;
    if (!Object.values(items[index]).every((value) => !validateRequired(value)))
      if (!validateRequired(value)) {
        isError = true;
      } else {
        if (field === "type") {
          if (value.length > 1 || !/^([A-Za-z]\d*)$/.test(value))
            isError = true;

          const item = items.find(
            (it, id) =>
              it.type.toUpperCase() === value.toUpperCase() && id !== index
          );
          if (item) isError = true;
        }

        if (["discountRate", "systemSalaryRate"].includes(field)) {
          const v = +value;
          if (v < 0 || v > 100) isError = true;
        }

        if (["freightCompensation", "freightCollect"].includes(field)) {
          const v = +value;
          if (v < 0) isError = true;
        }
      }

    const newItemsErrors = [...itemsError];
    newItemsErrors[index] = { ...itemsError[index], [field]: isError };
    setItemsError(newItemsErrors);

    return !isError;
  };

  const validate = () =>
    items.every(
      (item, index) =>
        Object.values(item).every((value) => !validateRequired(value)) ||
        (validateItem(null, index + ".type") &&
          validateItem(null, index + ".discountRate") &&
          validateItem(null, index + ".systemSalaryRate") &&
          validateItem(null, index + ".freightCollect") &&
          validateItem(null, index + ".freightCompensation"))
    );

  const handleUpdateRates = async () => {
    if (!validate()) {
      toast.error(t("dataInvalid"));
      return;
    }

    setUpdating(true);
    try {
      const data = {
        distributorId: distributor.id,
        rates: items.filter((item) =>
          Object.values(item).every((value) => validateRequired(value))
        ),
      };
      await apis.rate.updateRates(data);
      toast.success(t("updateRateSuccess"));
      handleInit();
    } catch (error) {
      toast.error(t(error.message));
    }
    setUpdating(false);
  };

  const getRates = async () => {
    try {
      const { result = [] } = await apis.rate.getRates(distributor.id);
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const handleInit = async () => {
    const rates = await getRates();
    if (!rates) {
      setError(true);
      return;
    }
    const initItems = rates.map((rate) => ({
      type: rate.type,
      discountRate: rate.discountRate,
      systemSalaryRate: rate.systemSalaryRate,
      freightCompensation: rate.freightCompensation,
      freightCollect: rate.freightCollect,
    }));

    if (!initItems.length) initItems.push(DEFAULT_RATE);

    const initItemsError = initItems.map(() => INIT_RATE);

    setItems(initItems);
    setItemsError(initItemsError);
  };

  const init = async () => {
    setLoading(true);
    await handleInit();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  if (loading)
    return (
      <Box padding={2}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box padding={2}>
        <Typography variant="body1" textAlign="center">
          {t("noData")} !
        </Typography>
      </Box>
    );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        mb={2}
      >
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          startIcon={<SaveOutlined />}
          color="success"
          onClick={handleUpdateRates}
          loading={updating}
        >
          {t("save")}
        </LoadingButton>
      </Stack>
      <StyledTable>
        <Table className="table">
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="header-cell" align="center">
                {t("no")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("type")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("discountRate")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("systemSalaryRate")}
              </TableCell>

              <TableCell className="header-cell" align="center">
                {t("freightCollect")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("freightCompensation")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {items.map((item, index) => (
              <TableRow
                key={index}
                className="body-row"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="body-cell cell-no" align="center">
                  {index + 1}
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <Input
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.type}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.type}
                    error={!!itemsError[index]?.type}
                    name={index + ".type"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.discountRate}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.discountRate}
                    error={!!itemsError[index]?.discountRate}
                    name={index + ".discountRate"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.systemSalaryRate}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.systemSalaryRate}
                    error={!!itemsError[index]?.systemSalaryRate}
                    name={index + ".systemSalaryRate"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </TableCell>

                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.freightCollect}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.freightCollect}
                    error={!!itemsError[index]?.freightCollect}
                    name={index + ".freightCollect"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">&#8363;</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.freightCompensation}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.freightCompensation}
                    error={!!itemsError[index]?.freightCompensation}
                    name={index + ".freightCompensation"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">&#8363;</InputAdornment>
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTable>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={2.5}
      >
        <Button variant="outlined" onClick={handleAddRate} startIcon={<Add />}>
          {t("addRate")}
        </Button>
      </Stack>
    </>
  );
};

export default DistributorRate;
