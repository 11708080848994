import { SERVICE } from "../constants";
import api from "./api";

const getBanners = async (params) => {
  const res = await api({
    method: "GET",
    url: `/banners`,
    params,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

const deleteBanner = async (id) => {
  const res = await api({
    method: "DELETE",
    url: `/banners/${id}`,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

const createBanner = async (data) => {
  const res = await api({
    method: "POST",
    url: `/banners`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

const updateBanner = async (id, data) => {
  const res = await api({
    method: "PUT",
    url: `/banners/${id}`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export { getBanners, deleteBanner, createBanner, updateBanner };
