import CurrencyInputBase from "react-currency-input-field";

const CurrencyInput = ({ onChange, name, ...props }) => {
  const handleOnChange = (newValue) => {
    const event = { target: { value: newValue || "", name } };
    onChange(event);
  };

  return (
    <CurrencyInputBase
      onValueChange={(newValue) => {
        handleOnChange(newValue);
      }}
      name={name}
      {...props}
    />
  );
};

export default CurrencyInput;
