import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  UnfoldMore,
} from "@mui/icons-material";
import { StyledTableCell } from "./index.style";

const CustomTableHeader = ({
  columns,
  selection,
  sort,
  showNumber,
  numSelected,
  rowCount,
  onSelectAll,
  onChangeSort,
}) => {
  const { t } = useTranslation();

  const renderSorter = (column) => {
    if (!column.sortable) return <></>;
    if (sort) {
      const [sortField, sorter] = sort.split("_");
      if (column.field === sortField)
        return sorter === "asc" ? (
          <IconButton onClick={() => onChangeSort(`${column.field}_desc`)}>
            <KeyboardArrowUp className="header-icon" />
          </IconButton>
        ) : (
          <IconButton onClick={() => onChangeSort(`${column.field}_asc`)}>
            <KeyboardArrowDown className="header-icon" />
          </IconButton>
        );
    }
    return (
      <IconButton
        sx={{ opacity: "0.5", padding: 0 }}
        onClick={() => onChangeSort(`${column.field}_asc`)}
      >
        <UnfoldMore className="header-icon" />
      </IconButton>
    );
  };

  return (
    <TableHead className="table-header">
      <TableRow>
        {selection && (
          <TableCell className="header-cell" padding="checkbox" align="center">
            <Checkbox
              className="header-checkbox"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAll}
            />
          </TableCell>
        )}
        {showNumber && (
          <TableCell className="header-cell" align="center">
            <Typography className="header-title">{t("no")}</Typography>
          </TableCell>
        )}
        {columns.map((column) => (
          <StyledTableCell
            className="header-cell"
            key={uuidv4()}
            align={column.align}
            width={column.width}
          >
            <div
              id={column.id}
              className={`header-item header-${
                column.sortable ? "between" : column.align
              }`}
            >
              <Typography className="header-title">{column.title}</Typography>
              {renderSorter(column)}
            </div>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHeader;
