import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Delete, FileDownloadOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";

import Table from "../../components/Table";
import {
  GROUP_TRANSACTION_TYPE_LABEL,
  TRANSACTION_STATUS,
} from "../../constants/wallet";
import { COLOR } from "../../styles/color";
import ROUTE from "../../constants/route";
import { downloadFile } from "../../utils/download";

const DownloadReport = ({ groupTransactionId }) => {
  const { t } = useTranslation();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    setDownloading(true);
    try {
      await downloadFile({
        url: "/transactions/download",
        params: {
          groupTransactionId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `transactions_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }

    setDownloading(false);
  };

  return (
    <Tooltip title={t("download")}>
      <span>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleDownload();
          }}
          disabled={downloading}
          color="primary"
        >
          <FileDownloadOutlined />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const GroupTransactionList = ({
  loading,
  paging,
  handleChangePage,
  groupTransactions,
  handleOpenPopup,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const renderTransactionStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.PENDING:
        return <Typography color={COLOR.warning.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.FAILED:
        return <Typography color={COLOR.error.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.CANCELED:
        return <Typography color={COLOR.error.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.COMPLETED:
        return <Typography color={COLOR.success.base}>{t(status)}</Typography>;
      default:
        return <Typography>{t(status)}</Typography>;
    }
  };

  const renderTitle = (groupTransaction) => {
    const { title, month, quarter, year } = groupTransaction;
    if (title) return title;
    if (quarter) return `${t("quarter")} ${quarter}/${year}`;
    if (month) return `${t("month")} ${month}/${year}`;
    return `${t("year")} ${year}`;
  };

  const handleClickRow = (row) =>
    history.push(`${ROUTE.FREIGHT_COMPENSATION}/${row.id}`);

  const columns = [
    {
      title: t("title"),
      align: "left",
      render: (row) => <Typography>{renderTitle(row)}</Typography>,
    },
    {
      title: t("source"),
      align: "left",
      render: (row) => (
        <Typography>
          {row.type ? t(GROUP_TRANSACTION_TYPE_LABEL[row.type]) : ""}
        </Typography>
      ),
    },
    {
      title: t("createdAt"),
      align: "left",
      render: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => renderTransactionStatus(row.status),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <DownloadReport groupTransactionId={row.id} />
            <Tooltip title={t("delete")}>
              <span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenPopup(row);
                  }}
                  color="error"
                  disabled={row.status === TRANSACTION_STATUS.COMPLETED}
                >
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <Table
      data={groupTransactions}
      columns={columns}
      total={paging.total}
      page={paging.page}
      loading={loading}
      onChangePage={handleChangePage}
      showNumber={true}
      onRowClick={handleClickRow}
    />
  );
};

export default GroupTransactionList;
