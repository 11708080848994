import ROUTE from "../constants/route";
import Timesheet from "../pages/Timesheet";
import Dashboard from "../pages/Dashboard";
import Distributor from "../pages/Distributor";
import Login from "../pages/Login";
import Permission from "../pages/Permission";
import Role from "../pages/Role";
import Store from "../pages/Store";
import User from "../pages/User";
import TimesheetDetail from "../pages/TimesheetDetail";
import Discount from "../pages/Discount";
import Order from "../pages/Order";
import DistributorDetail from "../pages/DistributorDetail";
import Product from "../pages/Product";
import Category from "../pages/Category";
import TransactionCollect from "../pages/TransactionCollect";
import TransactionWithdraw from "../pages/TransactionWithdraw";
import PaymentAccount from "../pages/PaymentAccount";
import DistributorOrder from "../pages/DistributorOrder";
import DistributorOrderDetail from "../pages/DistributorOrderDetail";
import WarehouseOrder from "../pages/WarehouseOrder";
import WarehouseOrderDetail from "../pages/WarehouseOrderDetail";
import FreightCompensation from "../pages/FreightCompensation";
import FreightCompensationDetail from "../pages/FreightCompensationDetail";
import Bonus from "../pages/Bonus";
import BonusDetail from "../pages/BonusDetail";
import Transaction from "../pages/Transaction";
import Statement from "../pages/Statement";
import StatementDetail from "../pages/StatementDetail";
import OaMessageTemplate from "../pages/OaMessageTemplate";
import Banner from "../pages/Banner";
import OaMessage from "../pages/OaMessage";
import Profile from "../pages/Profile";
import Feedback from "../pages/Feedback";
import RegisterBanner from "../pages/RegisterBanner";
import MaintainPOSM from "../pages/MaintainPOSM";
import UpdateStore from "../pages/UpdateStore";
import NewCompetitorProduct from "../pages/NewCompetitorProduct";
import WorkReport from "../pages/WorkReport";
// import CreateDistributorOrder from "../containers/DistributorOrder/CreateDistributorOrder";

const appRoutes = [
  {
    path: ROUTE.LOGIN,
    component: Login,
    restricted: true,
    isPrivate: false,
  },
  {
    path: ROUTE.DASHBOARD,
    component: Dashboard,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.USER,
    component: User,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.PERMISSION,
    component: Permission,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.ROLE,
    component: Role,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.DISTRIBUTOR,
    component: Distributor,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.STORE,
    component: Store,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.TIMESHEET,
    component: Timesheet,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.TIMESHEET_DETAIL,
    component: TimesheetDetail,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.DISCOUNT,
    component: Discount,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.ORDER,
    component: Order,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.DISTRIBUTOR_DETAIL,
    component: DistributorDetail,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.PRODUCT,
    component: Product,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.CATEGORY,
    component: Category,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.TRANSACTION_COLLECT,
    component: TransactionCollect,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.TRANSACTION_WITHDRAW,
    component: TransactionWithdraw,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.PAYMENT_ACCOUNT,
    component: PaymentAccount,
    restricted: false,
    isPrivate: true,
  },
  // {
  //   path: ROUTE.CREATE_DISTRIBUTOR_ORDER,
  //   component: CreateDistributorOrder,
  //   restricted: false,
  //   isPrivate: true,
  // },
  {
    path: ROUTE.DISTRIBUTOR_ORDER,
    component: DistributorOrder,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.DISTRIBUTOR_ORDER_DETAIL,
    component: DistributorOrderDetail,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.WAREHOUSE_ORDER,
    component: WarehouseOrder,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.WAREHOUSE_ORDER_DETAIL,
    component: WarehouseOrderDetail,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.FREIGHT_COMPENSATION,
    component: FreightCompensation,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.FREIGHT_COMPENSATION_DETAIL,
    component: FreightCompensationDetail,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.BONUS,
    component: Bonus,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.BONUS_DETAIL,
    component: BonusDetail,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.TRANSACTION,
    component: Transaction,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.STATEMENT,
    component: Statement,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.STATEMENT_DETAIL,
    component: StatementDetail,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.OA_MESSAGE_TEMPLATE,
    component: OaMessageTemplate,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.BANNER,
    component: Banner,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.OA_MESSAGE,
    component: OaMessage,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.PROFILE,
    component: Profile,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.FEEDBACK,
    component: Feedback,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.REGISTER_BANNER,
    component: RegisterBanner,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.MAINTAIN_POSM,
    component: MaintainPOSM,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.NEW_COMPETITOR_PRODUCT,
    component: NewCompetitorProduct,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.UPDATE_STORE,
    component: UpdateStore,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.WORK_REPORTS,
    component: WorkReport,
    restricted: false,
    isPrivate: true,
  },
];

export default appRoutes;
