import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Badge, Box, IconButton, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomMenu from "../CustomMenu";
import apis from "../../apis";

import { removeNotification } from "../../stores/notificationSlice";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_TYPE } from "../../constants/notification";
import ROUTE from "../../constants/route";

const Notification = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const notifications = useSelector((state) => state.notification);
  const { key: roleKey } = useSelector((state) => state.role);

  const getNotificationMessage = (notification) => {
    const { type, data } = notification;
    const { order, distributor } = data || {};
    const distributorName = distributor?.metadata?.name || "";
    const totalBoxes = order?.items?.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    return t(`notification.${type}`, { totalBoxes, distributorName });
  };

  const handleMenu = (event) => {
    if (notifications.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotification = async (notification) => {
    if (notification.data?.order) {
      if (roleKey === "WAREHOUSE") {
        history.push(
          `/warehouse/distributor-order/${notification.data?.order?.id}`
        );
      } else {
        history.push(`/distributor-order/${notification.data?.order?.id}`);
      }
    }
    if (notification.type === NOTIFICATION_TYPE.NEW_COMPETITOR_PRODUCT) {
      history.push(`/new-competitor-product`);
    }
    if (notification.type === NOTIFICATION_TYPE.COLLECT_FEEDBACK) {
      history.push(ROUTE.FEEDBACK);
    }
    if (notification.type === NOTIFICATION_TYPE.UPDATE_STORE) {
      history.push(ROUTE.UPDATE_STORE);
    }
    if (notification.type === NOTIFICATION_TYPE.REGISTER_BANNER) {
      history.push(ROUTE.REGISTER_BANNER);
    }
    dispatch(removeNotification(notification.id));
    try {
      await apis.notification.seenNotification(notification.id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <IconButton onClick={handleMenu}>
        <Badge badgeContent={notifications?.length} color="primary">
          <NotificationsNoneOutlinedIcon color="black" />
        </Badge>
      </IconButton>
      <CustomMenu anchorEl={anchorEl} onClose={handleClose}>
        {notifications.map((notification, index) => (
          <MenuItem
            key={notification.id}
            onClick={() => handleClickNotification(notification)}
          >
            {getNotificationMessage(notification)}
          </MenuItem>
        ))}
      </CustomMenu>
    </Box>
  );
};

export default Notification;
