import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import apis from "../../../apis";
import MainCard from "../../../components/MainCard";
import { formatNumber } from "../../../utils/number";
import UpdateCreditLimitDialog from "./UpdateCreditLimitDialog";
import UpdateCommodityCompensationBalanceDialog from "./UpdateCommodityCompensationBalanceDialog";

const DistributorWallet = ({ distributor, reload }) => {
  const { t } = useTranslation();

  const [wallet, setWallet] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [updateCreditLimitDialog, setUpdateCreditLimitDialog] = useState(false);
  const [
    updateCommodityCompensationBalanceDialog,
    setUpdateCommodityCompensationBalanceDialog,
  ] = useState(false);

  const getWallet = async () => {
    try {
      const { result = {} } = await apis.wallet.getWallet(distributor.id);
      setWallet(result);
    } catch (error) {
      toast.error(t(error.message));
      setError(true);
    }
  };

  const fetchWallet = async () => {
    setLoading(true);
    await getWallet();
    setLoading(false);
  };

  useEffect(() => {
    fetchWallet();
  }, []);

  if (loading)
    return (
      <Box padding={2}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box padding={2}>
        <Typography variant="body1" textAlign="center">
          {t("noData")} !
        </Typography>
      </Box>
    );

  return (
    <>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <Stack spacing={3}>
            <MainCard sx={{ paddingX: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Typography fontSize={24} fontWeight={500}>
                    {t("paymentWallet")}
                  </Typography>
                  <Stack direction="row" alignItems="flex-end" spacing={1}>
                    <Typography>{t("creditLimit")}:</Typography>
                    <Typography>
                      {formatNumber(distributor.creditLimit || 0)}
                    </Typography>
                    <Tooltip title={t("edit")}>
                      <IconButton
                        color="edit"
                        onClick={() => setUpdateCreditLimitDialog(true)}
                      >
                        <Edit size="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
                <Stack direction="row" spacing={1}>
                  <Typography fontSize={20} fontWeight={500}>
                    VND
                  </Typography>
                  <Typography fontSize={20} fontWeight={500}>
                    {formatNumber(wallet.paymentBalance)}
                  </Typography>
                </Stack>
              </Stack>
            </MainCard>
            <MainCard sx={{ padding: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Typography fontSize={24} fontWeight={500}>
                    {t("freightCompensationWallet")}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1}>
                  <Typography fontSize={20} fontWeight={500}>
                    VND
                  </Typography>
                  <Typography fontSize={20} fontWeight={500}>
                    {formatNumber(wallet.freightCompensationBalance)}
                  </Typography>
                </Stack>
              </Stack>
            </MainCard>
            <MainCard sx={{ padding: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Typography fontSize={24} fontWeight={500}>
                    {t("bonusWallet")}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1}>
                  <Typography fontSize={20} fontWeight={500}>
                    VND
                  </Typography>
                  <Typography fontSize={20} fontWeight={500}>
                    {formatNumber(wallet.bonusBalance)}
                  </Typography>
                </Stack>
              </Stack>
            </MainCard>
            <MainCard sx={{ padding: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Typography fontSize={24} fontWeight={500}>
                    {t("commodityCompensationWallet")}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1}>
                  {/* <Tooltip title={t("edit")}>
                    <IconButton
                      sx={{ paddingTop: "0px" }}
                      color="edit"
                      onClick={() =>
                        setUpdateCommodityCompensationBalanceDialog(true)
                      }
                    >
                      <Edit size="small" />
                    </IconButton>
                  </Tooltip> */}

                  <Typography fontSize={20} fontWeight={500}>
                    VND
                  </Typography>
                  <Typography fontSize={20} fontWeight={500}>
                    {formatNumber(wallet.commodityCompensationBalance)}
                  </Typography>
                </Stack>
              </Stack>
            </MainCard>
          </Stack>
        </Grid>
      </Grid>
      <UpdateCreditLimitDialog
        open={updateCreditLimitDialog}
        handleClose={() => setUpdateCreditLimitDialog(false)}
        distributor={distributor}
        reload={reload}
      />
      <UpdateCommodityCompensationBalanceDialog
        open={updateCommodityCompensationBalanceDialog}
        handleClose={() => setUpdateCommodityCompensationBalanceDialog(false)}
        distributor={distributor}
        initBalance={wallet.commodityCompensationBalance}
        reload={getWallet}
      />
    </>
  );
};

export default DistributorWallet;
