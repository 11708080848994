import { styled } from "@mui/material/styles";
import { TableContainer, TableCell, Box } from "@mui/material";
import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";

const StyledTable = styled(TableContainer)`
  background: ${COLOR.white[100]};
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b9c7;
    border-radius: 3px;
  }

  .table-header {
    background: ${COLOR.primary.base};
    border-radius: 20px;
  }

  .header-cell {
    padding: 6px 12px;
    height: 36px;
    color: ${COLOR.white[100]};
    max-width: 200px;
  }

  .header-item {
    display: flex;
    align-items: center;
  }

  .header-left {
    justify-content: left;
  }

  .header-right {
    justify-content: right;
  }

  .header-between {
    justify-content: space-between;
  }

  .header-center {
    justify-content: center;
  }

  .header-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .header-icon {
    font-size: 18px;
    margin-bottom: 3px;
    color: ${COLOR.white[100]};
  }

  .body-row {
    &:last-child td,
    &:last-child th {
      border: 0;
    }
    &:hover {
      background: ${TRANSPARENT_COLOR.light};
    }
    &:nth-of-type(even) {
      background: ${COLOR.black[4]};
    }
    &:last-of-type {
      border-bottom: 1px solid ${COLOR.black[32]};
    }
  }

  .body-cell {
    border: none;
    padding: 14px 12px;
    width: ${(props) => (props.width ? props.width : "auto")};
    font-size: 16px;
    max-width: 200px;
  }
`;

const StyledTableCell = styled(TableCell)`
  width: ${(props) => (props.width ? props.width : "auto")};
`;

const StyledTableFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  padding: 20px;

  .Mui-selected {
    color: ${COLOR.white} !important;
  }
  .footer-label {
    font-size: 14px;
    font-weight: 600;
  }
`;

export { StyledTable, StyledTableCell, StyledTableFooter };
