import { forwardRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import { COLOR } from "../styles/color";
import { BORDER_RADIUS } from "../styles/config";
import { Divider } from "@mui/material";

const headerSX = {
  p: 2.5,
  "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" },
};

const MainCard = (
  {
    border = true,
    boxShadow,
    children,
    content = true,
    contentSX = {},
    elevation,
    secondary,
    shadow,
    sx = {},
    title,
    ...others
  },
  ref
) => {
  return (
    <Card
      elevation={elevation || 0}
      ref={ref}
      {...others}
      sx={{
        borderRadius: BORDER_RADIUS.container,
        backgroundColor: COLOR.white[100],
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
        ...sx,
      }}
    >
      {title && (
        <>
          <CardHeader
            sx={headerSX}
            titleTypographyProps={{ variant: "h6" }}
            title={title}
            action={secondary}
          />
          <Divider />
        </>
      )}

      {content && (
        <CardContent sx={{ padding: 2, ...contentSX }}>{children}</CardContent>
      )}
      {!content && children}
    </Card>
  );
};

export default forwardRef(MainCard);
