import { SERVICE } from "../constants";
import api from "./api";

const getTransactions = async (data) => {
  const response = await api({
    method: "GET",
    url: `/transactions`,
    params: data,
    source: SERVICE.VELA_API,
  });

  return response;
};

const createTransaction = async (data) => {
  const response = await api({
    method: "POST",
    url: `/transactions`,
    data,
    source: SERVICE.VELA_API,
  });

  return response;
};

const confirmTransaction = async (transactionId) => {
  const response = await api({
    method: "POST",
    url: `/transactions/${transactionId}/confirm`,
    source: SERVICE.VELA_API,
  });

  return response;
};

const cancelTransaction = async (transactionId) => {
  const response = await api({
    method: "POST",
    url: `/transactions/${transactionId}/cancel`,
    source: SERVICE.VELA_API,
  });

  return response;
};

const approveFreightCompensation = async (data) => {
  const response = await api({
    method: "PUT",
    url: `/transactions/approve-freight-compensation`,
    data,
    source: SERVICE.VELA_API,
  });

  return response;
};

const approveBonus = async (data) => {
  const response = await api({
    method: "PUT",
    url: `/transactions/approve-bonus`,
    data,
    source: SERVICE.VELA_API,
  });

  return response;
};

export {
  getTransactions,
  createTransaction,
  confirmTransaction,
  cancelTransaction,
  approveFreightCompensation,
  approveBonus,
};
