const WS_TYPE = {
  PING: "PING",
  PONG: "PONG",
  INIT: "INIT",
  NOTIFICATION: "NOTIFICATION",
};

const PING_INTERVAL = 10 * 1000; // 10s

export { WS_TYPE, PING_INTERVAL };
