const COLLECT_FEEDBACK_STATUS = {
  NEW: "NEW",
  SEEN: "SEEN",
  PROCESSED: "PROCESSED",
  SKIP: "SKIP",
};

const COLLECT_FEEDBACK_STATUS_LABEL = {
  [COLLECT_FEEDBACK_STATUS.NEW]: "new",
  [COLLECT_FEEDBACK_STATUS.SEEN]: "seen",
  [COLLECT_FEEDBACK_STATUS.PROCESSED]: "processed",
  [COLLECT_FEEDBACK_STATUS.SKIP]: "skip",
};

const FEEDBACK_TYPE = {
  POSITIVE: "POSITIVE",
  NEUTRAL: "NEUTRAL",
  NEGATIVE: "NEGATIVE",
};

const FEEDBACK_TYPE_LABEL = {
  [FEEDBACK_TYPE.POSITIVE]: "positive",
  [FEEDBACK_TYPE.NEUTRAL]: "neutral",
  [FEEDBACK_TYPE.NEGATIVE]: "negative",
};

const FEEDBACK_SOURCE = {
  STORE_OWNER: "STORE_OWNER",
  CONSUMER: "CONSUMER",
  OTHER: "OTHER",
};

const FEEDBACK_SOURCE_LABEL = {
  [FEEDBACK_SOURCE.STORE_OWNER]: "storeOwner",
  [FEEDBACK_SOURCE.CONSUMER]: "consumer",
  [FEEDBACK_SOURCE.OTHER]: "other",
};

const REGISTER_BANNER_STATUS = {
  NEW: "NEW",
  APPROVED: "APPROVED",
  PROCESSING: "PROCESSING",
  GRANTING: "GRANTING",
  DONE: "DONE",
  REJECTED: "REJECTED",
};

const REGISTER_BANNER_STATUS_LABEL = {
  [REGISTER_BANNER_STATUS.NEW]: "new",
  [REGISTER_BANNER_STATUS.APPROVED]: "approved",
  [REGISTER_BANNER_STATUS.PROCESSING]: "processing",
  [REGISTER_BANNER_STATUS.GRANTING]: "granting",
  [REGISTER_BANNER_STATUS.REJECTED]: "rejected",
  [REGISTER_BANNER_STATUS.DONE]: "done",
};

const COMPETITOR_REPORT_STATUS = {
  NEW: "NEW",
  SEEN: "SEEN",
  PROCESSED: "PROCESSED",
  SKIP: "SKIP",
};

const COMPETITOR_REPORT_STATUS_LABEL = {
  [COMPETITOR_REPORT_STATUS.NEW]: "new",
  [COMPETITOR_REPORT_STATUS.SEEN]: "seen",
  [COMPETITOR_REPORT_STATUS.PROCESSED]: "processed",
  [COMPETITOR_REPORT_STATUS.SKIP]: "skip",
};

const MAINTAIN_POSM_STATUS = {
  NEW: "NEW",
  SEEN: "SEEN",
  PROCESSING: "PROCESSING",
  PROCESSED: "PROCESSED",
};

const MAINTAIN_POSM_STATUS_LABEL = {
  [MAINTAIN_POSM_STATUS.NEW]: "new",
  [MAINTAIN_POSM_STATUS.SEEN]: "seen",
  [MAINTAIN_POSM_STATUS.PROCESSING]: "processing",
  [MAINTAIN_POSM_STATUS.PROCESSED]: "processed",
};

const MAINTAIN_POSM_PROBLEMS = {
  NO_PROBLEM: "no-problem",
  NEED_QR_CODE: "need-qr-code",
  NEED_CHANGE_PART: "need-change-part",
  NEED_CHANGE_ADS: "need-change-ads",
  RETAKE_DISPLAY_ITEM: "retake-display-item",
  LOST_DISPLAY_ITEM: "lost-display-item",
};

const MAINTAIN_POSM_PROBLEMS_LABEL = {
  [MAINTAIN_POSM_PROBLEMS.NO_PROBLEM]: "noProblem",
  [MAINTAIN_POSM_PROBLEMS.NEED_QR_CODE]: "needQrCode",
  [MAINTAIN_POSM_PROBLEMS.NEED_CHANGE_PART]: "needChangePart",
  [MAINTAIN_POSM_PROBLEMS.NEED_CHANGE_ADS]: "needChangeAds",
  [MAINTAIN_POSM_PROBLEMS.RETAKE_DISPLAY_ITEM]: "retakeDisplayItem",
  [MAINTAIN_POSM_PROBLEMS.LOST_DISPLAY_ITEM]: "lostDisplayItem",
};

const MAINTAIN_POSM_PRODUCT_STATUS = {
  GOOD: "good",
  NEED_REPAIR: "need-repair",
  NOT_WORKING: "not-working",
};

const MAINTAIN_POSM_PRODUCT_STATUS_LABEL = {
  [MAINTAIN_POSM_PRODUCT_STATUS.GOOD]: "good",
  [MAINTAIN_POSM_PRODUCT_STATUS.NEED_REPAIR]: "needRepair",
  [MAINTAIN_POSM_PRODUCT_STATUS.NOT_WORKING]: "notWorking",
};

const UPDATE_STORE_STATUS = {
  NEW: "NEW",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

const UPDATE_STORE_STATUS_LABEL = {
  [UPDATE_STORE_STATUS.NEW]: "new",
  [UPDATE_STORE_STATUS.APPROVED]: "approved",
  [UPDATE_STORE_STATUS.REJECTED]: "rejected",
};

const STORE_ROUTES = {
  T2: "T2",
  T3: "T3",
  T4: "T4",
  T5: "T5",
  T6: "T6",
  T7: "T7",
  CN: "CN",
};

const VISIT_FREQUENCY = {
  F1: "F1",
  F2: "F2",
  F4: "F4",
  F8: "F8",
};

export {
  COLLECT_FEEDBACK_STATUS,
  COLLECT_FEEDBACK_STATUS_LABEL,
  FEEDBACK_TYPE,
  FEEDBACK_TYPE_LABEL,
  FEEDBACK_SOURCE,
  FEEDBACK_SOURCE_LABEL,
  REGISTER_BANNER_STATUS,
  REGISTER_BANNER_STATUS_LABEL,
  MAINTAIN_POSM_STATUS,
  MAINTAIN_POSM_STATUS_LABEL,
  MAINTAIN_POSM_PROBLEMS,
  MAINTAIN_POSM_PROBLEMS_LABEL,
  MAINTAIN_POSM_PRODUCT_STATUS,
  MAINTAIN_POSM_PRODUCT_STATUS_LABEL,
  COMPETITOR_REPORT_STATUS,
  COMPETITOR_REPORT_STATUS_LABEL,
  UPDATE_STORE_STATUS,
  UPDATE_STORE_STATUS_LABEL,
  STORE_ROUTES,
  VISIT_FREQUENCY,
};
