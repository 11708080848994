const renderStoreAddress = (store) => {
  const { province, district, ward, address } = store || {};
  let displayAddress = address;
  if (ward) {
    displayAddress = `${displayAddress}, ${ward}`;
  }
  if (district) {
    displayAddress = `${displayAddress}, ${district}`;
  }
  if (province) {
    displayAddress = `${displayAddress}, ${province}`;
  }
  return displayAddress;
};

export { renderStoreAddress };
