import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DownloadOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";

import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import StatementList from "./StatementList";
import { WALLET_TYPE } from "../../constants/wallet";
import { ALL, INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import { downloadFile } from "../../utils/download";
import useSearchParams from "../../hooks/useSearchParams";
import ROUTE from "../../constants/route";

const INIT_FILTER = {
  walletType: ALL,
  userId: null,
};

const Statement = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { statementId } = useParams();

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [loading, setLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([
    { title: t("statement"), path: ROUTE.STATEMENT },
  ]);
  const [initializing, setInitializing] = useState(true);
  const [error, setError] = useState(false);
  const [groupStatement, setGroupStatement] = useState({});

  const { addParams } = useSearchParams();

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.code}. ${
      distributor.metadata?.name || distributor.name
    }`;
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const handleChangeWalletType = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, walletType: value }));
    addParams({ walletType: value });
  };

  const handleChangeUserId = (value) => {
    setFilter((prev) => ({ ...prev, userId: value }));
    addParams({ userId: value });
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const {
        walletType = INIT_FILTER.walletType,
        userId = INIT_FILTER.userId,
      } = searchParams;

      await downloadFile({
        url: "/statements/download",
        params: {
          walletType: walletType === ALL ? undefined : walletType,
          userId: userId || undefined,

          groupStatementId: statementId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `statements_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }
    setDownloading(false);
  };

  const fetchDistributors = async () => {
    try {
      const { result } = await apis.user.getUsers({
        pageType: PAGE_TYPE.DISTRIBUTOR,
        sort: ["code_asc"],
      });
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchStatements = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        userId: newFilter.userId || undefined,
        walletType:
          newFilter.walletType === ALL ? undefined : newFilter.walletType,

        groupStatementId: statementId,
      };

      const { result } = await apis.statement.getStatements(condition);
      setStatements(result.statements);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchGroupStatement = async () => {
    setInitializing(true);
    try {
      const { result } = await apis.groupStatement.getGroupStatement(
        statementId
      );
      setGroupStatement(result);
      setBreadcrumbs((prev) => [prev[0], { title: renderTitle(result) }]);
    } catch (error) {
      toast.error(t(error.message));
      setError(true);
      setBreadcrumbs([{ title: t("back"), path: ROUTE.BONUS }]);
    }
    setInitializing(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      walletType = INIT_FILTER.walletType,
      userId = INIT_FILTER.userId,
    } = searchParams;
    setFilter({ walletType, userId });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchStatements({
      walletType,
      userId,
      page: parseInt(page, 10),
    });
  }, [location.search]);

  useEffect(() => {
    fetchGroupStatement();
    fetchDistributors();
  }, []);

  const renderTitle = (groupStatement) => {
    const { month, year } = groupStatement;
    return `${t("month")} ${month}/${year}`;
  };

  const renderContent = () => (
    <>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={7} md={5}>
          <Autocomplete
            size="small"
            value={filter.userId}
            options={distributors.map((distributor) => distributor.id)}
            getOptionLabel={(option) => renderDistributorOptions(option)}
            filterSelectedOptions
            onChange={(event, newValue) => handleChangeUserId(newValue)}
            renderOption={(props, key) => (
              <MenuItem value={key} {...props}>
                {renderDistributorOptions(key)}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField {...params} fullWidth placeholder={t("distributor")} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("walletType")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={filter.walletType}
              onChange={handleChangeWalletType}
              size="small"
              label={t("walletType")}
              fullWidth
            >
              <MenuItem value={ALL}>{t("all")}</MenuItem>
              <MenuItem value={WALLET_TYPE.PAYMENT}>
                {t("paymentWallet")}
              </MenuItem>
              <MenuItem value={WALLET_TYPE.COMMODITY_COMPENSATION}>
                {t("commodityCompensationWallet")}
              </MenuItem>
              <MenuItem value={WALLET_TYPE.FREIGHT_COMPENSATION}>
                {t("freightCompensationWallet")}
              </MenuItem>
              <MenuItem value={WALLET_TYPE.BONUS}>{t("bonusWallet")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <LoadingButton
              loading={downloading}
              loadingPosition="start"
              variant="outlined"
              startIcon={<DownloadOutlined />}
              disabled={!statements.length}
              onClick={handleDownload}
            >
              {t("download")}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      <StatementList
        loading={loading}
        paging={paging}
        handleChangePage={handleChangePage}
        statements={statements}
        distributors={distributors}
      />
    </>
  );

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} />
      <MainCard>
        {initializing ? (
          <CircularProgress />
        ) : error ? (
          <Box padding={2}>
            <Typography variant="body1" textAlign="center">
              {t("bonusNotFound")} !
            </Typography>
          </Box>
        ) : (
          renderContent()
        )}
      </MainCard>
    </>
  );
};

export default Statement;
