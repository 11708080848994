import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import { validateRequired as checkRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";
import {
  REGISTER_BANNER_STATUS,
  REGISTER_BANNER_STATUS_LABEL,
} from "../../constants/storeAction";
import ImagePreview from "../../components/ImagePreview";

const RegisterBannerDialog = ({
  open,
  handleClose,
  registerBanner,
  reload,
  users,
  stores,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");
  const [contact, setContact] = useState("");
  const [contactError, setContactError] = useState("");

  const renderStoreOptions = (id) => {
    const store = stores.find((s) => s.id === id);
    return store?.name || "";
  };

  const renderUserOptions = (id) => {
    const user = users.find((u) => u.id === id);
    if (!user) return "";
    return `${user.metadata?.name || user.name}`;
  };

  const handleCloseDialog = () => {
    handleClose();
    setStatus("");
    setStatusError("");
  };

  const validateStatusRequired = () => {
    if (!checkRequired(status)) {
      setStatusError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validateNameRequired = () => {
    if (!checkRequired(contact)) {
      setContactError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validate = () => validateStatusRequired() && validateNameRequired();

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.storeAction.changeStatusStoreAction(registerBanner.id, {
        status,
        detail: { ...registerBanner.detail, contact },
      });
      handleCloseDialog();
      reload();
      toast.success(t("reviewRegisterBannerSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const isEditable = useMemo(() => {
    return ![
      REGISTER_BANNER_STATUS.REJECTED,
      REGISTER_BANNER_STATUS.DONE,
    ].includes(registerBanner?.detail?.status);
  }, [registerBanner]);

  useEffect(() => {
    if (
      ![REGISTER_BANNER_STATUS.REJECTED, REGISTER_BANNER_STATUS.DONE].includes(
        registerBanner?.detail?.status
      )
    )
      setContact(registerBanner?.detail?.contact);

    if (registerBanner?.detail?.status !== REGISTER_BANNER_STATUS.NEW) {
      setStatus(registerBanner?.detail?.status);
    }
  }, [registerBanner]);

  return (
    <Dialog
      title={t("reviewRegisterBanner")}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(registerBanner).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={isEditable ? 1 : 0}>
                {t("contactInfo")}:
              </Typography>
              {isEditable ? (
                <TextField
                  helperText={contactError}
                  error={!!contactError}
                  value={contact}
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    setContact(e.target.value);
                    setContactError("");
                  }}
                  onBlur={() => validateNameRequired()}
                  placeholder={t("contactInfo")}
                />
              ) : (
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {registerBanner.detail.contact}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500}>{t("reason")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {registerBanner.detail.reason}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("store")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {renderStoreOptions(registerBanner.storeId)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("createdBy")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {renderUserOptions(registerBanner.userId)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("frontImage")}:
              </Typography>
              <ImagePreview
                galleryID="custom-image-gallery-123"
                images={[{ url: registerBanner.detail.primaryImage }]}
                height="100px"
                previewWidth={900}
                previewHeight={1600}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("edgeImage")}:
              </Typography>
              <ImagePreview
                galleryID="custom-image-gallery-12345"
                images={registerBanner.detail.secondImages.map((url) => ({
                  url,
                }))}
                height="100px"
                previewWidth={900}
                previewHeight={1600}
              />
            </Grid>
            {!!registerBanner.detail.specialRequest && (
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500}>{t("specialRequest")}:</Typography>
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {registerBanner.detail.specialRequest}
                </Typography>
              </Grid>
            )}
            {!!registerBanner.detail.note && (
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500}>{t("note")}:</Typography>
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {registerBanner.detail.note}
                </Typography>
              </Grid>
            )}

            {!isEditable && (
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500}>{t("status")}:</Typography>
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {t(
                    REGISTER_BANNER_STATUS_LABEL[registerBanner.detail.status]
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {isEditable && (
          <>
            <Divider />
            <Box mt={3}>
              <Typography fontWeight={500} mb={1}>
                {t("review")} <span style={{ color: COLOR.error.base }}>*</span>
                :
              </Typography>
              <FormControl fullWidth error={!!statusError}>
                <Select
                  value={status}
                  size="small"
                  onChange={(event) => {
                    setStatus(event.target.value);
                    setStatusError("");
                  }}
                >
                  {Object.values(REGISTER_BANNER_STATUS)
                    .filter((value) => value !== REGISTER_BANNER_STATUS.NEW)
                    .map((value) => (
                      <MenuItem key={value} value={value}>
                        {t(REGISTER_BANNER_STATUS_LABEL[value])}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{statusError}</FormHelperText>
              </FormControl>
            </Box>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {isEditable && (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdate}
          >
            {t("confirm")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RegisterBannerDialog;
