import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Dialog from "../../components/Dialog";
import apis from "../../apis";
import { COLOR } from "../../styles/color";
import { PAGE_TYPE } from "../../constants";
import { validateRequired as checkRequired } from "../../utils/validate";

const TransferDistributorDialog = ({ open, handleClose, distributor }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [distributorId, setDistributorId] = useState("");
  const [distributorIdError, setDistributorIdError] = useState("");

  const handleCloseDialog = () => {
    handleClose();
    setDistributorId(null);
    setDistributorIdError("");
  };

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.metadata?.name} - ${distributor.phoneNumber}`;
  };

  const validateRequired = () => {
    if (!checkRequired(distributorId)) {
      setDistributorIdError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validate = () => validateRequired();

  const handleTransfer = async () => {
    if (!validate()) return;
    setProcessing(true);
    try {
      await apis.user.transferDistributor(distributor.id, distributorId);
      handleCloseDialog();
      toast.success(t("handoverOfWorkSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setProcessing(false);
  };

  const getDistributors = async () => {
    setLoading(true);
    try {
      const res = await apis.user.getUsers({
        pageType: PAGE_TYPE.DISTRIBUTOR,
        region: distributor.region,
        sort: ["code_asc"],
        active: true,
        isStopCooperate: false,
      });
      setDistributors(res.result);
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (distributor.region) getDistributors();
  }, [distributor.region]);

  return (
    <Dialog
      title={t("handoverOfWork")}
      subTitle={`${distributor.name} - ${distributor.phoneNumber}`}
      maxWidth="xs"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography fontWeight={500} mb={1}>
              {t("chooseDistributors")}{" "}
              <span style={{ color: COLOR.error.base }}>*</span>:
            </Typography>
            <Autocomplete
              size="small"
              value={distributorId}
              options={distributors
                .filter((d) => d.id !== distributor.id)
                .map((d) => d.id)}
              getOptionLabel={(option) => renderDistributorOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) => setDistributorId(newValue)}
              onBlur={validateRequired}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderDistributorOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!distributorIdError}
                  helperText={distributorIdError}
                  fullWidth
                />
              )}
            />
            <Typography pt={3} color="error" align="center">
              {t("handoverOfWorkDesc")}
            </Typography>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("close")}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={processing}
          onClick={handleTransfer}
        >
          {t("confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TransferDistributorDialog;
