import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import { validateRequired as checkRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";
import {
  COLLECT_FEEDBACK_STATUS,
  COLLECT_FEEDBACK_STATUS_LABEL,
  FEEDBACK_SOURCE_LABEL,
  FEEDBACK_TYPE_LABEL,
} from "../../constants/storeAction";
import ImagePreview from "../../components/ImagePreview";

const FeedbackDialog = ({
  open,
  handleClose,
  feedback,
  reload,
  users,
  stores,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");

  const renderStoreOptions = (id) => {
    const store = stores.find((s) => s.id === id);
    return store?.name || "";
  };

  const renderUserOptions = (id) => {
    const user = users.find((u) => u.id === id);
    if (!user) return "";
    return `${user.metadata?.name || user.name}`;
  };

  const handleCloseDialog = () => {
    handleClose();
    setStatus("");
    setStatusError("");
  };

  const validateStatusRequired = () => {
    if (!checkRequired(status)) {
      setStatusError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validate = () => validateStatusRequired();

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.storeAction.changeStatusStoreAction(feedback.id, {
        status,
      });
      handleCloseDialog();
      reload();
      toast.success(t("reviewFeedbackSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  return (
    <Dialog
      title={t("reviewFeedback")}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(feedback).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500}>{t("feedbackContent")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {feedback.detail.content}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("feedbackType")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {t(FEEDBACK_TYPE_LABEL[feedback.detail.type])}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("feedbackSource")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {t(FEEDBACK_SOURCE_LABEL[feedback.detail.source])}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("store")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {renderStoreOptions(feedback.storeId)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("createdBy")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {renderUserOptions(feedback.userId)}
              </Typography>
            </Grid>
            {!!feedback.detail.images && !!feedback.detail.images.length && (
              <Grid item xs={12} sm={12}>
                <Typography fontWeight={500} mb={1}>
                  {t("image")}:
                </Typography>
                <ImagePreview
                  galleryID="custom-image-gallery-12345"
                  images={feedback.detail.images.map((url) => ({
                    url,
                  }))}
                  height="100px"
                  previewWidth={900}
                  previewHeight={1600}
                />
              </Grid>
            )}
            {[
              COLLECT_FEEDBACK_STATUS.PROCESSED,
              COLLECT_FEEDBACK_STATUS.SKIP,
            ].includes(feedback.detail.status) && (
              <Grid item xs={12} sm={12}>
                <Typography fontWeight={500}>{t("status")}:</Typography>
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {t(COLLECT_FEEDBACK_STATUS_LABEL[feedback.detail.status])}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {[COLLECT_FEEDBACK_STATUS.NEW, COLLECT_FEEDBACK_STATUS.SEEN].includes(
          feedback.detail?.status
        ) && (
          <>
            <Divider />
            <Box mt={3}>
              <Typography fontWeight={500} mb={1}>
                {t("review")} <span style={{ color: COLOR.error.base }}>*</span>
                :
              </Typography>
              <FormControl fullWidth error={!!statusError}>
                <Select
                  value={status}
                  size="small"
                  onChange={(event) => {
                    setStatus(event.target.value);
                    setStatusError("");
                  }}
                >
                  <MenuItem value={COLLECT_FEEDBACK_STATUS.PROCESSED}>
                    {t(
                      COLLECT_FEEDBACK_STATUS_LABEL[
                        COLLECT_FEEDBACK_STATUS.PROCESSED
                      ]
                    )}
                  </MenuItem>
                  <MenuItem value={COLLECT_FEEDBACK_STATUS.SKIP}>
                    {t(
                      COLLECT_FEEDBACK_STATUS_LABEL[
                        COLLECT_FEEDBACK_STATUS.SKIP
                      ]
                    )}
                  </MenuItem>
                </Select>
                <FormHelperText>{statusError}</FormHelperText>
              </FormControl>
            </Box>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {[COLLECT_FEEDBACK_STATUS.NEW, COLLECT_FEEDBACK_STATUS.SEEN].includes(
          feedback.detail?.status
        ) && (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdate}
          >
            {t("confirm")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
