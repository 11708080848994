import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { DateTimePicker } from "@mui/x-date-pickers";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import {
  validateRequired as checkRequired,
  validateDate as checkDate,
} from "../../utils/validate";
import { COLOR } from "../../styles/color";
import {
  DATE_TIME_FORMAT,
  DIALOG_TYPE,
  OA_MESSAGE_STATUS,
  SEND_OA_MESSAGE_MODE,
  SEND_OA_MESSAGE_MODE_LABEL,
} from "../../constants";
import dayjs from "dayjs";

const INIT_MESSAGE_ERROR = {
  templateId: "",
  scheduledAt: "",
  sendMode: "",
};

const OaMessageDialog = ({
  open,
  handleClose,
  dialogType,
  initMessage,
  templates,
  regions,
  provinces,
  distributors,
  reload,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [messageError, setMessageError] = useState(INIT_MESSAGE_ERROR);

  const renderTemplateOptions = (id) => {
    const template = templates.find((t) => t.id === id);
    return template.title || "-";
  };

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.code}. ${
      distributor.metadata?.name || distributor.name
    }`;
  };

  const handleChangeTemplateId = (newValue) => {
    setMessage((prev) => ({ ...prev, templateId: newValue }));
    setMessageError((prev) => ({ ...prev, templateId: "" }));
  };

  const handleChangeRegions = (newValue) => {
    let newProvinces = [...message.provinces];
    let newDistributorIds = [...message.distributorIds];
    if (newValue.length) {
      const validProvinces = provinces
        .filter(({ region }) => newValue.includes(region))
        .map(({ name }) => name);
      newProvinces = newProvinces.filter((province) =>
        validProvinces.includes(province)
      );
      const validDistributorIds = distributors
        .filter(({ province }) => validProvinces.includes(province))
        .map(({ id }) => id);
      newDistributorIds = newDistributorIds.filter((distributorId) =>
        validDistributorIds.includes(distributorId)
      );
    }
    setMessage((prev) => ({
      ...prev,
      regions: newValue,
      provinces: newProvinces,
      distributorIds: newDistributorIds,
    }));
  };

  const handleChangeProvinces = (newValue) => {
    let newDistributorIds = [...message.distributorIds];
    if (newValue.length) {
      const validDistributorIds = distributors
        .filter(({ province }) => newValue.includes(province))
        .map(({ id }) => id);
      newDistributorIds = newDistributorIds.filter((distributorId) =>
        validDistributorIds.includes(distributorId)
      );
    }

    setMessage((prev) => ({
      ...prev,
      provinces: newValue,
      distributorIds: newDistributorIds,
    }));
  };

  const handleChangeDistributorIds = (newValue) => {
    setMessage((prev) => ({
      ...prev,
      distributorIds: newValue,
    }));
  };

  const handleChangeMode = (event) => {
    const { value } = event.target;
    setMessage((prev) => ({ ...prev, sendMode: value }));
    setMessageError((prev) => ({ ...prev, sendMode: "" }));
  };

  const handleChangeDateTime = (newValue) => {
    setMessage((prev) => ({ ...prev, scheduledAt: newValue }));
    setMessageError((prev) => ({ ...prev, scheduledAt: "" }));
  };

  const handleCloseDialog = () => {
    handleClose();
    setMessage({});
    setMessageError({});
  };

  const validateRequired = (field) => {
    if (!checkRequired(message[field])) {
      setMessageError((prev) => ({ ...prev, [field]: t("fieldRequired") }));
      return false;
    }
    return true;
  };

  const validateDate = (field) => {
    if (message[field] && !checkDate(message[field], DATE_TIME_FORMAT)) {
      setMessageError((prev) => ({
        ...prev,
        [field]: t("timeInvalid"),
      }));
      return false;
    }

    return true;
  };

  const validate = () =>
    validateRequired("templateId") &&
    validateRequired("sendMode") &&
    (message.sendMode !== SEND_OA_MESSAGE_MODE.SCHEDULER ||
      (message.sendMode === SEND_OA_MESSAGE_MODE.SCHEDULER &&
        validateRequired("scheduledAt")));

  const handleCreate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.oaMessage.createOaMessage(message);
      handleCloseDialog();
      reload();
      toast.success(t("addOaMessageSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      const { id, status, ...data } = message;
      await apis.oaMessage.updateOaMessage(id, data);
      handleCloseDialog();
      reload();
      toast.success(t("editOaMessageSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) setMessage(initMessage);
  }, [open, initMessage]);

  const isEditable = useMemo(() => {
    return [
      OA_MESSAGE_STATUS.SENDING,
      OA_MESSAGE_STATUS.COMPLETED,
      OA_MESSAGE_STATUS.FAILED,
    ].includes(message.status);
  }, [message.status]);

  return (
    <Dialog
      title={
        dialogType === DIALOG_TYPE.CREATE
          ? t("addOaMessage")
          : !isEditable
          ? t("editOaMessage")
          : t("oaMessage")
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(message).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={1}>
                {t("oaMessageTemplate")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <Autocomplete
                size="small"
                value={message.templateId}
                options={templates.map((template) => template.id)}
                getOptionLabel={(option) => renderTemplateOptions(option)}
                filterSelectedOptions
                onChange={(event, newValue) => handleChangeTemplateId(newValue)}
                onBlur={() => validateRequired("templateId")}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {renderTemplateOptions(key)}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!messageError.templateId}
                    helperText={messageError.templateId}
                  />
                )}
                disabled={isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("region")}:
              </Typography>
              <Autocomplete
                multiple
                size="small"
                value={message.regions}
                options={regions}
                getOptionLabel={(option) => `${t("region")} ${option}`}
                disableCloseOnSelect
                onChange={(event, newValue) => handleChangeRegions(newValue)}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        checked={selected}
                      />
                      {`${t("region")} ${option}`}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                disabled={isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("province")}:
              </Typography>
              <Autocomplete
                multiple
                size="small"
                value={message.provinces}
                options={provinces
                  .filter(
                    ({ region }) =>
                      !message.regions.length ||
                      message.regions.includes(region)
                  )
                  .map(({ name }) => name)}
                disableCloseOnSelect
                onChange={(event, newValue) => handleChangeProvinces(newValue)}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        // style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                disabled={isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={1}>
                {t("distributor")}:
              </Typography>
              <Autocomplete
                multiple
                size="small"
                value={message.distributorIds}
                options={distributors
                  .filter(
                    ({ region }) =>
                      !message.regions.length ||
                      message.regions.includes(region)
                  )
                  .filter(
                    ({ province }) =>
                      !message.provinces.length ||
                      message.provinces.includes(province)
                  )
                  .map((distributor) => distributor.id)}
                getOptionLabel={(option) => renderDistributorOptions(option)}
                disableCloseOnSelect
                onChange={(event, newValue) =>
                  handleChangeDistributorIds(newValue)
                }
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        // style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {renderDistributorOptions(option)}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                disabled={isEditable}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography fontWeight={500}>
                {t("timeToSend")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <FormControl error={!!messageError.sendMode}>
                <RadioGroup
                  value={message.sendMode}
                  onChange={handleChangeMode}
                >
                  {Object.values(SEND_OA_MESSAGE_MODE).map((value) => (
                    <FormControlLabel
                      value={value}
                      control={<Radio />}
                      label={t(SEND_OA_MESSAGE_MODE_LABEL[value])}
                      key={value}
                      disabled={isEditable}
                    />
                  ))}
                </RadioGroup>
                <FormHelperText>{messageError.sendMode}</FormHelperText>
              </FormControl>
            </Grid>
            {message.sendMode === SEND_OA_MESSAGE_MODE.SCHEDULER && (
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  {t("scheduledAt")}{" "}
                  <span style={{ color: COLOR.error.base }}>*</span>:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    name="scheduledAt"
                    inputFormat={DATE_TIME_FORMAT}
                    value={message.scheduledAt || null}
                    onChange={(newValue) => handleChangeDateTime(newValue)}
                    minDate={dayjs()}
                    ampm={false}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        onBlur={() =>
                          validateRequired("scheduledAt") &&
                          validateDate("scheduledAt")
                        }
                        error={!!messageError.scheduledAt}
                        helperText={messageError.scheduledAt}
                      />
                    )}
                    disabled={isEditable}
                  />
                </LocalizationProvider>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {dialogType === DIALOG_TYPE.CREATE ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreate}
          >
            {t("addNew")}
          </LoadingButton>
        ) : (
          !isEditable && (
            <LoadingButton
              variant="contained"
              color="success"
              loading={loading}
              onClick={handleUpdate}
            >
              {t("save")}
            </LoadingButton>
          )
        )}
      </DialogActions>
    </Dialog>
  );
};

export default OaMessageDialog;
