import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import ImagePreview from "../../components/ImagePreview";
import {
  COMPETITOR_REPORT_STATUS,
  COMPETITOR_REPORT_STATUS_LABEL,
} from "../../constants/storeAction";
import { COLOR } from "../../styles/color";
import { validateRequired as checkRequired } from "../../utils/validate";
import CurrencyInput from "../../components/CurrencyInput";

const InputWithLabel = ({
  label,
  value,
  setValue,
  placeholder,
  error,
  inputLabel,
  isRequired,
  disabled,
}) => (
  <>
    {label && (
      <Typography fontWeight={500}>
        {label}{" "}
        {isRequired && <span style={{ color: COLOR.error.base }}>*</span>}:
      </Typography>
    )}
    <TextField
      label={inputLabel}
      error={!!error}
      helperText={error}
      value={value}
      size="small"
      fullWidth
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
    />
  </>
);

const INIT_ERROR = {
  productName: "",
  brandName: "",
};

const CompetitorProductDialog = ({
  open,
  handleClose,
  data,
  reload,
  users,
  stores,
}) => {
  const { t } = useTranslation();

  const [actionDetail, setActionDetail] = useState(data.detail || {});
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");
  const [error, setError] = useState(INIT_ERROR);

  const isEditable = useMemo(() => {
    return [
      COMPETITOR_REPORT_STATUS.NEW,
      COMPETITOR_REPORT_STATUS.SEEN,
    ].includes(data?.detail?.status);
  }, [data]);

  const renderStoreOptions = (id) => {
    const store = stores.find((s) => s.id === id);
    return store?.name || "";
  };

  const renderUserOptions = (id) => {
    const user = users.find((u) => u.id === id);
    if (!user) return "";
    return `${user.metadata?.name || user.name}`;
  };

  const handleCloseDialog = () => {
    handleClose();
    setStatus("");
    setStatusError("");
    setError(INIT_ERROR);
  };

  const handleChangeDetailField = (field, value) => {
    setActionDetail((prev) => ({
      ...prev,
      [field]: value,
    }));
    setError((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const validateStatusRequired = () => {
    let isValid = true;
    if (!checkRequired(status)) {
      setStatusError(t("fieldRequired"));
      isValid = false;
    }
    const errorObject = {};
    Object.keys(INIT_ERROR).forEach((key) => {
      if (!checkRequired(actionDetail[key])) {
        errorObject[key] = t("fieldRequired");
        isValid = false;
      }
    });
    setError(errorObject);
    return isValid;
  };

  const validate = () => validateStatusRequired();

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.storeAction.changeStatusStoreAction(data.id, {
        status,
        detail: actionDetail,
      });
      handleCloseDialog();
      reload();
      toast.success(t("reviewCompetitorProductSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data.detail) setActionDetail(data.detail);
  }, [data]);

  return (
    <Dialog
      title={t("reviewCompetitorProduct")}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(data).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <InputWithLabel
                label={t("productName")}
                value={actionDetail.productName}
                setValue={(value) =>
                  handleChangeDetailField("productName", value)
                }
                disabled={!isEditable}
                isRequired
                error={error.productName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputWithLabel
                label={t("brandName")}
                value={actionDetail.brandName}
                setValue={(value) =>
                  handleChangeDetailField("brandName", value)
                }
                disabled={!isEditable}
                isRequired
                error={error.brandName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("weight") + " (g)"}:</Typography>
              <CurrencyInput
                customInput={TextField}
                allowDecimals={false}
                allowNegativeValue={false}
                fullWidth
                value={actionDetail.weight}
                onChange={(e) =>
                  handleChangeDetailField("weight", e.target.value)
                }
                name="weight"
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>
                {t("retailPrice") + " (đ)"}:
              </Typography>
              <CurrencyInput
                customInput={TextField}
                allowDecimals={false}
                allowNegativeValue={false}
                fullWidth
                value={actionDetail.retailPrice}
                onChange={(e) =>
                  handleChangeDetailField("retailPrice", e.target.value)
                }
                name="retailPrice"
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>
                {t("supplyPrice") + " (đ)"}:
              </Typography>
              <CurrencyInput
                customInput={TextField}
                allowDecimals={false}
                allowNegativeValue={false}
                fullWidth
                value={actionDetail.supplyPrice}
                onChange={(e) =>
                  handleChangeDetailField("supplyPrice", e.target.value)
                }
                name="supplyPrice"
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>
                {t("expiryDays") + " (ngày)"}:
              </Typography>
              <CurrencyInput
                customInput={TextField}
                allowDecimals={false}
                allowNegativeValue={false}
                fullWidth
                value={actionDetail.expiryDays}
                onChange={(e) =>
                  handleChangeDetailField("expiryDays", e.target.value)
                }
                name="expiryDays"
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputWithLabel
                label={t("sellingStatus")}
                value={actionDetail.sellingStatus}
                setValue={(value) =>
                  handleChangeDetailField("sellingStatus", value)
                }
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputWithLabel
                label={t("note")}
                value={actionDetail.note}
                setValue={(value) => handleChangeDetailField("note", value)}
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("store")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {renderStoreOptions(data.storeId)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("createdBy")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {renderUserOptions(data.userId)}
              </Typography>
            </Grid>
            {!!data.detail.productImages &&
              !!data.detail.productImages.length && (
                <Grid item xs={12} sm={6}>
                  <Typography fontWeight={500} mb={1}>
                    {t("productImage")}:
                  </Typography>
                  <ImagePreview
                    galleryID="custom-image-gallery-12345"
                    images={data.detail.productImages.map((url) => ({
                      url,
                    }))}
                    height="100px"
                    previewWidth={900}
                    previewHeight={1600}
                  />
                </Grid>
              )}
            {!!data.detail.displayProductImages &&
              !!data.detail.displayProductImages.length && (
                <Grid item xs={12} sm={6}>
                  <Typography fontWeight={500} mb={1}>
                    {t("displayProductImage")}:
                  </Typography>
                  <ImagePreview
                    galleryID="custom-image-gallery-12345"
                    images={data.detail.displayProductImages.map((url) => ({
                      url,
                    }))}
                    height="100px"
                    previewWidth={900}
                    previewHeight={1600}
                  />
                </Grid>
              )}
            {[
              COMPETITOR_REPORT_STATUS.PROCESSED ||
                COMPETITOR_REPORT_STATUS.SKIP,
            ].includes(data.detail.status) && (
              <Grid item xs={12} sm={12}>
                <Typography fontWeight={500}>{t("status")}:</Typography>
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {t(COMPETITOR_REPORT_STATUS_LABEL[data.detail.status])}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {[COMPETITOR_REPORT_STATUS.NEW, COMPETITOR_REPORT_STATUS.SEEN].includes(
          data.detail?.status
        ) && (
          <>
            <Divider />
            <Box mt={3}>
              <Typography fontWeight={500} mb={1}>
                {t("review")} <span style={{ color: COLOR.error.base }}>*</span>
                :
              </Typography>
              <FormControl fullWidth error={!!statusError}>
                <Select
                  value={status}
                  size="small"
                  onChange={(event) => {
                    setStatus(event.target.value);
                    setStatusError("");
                  }}
                >
                  <MenuItem value={COMPETITOR_REPORT_STATUS.PROCESSED}>
                    {t(
                      COMPETITOR_REPORT_STATUS_LABEL[
                        COMPETITOR_REPORT_STATUS.PROCESSED
                      ]
                    )}
                  </MenuItem>
                  <MenuItem value={COMPETITOR_REPORT_STATUS.SKIP}>
                    {t(
                      COMPETITOR_REPORT_STATUS_LABEL[
                        COMPETITOR_REPORT_STATUS.SKIP
                      ]
                    )}
                  </MenuItem>
                </Select>
                <FormHelperText>{statusError}</FormHelperText>
              </FormControl>
            </Box>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {[COMPETITOR_REPORT_STATUS.NEW, COMPETITOR_REPORT_STATUS.SEEN].includes(
          data.detail?.status
        ) && (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdate}
          >
            {t("confirm")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CompetitorProductDialog;
