import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PermMediaOutlined from "@mui/icons-material/PermMediaOutlined";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ROUTE from "../../constants/route";

export const menus = [
  {
    label: "home",
    icon: <HomeOutlinedIcon />,
    path: ROUTE.DASHBOARD,
  },
  {
    label: "userManagement",
    icon: <PeopleAltOutlinedIcon />,
    path: ROUTE.USER,
  },
  {
    label: "distributorManagement",
    icon: <CallSplitIcon />,
    path: ROUTE.DISTRIBUTOR,
  },
  {
    label: "storeManagement",
    icon: <StoreMallDirectoryOutlinedIcon />,
    path: ROUTE.STORE,
  },
  {
    label: "timesheetManagement",
    icon: <PinDropOutlinedIcon />,
    path: ROUTE.TIMESHEET,
  },
  {
    label: "bannerManagement",
    icon: <PermMediaOutlined />,
    path: ROUTE.BANNER,
  },
  {
    label: "salesManagement",
    icon: <LocalGroceryStoreOutlinedIcon />,
    subMenu: [
      { label: "discountManagement", path: ROUTE.DISCOUNT },
      { label: "orderManagement", path: ROUTE.ORDER },
      { label: "productManagement", path: ROUTE.PRODUCT },
      { label: "categoryManagement", path: ROUTE.CATEGORY },
      { label: "distributorOrder", path: ROUTE.DISTRIBUTOR_ORDER },
    ],
  },
  {
    label: "warehouse",
    icon: <WarehouseOutlinedIcon />,
    subMenu: [{ label: "distributorOrder", path: ROUTE.WAREHOUSE_ORDER }],
  },
  {
    label: "accountant",
    icon: <EventNoteOutlinedIcon />,
    subMenu: [
      { label: "depositTicket", path: ROUTE.TRANSACTION_COLLECT },
      { label: "withdrawTicket", path: ROUTE.TRANSACTION_WITHDRAW },
      { label: "paymentAccount", path: ROUTE.PAYMENT_ACCOUNT },
      { label: "freightCompensation", path: ROUTE.FREIGHT_COMPENSATION },
      { label: "bonus", path: ROUTE.BONUS },
      { label: "transaction", path: ROUTE.TRANSACTION },
      { label: "statement", path: ROUTE.STATEMENT },
    ],
  },
  {
    label: "sendOaMessage",
    icon: <MessageOutlinedIcon />,
    subMenu: [
      {
        label: "oaMessage",
        path: ROUTE.OA_MESSAGE,
      },
      {
        label: "oaMessageTemplate",
        path: ROUTE.OA_MESSAGE_TEMPLATE,
      },
    ],
  },
  {
    label: "activities",
    icon: <LocalActivityOutlinedIcon />,
    subMenu: [
      {
        label: "maintainPosm",
        path: ROUTE.MAINTAIN_POSM,
      },
      {
        label: "newCompetitorProduct",
        path: ROUTE.NEW_COMPETITOR_PRODUCT,
      },
      {
        label: "feedback",
        path: ROUTE.FEEDBACK,
      },
      {
        label: "registerBanner",
        path: ROUTE.REGISTER_BANNER,
      },
      {
        label: "updateStore",
        path: ROUTE.UPDATE_STORE,
      },
      {
        label: "workReports",
        path: ROUTE.WORK_REPORTS,
      },
    ],
  },
  {
    label: "system",
    icon: <SettingsOutlinedIcon />,
    subMenu: [
      {
        label: "roleManagement",
        path: ROUTE.ROLE,
      },
      {
        label: "permissionManagement",
        path: ROUTE.PERMISSION,
      },
    ],
  },
];
