import { useTranslation } from "react-i18next";
import { Edit } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import { DIALOG_TYPE } from "../../constants";

const OaMessageTemplateList = ({
  loading,
  paging,
  handleChangePage,
  templates,
  handleOpenDialog,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("title"),
      align: "left",
      field: "title",
    },
    {
      title: t("description"),
      align: "left",
      field: "description",
    },
    {
      title: t("tableContent"),
      align: "left",
      render: (row) =>
        row.items.map((item) => (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography>{item.key}</Typography>
            <Typography fontWeight={500}>{item.value}</Typography>
          </Stack>
        )),
    },
    {
      title: t("openMiniAppDesc"),
      align: "left",
      render: (row) => (
        <>
          {!row.openMiniApp ? (
            <Typography sx={{ color: COLOR.error.base }}>{t("n")}</Typography>
          ) : (
            <Typography sx={{ color: COLOR.success.base }}>{t("y")}</Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  onClick={() => handleOpenDialog(DIALOG_TYPE.UPDATE, row)}
                  color="edit"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={templates}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default OaMessageTemplateList;
