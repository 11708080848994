import { SERVICE } from "../constants";
import api from "./api";

const getOaMessages = async (params) => {
  const res = await api({
    method: "GET",
    url: `/oa-messages`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

const createOaMessage = async (data) => {
  const res = await api({
    method: "POST",
    url: `/oa-messages`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const updateOaMessage = async (id, data) => {
  const res = await api({
    method: "PUT",
    url: `/oa-messages/${id}`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

export { getOaMessages, createOaMessage, updateOaMessage };
