import React, { useState } from "react";
import { Table } from "@mui/material";
import { StyledTable } from "./index.style";
import CustomTableHeader from "./TableHeader";
import CustomTableBody from "./TableBody";
import CustomTableFooter from "./TableFooter";
import NoData from "../NoData";
import { PAGINATION_LIMIT } from "../../constants";

// columns, data, total, page, onChangePage are required
const CustomTable = ({
  columns,
  data = [],
  total,
  page,
  limit = PAGINATION_LIMIT,
  cellWidth,
  selection,
  loading,
  showNumber,
  onChangePage,
  onRowClick,
  disablePagination,
  minWidth = "1266px",
}) => {
  const [selected, setSelected] = useState([]);
  const [sort, setSort] = useState("");

  const isSelected = (id) => selected.includes(id);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((rowData) => rowData.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectRow = (id) => (event) => {
    if (event.target.checked && !selected.includes(id)) {
      setSelected([...selected, id]);
      return;
    }
    setSelected(selected.filter((item) => item !== id));
  };

  const getSortedData = () => {
    if (!sort) return data;
    const [sortField, sorter] = sort.split("_");
    const column = columns.find((c) => c.field === sortField);
    if (!column) return data;
    let d = data;
    if (column.render) {
      d = data.map((item) => ({ ...item, [sortField]: column.render(item) }));
    }
    return d.sort((item1, item2) => {
      const value1 = (item1[sortField] || "").toLowerCase();
      const value2 = (item2[sortField] || "").toLowerCase();
      if (sorter === "asc") return handleSort(value1, value2, column.type);

      return handleSort(value2, value1, column.type);
    });
  };

  const handleSort = (value1, value2, type) => {
    switch (type) {
      case "number":
        return value1 - value2;

      default:
        return value1.localeCompare(value2);
    }
  };

  const onChangeSort = (value) => setSort(value);

  // TODO: fixed table body height when change page
  // TODO: add loading data and show no data
  // TODO: add handle when selection checkbox
  return (
    <>
      <StyledTable width={cellWidth}>
        <Table sx={{ minWidth }}>
          <CustomTableHeader
            columns={columns}
            selection={selection}
            sort={sort}
            showNumber={showNumber}
            numSelected={selected.length}
            rowCount={data.length}
            onSelectAll={handleSelectAll}
            onChangeSort={onChangeSort}
          />
          <CustomTableBody
            page={page}
            limit={limit}
            columns={columns}
            data={getSortedData(data)}
            selection={selection}
            showNumber={showNumber}
            isSelected={isSelected}
            loading={loading}
            onSelectRow={handleSelectRow}
            onRowClick={onRowClick}
          />
        </Table>
      </StyledTable>
      {data && data.length
        ? !disablePagination && (
            <CustomTableFooter
              total={total}
              page={page}
              limit={limit}
              onChangePage={onChangePage}
            />
          )
        : !loading && <NoData />}
    </>
  );
};

export default CustomTable;
