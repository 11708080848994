import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Table from "../../components/Table";
import { TRANSACTION_STATUS, WALLET_TYPE } from "../../constants/wallet";
import { COLOR } from "../../styles/color";
import moment from "moment";

const TransactionList = ({
  loading,
  paging,
  handleChangePage,
  transactions,
}) => {
  const { t } = useTranslation();

  const renderTransactionStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.PENDING:
        return <Typography color={COLOR.warning.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.FAILED:
        return <Typography color={COLOR.error.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.CANCELED:
        return <Typography color={COLOR.error.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.COMPLETED:
        return <Typography color={COLOR.success.base}>{t(status)}</Typography>;
      default:
        return <Typography>{t(status)}</Typography>;
    }
  };

  const renderWalletType = (walletType) => {
    switch (walletType) {
      case WALLET_TYPE.PAYMENT:
        return t("paymentWallet");
      case WALLET_TYPE.COMMODITY_COMPENSATION:
        return t("commodityCompensationWallet");
      case WALLET_TYPE.FREIGHT_COMPENSATION:
        return t("freightCompensationWallet");
      case WALLET_TYPE.BONUS:
        return t("bonusWallet");
      default:
        return "";
    }
  };

  const columns = [
    {
      title: t("walletType"),
      align: "left",
      render: (row) => (
        <Typography>{renderWalletType(row.walletType)}</Typography>
      ),
    },
    {
      title: t("distributor"),
      align: "left",
      render: (row) => <Typography>{row.user?.metadata?.name}</Typography>,
    },
    {
      title: t("region"),
      align: "left",
      render: (row) => (
        <Typography>
          {t("region")} {row.user?.region}
        </Typography>
      ),
    },
    {
      title: t("transactionType"),
      align: "left",
      render: (row) => t(row.type.toLowerCase()),
    },
    {
      title: t("totalMoney"),
      align: "right",
      sortable: true,
      width: "150px",
      field: "totalMoney",
      render: (row) => `${row.amount?.toLocaleString()} đ`,
    },
    {
      title: t("currentBalance"),
      align: "right",
      width: "150px",
      render: (row) =>
        ![null, undefined].includes(row.currentBalance)
          ? `${row.currentBalance?.toLocaleString()} đ`
          : "-",
    },
    {
      title: t("createdAt"),
      align: "left",
      width: "135px",
      render: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: t("completedAt"),
      align: "left",
      width: "200px",
      render: (row) =>
        row.completedAt
          ? moment(row.completedAt).format("DD/MM/YYYY HH:mm")
          : "-",
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => renderTransactionStatus(row.status),
    },
    {
      title: t("note"),
      align: "left",
      field: "memo",
      width: "100px",
    },
  ];

  return (
    <Table
      data={transactions}
      columns={columns}
      total={paging.total}
      page={paging.page}
      loading={loading}
      onChangePage={handleChangePage}
      showNumber={true}
    />
  );
};

export default TransactionList;
