import { SERVICE } from "../constants";
import api from "./api";

export const getWorkReports = async (params) => {
  const res = await api({
    method: "GET",
    url: `/work-reports`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};
