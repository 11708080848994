import { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import {
  Add,
  ArrowBackIos,
  Autorenew,
  CheckOutlined,
  Clear,
  DeleteOutlineOutlined,
  Edit,
  LocalPrintshopOutlined,
  SaveOutlined,
  SettingsBackupRestore,
} from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import apis from "../../apis";
import ROUTE from "../../constants/route";
import PageTitle from "../../components/PageTitle";
import DistributorInfo from "./DistributorInfo";
import MainCard from "../../components/MainCard";
import { StyledTable } from "./index.style";
import CurrencyInput from "../../components/CurrencyInput";
import { validateRequired } from "../../utils/validate";
import {
  DISTRIBUTOR_ORDER_STATUS,
  SHIPPING_METHOD,
  SHIPPING_METHOD_LABEL,
} from "../../constants/order";
import { formatNumber } from "../../utils/number";
import { COLOR } from "../../styles/color";
import DistributorOrderStatus from "../../components/DistributorOrderStatus";
import Invoice from "./Invoice";

const INIT_ITEM = {
  productId: null,
  code: "",
  name: "",
  price: "",
  quantity: "",
  image: "",
  category: "",
  productPerBox: "",
  discountType: "",
  sizeType: "",
  discount: "",
  freightCollect: "",
  total: "",
  note: "",
  pricePerBox: "",
};

const EMPTY_VALUES = [null, undefined];

const DistributorOrderDetail = ({ prevPath }) => {
  const { t } = useTranslation();
  const { distributorOrderId } = useParams();
  const role = useSelector((state) => state.role);
  const history = useHistory();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([
    { title: t("distributorOrder"), path: prevPath || ROUTE.DISTRIBUTOR_ORDER },
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [distributor, setDistributor] = useState({});
  const [wallet, setWallet] = useState({});
  const [order, setOrder] = useState({});
  const [shippingMethod, setShippingMethod] = useState("");
  const [shippingMethodError, setShippingMethodError] = useState("");
  const [items, setItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [itemsError, setItemsError] = useState([]);
  const [rates, setRates] = useState([]);
  const [exceptionRates, setExceptionRates] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [approving, setApproving] = useState(false);
  const [shipper, setShipper] = useState("");
  const [shipperError, setShipperError] = useState("");
  const [canceling, setCanceling] = useState(false);
  const [backing, setBacking] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const componentRef = useRef();

  const renderProductOptions = (id) => {
    const product = products.find((d) => d.id === id);
    if (!product) return "";
    return `[${product.code}] ${product.name}`;
  };

  const handleChangeShippingMethod = (e) => {
    const { value } = e.target;
    setShippingMethod(value);
    setShippingMethodError("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    let newItem = { ...items[index], [field]: value };
    if (field === "productId") {
      const product = products.find((d) => d.id === value);
      const exceptionRate = exceptionRates.find((er) => er.productId === value);
      newItem = {
        ...newItem,
        code: product?.code,
        name: product?.name,
        price: product?.price,
        image: product?.image,
        category: product?.productCategory?.name || "",
        productPerBox: product?.productPerBox,
        discountType: product?.discountType,
        sizeType: product?.sizeType,
        pricePerBox:
          ((exceptionRate?.price ?? product?.price ?? 0) *
            (product?.productPerBox ?? 0)) ?? 0,
        quantity: newItem.quantity || 1,
      };
    }
    const newItems = [...items];
    newItems[index] = newItem;
    setItems(newItems);

    let newItemError = { ...itemsError[index], [field]: false };
    if (
      [items[index].productId, items[index].quantity].every(
        (value) => !validateRequired(value)
      )
    )
      newItemError = INIT_ITEM;
    const newItemsError = [...itemsError];
    newItemsError[index] = newItemError;
    setItemsError(newItemsError);
  };

  const validateItem = (event, initName) => {
    const name = event ? event.target.name : initName;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const value = items[index][field];
    let isError = false;
    if (
      ![items[index].productId, items[index].quantity].every(
        (value) => !validateRequired(value)
      )
    ) {
      if (!validateRequired(value)) {
        isError = true;
      }
    }

    const newItemsErrors = [...itemsError];
    newItemsErrors[index] = { ...itemsError[index], [field]: isError };
    setItemsError(newItemsErrors);

    return !isError;
  };

  const validateShippingMethod = () => {
    if (!validateRequired(shippingMethod)) {
      setShippingMethodError(t("fieldRequired"));
      return false;
    }

    return true;
  };

  const handleAddProduct = () => {
    setItems((prev) => [...prev, INIT_ITEM]);
    setItemsError((prev) => [...prev, INIT_ITEM]);
  };

  const handleRemoveProduct = (index) => {
    const itemsTmp = [...items];
    itemsTmp.splice(index, 1);
    const itemsErrorTmp = [...itemsError];
    itemsErrorTmp.splice(index, 1);
    setItems(itemsTmp);
    setItemsError(itemsErrorTmp);
  };

  const handleReset = () => {
    setItems(
      order.items.map((item) => {
        const exceptionRate = exceptionRates.find(
          (er) => er.productId === item.productId
        );
        return {
          ...item,
          pricePerBox:
            item.config?.pricePerBox ??
            ((exceptionRate?.price ?? item?.price ?? 0) *
              (item?.productPerBox ?? 0)),
        };
      })
    );
    setItemsError(order.items.map(() => INIT_ITEM));
  };

  const getValueByOrder = (...values) => {
    for (const value of values) {
      if (!EMPTY_VALUES.includes(value)) return value;
    }
    return 0;
  };

  const convertItems = (items, rates, exceptionRates, shippingMethod) =>
    items.map((item) => {
      const {
        discountType,
        sizeType,
        quantity,
        productId,
        pricePerBox,
        config = {},
      } = item;

      if (!productId || !validateRequired(quantity))
        return {
          ...item,
          discount: "",
          freightCollect: "",
          total: "",
        };

      const exceptionRate = exceptionRates.find(
        (er) => er.productId === item.productId
      );
      const discountRate = rates.find((rate) => rate.type === discountType);
      const sizeRate = rates.find((rate) => rate.type === sizeType);

      const newConfig = {
        discountRate: getValueByOrder(
          config.discountRate,
          exceptionRate?.discountRate,
          discountRate?.discountRate
        ),
        freightCollect: getValueByOrder(
          config.freightCollect,
          exceptionRate?.freightCollect,
          sizeRate?.freightCollect
        ),
      };

      const discount = Math.round(
        (pricePerBox * quantity * newConfig.discountRate) / 100
      );
      const freightCollect =
        shippingMethod === SHIPPING_METHOD.SEND
          ? newConfig.freightCollect * quantity
          : 0;

      return {
        ...item,
        discount,
        freightCollect,
        total: pricePerBox * quantity - discount + freightCollect,
      };
    });

  const getDistributor = async (distributorId) => {
    try {
      const { result = {} } = await apis.user.getUser(distributorId);
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const getWallet = async (distributorId) => {
    try {
      const { result = {} } = await apis.wallet.getWallet(distributorId);
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const getProducts = async () => {
    try {
      const { result = [] } = await apis.product.getProducts();
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const getRates = async (distributorId) => {
    try {
      const { result = [] } = await apis.rate.getRates(distributorId);
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const getExceptionRates = async (distributorId) => {
    try {
      const { result = [] } = await apis.exceptionRate.getExceptionRates(
        distributorId
      );
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const handleInit = async () => {
    try {
      const { result } = await apis.distributorOrder.getDistributorOrder(
        distributorOrderId
      );

      setShipper(result.shipper || "");
      setShippingMethod(result.shippingMethod || "");

      const [d, w, ps, rs, ers] = await Promise.all([
        getDistributor(result.distributorId),
        getWallet(result.distributorId),
        getProducts(),
        getRates(result.distributorId),
        getExceptionRates(result.distributorId),
      ]);

      if (!d || !w || !ps || !rs || !ers) throw new Error("somethingWentWrong");

      setItems(
        result.items.length
          ? result.items.map((item) => {
              const exceptionRate = ers.find(
                (er) => er.productId === item.productId
              );
              return {
                ...item,
                pricePerBox:
                  item.config?.pricePerBox ||
                  (exceptionRate?.price || item?.price || 0) *
                    (item?.productPerBox || 0),
              };
            })
          : [INIT_ITEM]
      );
      setItemsError(result.items.map(() => INIT_ITEM));

      setDistributor(d);
      setWallet(w);
      setProducts(ps);
      setRates(rs);
      setExceptionRates(ers);
      setBreadcrumbs((prev) => [
        prev[0],
        { title: `${d.metadata?.name || d.name} - ${d.phoneNumber}` },
      ]);
      setOrder(result);
    } catch (error) {
      setError(true);
      setBreadcrumbs([
        { title: t("back"), path: prevPath || ROUTE.DISTRIBUTOR_ORDER },
      ]);
      toast.error(t(error.message));
    }
  };

  const init = async () => {
    setLoading(true);
    await handleInit();
    setLoading(false);
  };

  const convertedItems = useMemo(
    () => convertItems(items, rates, exceptionRates, shippingMethod),
    [items, rates, exceptionRates, shippingMethod]
  );

  const {
    totalProductPrice,
    totalDiscount,
    totalFreightCollect,
    totalPrice,
    totalBoxes,
  } = useMemo(
    () =>
      convertedItems.reduce(
        (acc, item) => {
          const {
            pricePerBox = 0,
            quantity = 0,
            discount = 0,
            freightCollect = 0,
            total = 0,
          } = item;
          acc.totalProductPrice += +pricePerBox * +quantity;
          acc.totalDiscount += +discount;
          acc.totalFreightCollect += +freightCollect;
          acc.totalPrice += +total;
          acc.totalBoxes += +quantity;
          return acc;
        },
        {
          totalProductPrice: 0,
          totalDiscount: 0,
          totalFreightCollect: 0,
          totalPrice: 0,
          totalBoxes: 0,
        }
      ),
    [convertedItems]
  );

  const hasPermission = (method, path) =>
    role.isMasterRole ||
    !!role.apiPermissions.find(
      (permission) => permission.method === method && permission.path === path
    );

  const isDisplayWallet =
    hasPermission("PUT", "/api/v1/distributor-orders/:id/admin-update") &&
    [
      DISTRIBUTOR_ORDER_STATUS.AWAITING_ADMIN_CONFIRMATION,
      DISTRIBUTOR_ORDER_STATUS.AWAITING_PAYMENT,
    ].includes(order.status);

  const walletPreview = useMemo(() => {
    const preview = {
      commodityCompensationWalletPayOut: 0,
      freightCompensationWalletPayOut: 0,
      bonusWalletPayOut: 0,
      paymentWalletPayOut: 0,
      creditPayOut: 0,
      missingAmount: 0,
    };

    if (!isDisplayWallet) {
      return preview;
    }

    const {
      commodityCompensationBalance,
      freightCompensationBalance,
      bonusBalance,
      paymentBalance,
    } = wallet;
    let remainingAmount = totalPrice;
    if (commodityCompensationBalance > remainingAmount) {
      preview.commodityCompensationWalletPayOut = remainingAmount;
      return preview;
    }
    if (commodityCompensationBalance > 0) {
      remainingAmount -= commodityCompensationBalance;
      preview.commodityCompensationWalletPayOut = commodityCompensationBalance;
    }

    if (freightCompensationBalance > remainingAmount) {
      preview.freightCompensationWalletPayOut = remainingAmount;
      return preview;
    }
    if (freightCompensationBalance > 0) {
      remainingAmount -= freightCompensationBalance;
      preview.freightCompensationWalletPayOut = freightCompensationBalance;
    }

    if (bonusBalance > remainingAmount) {
      preview.bonusWalletPayOut = remainingAmount;
      return preview;
    }
    if (bonusBalance > 0) {
      remainingAmount -= bonusBalance;
      preview.bonusWalletPayOut = bonusBalance;
    }

    if (paymentBalance > remainingAmount) {
      preview.paymentWalletPayOut = remainingAmount;
      return preview;
    }

    if (!distributor?.creditLimit) {
      if (paymentBalance > 0) {
        preview.paymentWalletPayOut = paymentBalance;
        preview.missingAmount = remainingAmount - paymentBalance;
      } else {
        preview.paymentWalletPayOut = 0;
        preview.missingAmount = remainingAmount;
      }

      return preview;
    }

    preview.paymentWalletPayOut = remainingAmount;

    if (distributor.creditLimit + paymentBalance >= remainingAmount) {
      preview.creditPayOut =
        remainingAmount - (paymentBalance >= 0 ? paymentBalance : 0);
      return preview;
    }

    preview.creditPayOut =
      distributor.creditLimit + (paymentBalance >= 0 ? 0 : paymentBalance);

    preview.missingAmount =
      remainingAmount - (distributor.creditLimit + paymentBalance);

    return preview;
  }, [wallet, totalPrice, isDisplayWallet, distributor]);

  const isOrderChange = useMemo(() => {
    if (
      order.shippingMethod !== shippingMethod ||
      order.items?.length !==
        items.filter(
          (item) =>
            ![item.productId, item.quantity].every(
              (value) => !validateRequired(value)
            )
        ).length
    )
      return true;
    return !order.items.every((orderItem) => {
      const item = items.find((i) => i.productId === orderItem.productId);
      if (item && String(item.quantity) === String(orderItem.quantity))
        return true;
      return false;
    });
  }, [order, items, shippingMethod]);

  const validate = () =>
    validateShippingMethod() &&
    items.filter(
      (item) =>
        ![item.productId, item.quantity].every(
          (value) => !validateRequired(value)
        )
    ).length &&
    items.every(
      (item, index) =>
        Object.values(item).every((value) => !validateRequired(value)) ||
        (validateItem(null, index + ".productId") &&
          validateItem(null, index + ".quantity") &&
          validateItem(null, index + ".pricePerBox"))
    );

  const handleApproveDistributorOrder = async () => {
    if (!validate()) {
      toast.error(t("dataInvalid"));
      return;
    }
    setApproving(true);
    try {
      await apis.distributorOrder.adminUpdateDistributorOrder(
        distributorOrderId,
        {
          shippingMethod,
          items: items.filter(
            (item) =>
              ![item.productId, item.quantity].every(
                (value) => !validateRequired(value)
              )
          ),
          isOrderChange,
        }
      );
      await handleInit();
      toast.success(t("approveOrderSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setApproving(false);
  };

  const handlePayDistributorOrder = async () => {
    setApproving(true);
    try {
      await apis.distributorOrder.payDistributorOrder(distributorOrderId);
      await handleInit();
      toast.success(t("paymentOrderSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setApproving(false);
  };

  const handleRequestUpdateDistributorOrder = async () => {
    setRequesting(true);
    try {
      await apis.distributorOrder.requestUpdateDistributorOrder(
        distributorOrderId
      );
      await handleInit();
    } catch (error) {
      toast.error(t(error.message));
    }
    setRequesting(false);
  };

  const handleConfirmShippingOrder = async () => {
    setApproving(true);
    try {
      await apis.distributorOrder.confirmShippingOrder(distributorOrderId, {
        shipper,
      });
      await handleInit();
      toast.success(t("confirmShippingSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setApproving(false);
  };

  const handleUpdateDistributorOrder = async () => {
    if (!validate()) {
      toast.error(t("dataInvalid"));
      return;
    }
    setUpdating(true);
    try {
      await apis.distributorOrder.warehouseUpdateDistributorOrder(
        distributorOrderId,
        {
          shippingMethod,
          shipper,
          items: items.filter(
            (item) =>
              ![item.productId, item.quantity].every(
                (value) => !validateRequired(value)
              )
          ),
        }
      );
      await handleInit();
      toast.success(t("updateOrderSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setUpdating(false);
  };

  const handleCancelDistributorOrder = async () => {
    setCanceling(true);
    try {
      await apis.distributorOrder.cancelOrder(distributorOrderId);
      await handleInit();
      toast.success(t("cancelOrderSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setCanceling(false);
  };

  const handleReReviewDistributorOrder = async () => {
    setBacking(true);
    try {
      await apis.distributorOrder.adminReReviewOrder(distributorOrderId);
      await handleInit();
      toast.success(t("saleAdminReReviewSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setBacking(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `hoa_don_don_hang_${order.distributorOrderId}`,
    pageStyle: `
      @page {
        margin: 20mm 15mm 20mm 15mm !important; 
      }
    `,
  });

  useEffect(() => {
    init();
  }, [distributorOrderId]);

  const isEditable =
    (hasPermission("PUT", "/api/v1/distributor-orders/:id/admin-update") &&
      order.status === DISTRIBUTOR_ORDER_STATUS.AWAITING_ADMIN_CONFIRMATION) ||
    (hasPermission("PUT", "/api/v1/distributor-orders/:id/warehouse-update") &&
      order.status === DISTRIBUTOR_ORDER_STATUS.AWAITING_SHIPPING);

  const isPriceEditable =
    hasPermission("PUT", "/api/v1/distributor-orders/:id/admin-update") &&
    order.status === DISTRIBUTOR_ORDER_STATUS.AWAITING_ADMIN_CONFIRMATION;

  const isDisplayShipperInfo = [
    DISTRIBUTOR_ORDER_STATUS.AWAITING_SHIPPING,
    DISTRIBUTOR_ORDER_STATUS.SHIPPING,
    DISTRIBUTOR_ORDER_STATUS.COMPLETED,
  ].includes(order.status);

  const isEditableShipperInfo =
    order.status === DISTRIBUTOR_ORDER_STATUS.AWAITING_SHIPPING;

  const renderAction = () => {
    if (
      hasPermission("PUT", "/api/v1/distributor-orders/:id/admin-update") &&
      order.status === DISTRIBUTOR_ORDER_STATUS.AWAITING_ADMIN_CONFIRMATION
    )
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleReset}
            startIcon={<Autorenew />}
          >
            {t("refresh")}
          </Button>
          <LoadingButton
            variant="contained"
            color="success"
            onClick={handleApproveDistributorOrder}
            startIcon={<CheckOutlined />}
            loading={approving}
          >
            {t("approveOrder")}
          </LoadingButton>
        </>
      );

    if (
      hasPermission("PUT", "/api/v1/distributor-orders/:id/confirm-payment") &&
      order.status === DISTRIBUTOR_ORDER_STATUS.AWAITING_PAYMENT
    )
      return (
        <>
          <LoadingButton
            variant="outlined"
            color="warning"
            onClick={handleRequestUpdateDistributorOrder}
            startIcon={<Edit />}
            loading={requesting}
          >
            {t("update")}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="success"
            onClick={handlePayDistributorOrder}
            startIcon={<CheckOutlined />}
            loading={approving}
            disabled={walletPreview.missingAmount > 0}
          >
            {t("confirmPayment")}
          </LoadingButton>
        </>
      );

    if (
      hasPermission("PUT", "/api/v1/distributor-orders/:id/warehouse-update") &&
      order.status === DISTRIBUTOR_ORDER_STATUS.AWAITING_SHIPPING
    )
      return (
        <>
          <Button
            variant="outlined"
            color="error"
            onClick={handleReset}
            startIcon={<Autorenew />}
          >
            {t("refresh")}
          </Button>
          <LoadingButton
            variant="outlined"
            color="primary"
            onClick={handleUpdateDistributorOrder}
            startIcon={<SaveOutlined />}
            loading={updating}
            disabled={!isOrderChange}
          >
            {t("save")}
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            color="warning"
            onClick={handleReReviewDistributorOrder}
            startIcon={<SettingsBackupRestore />}
            loading={backing}
            disabled={isOrderChange}
          >
            {t("saleAdminReReview")}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="success"
            onClick={handleConfirmShippingOrder}
            startIcon={<CheckOutlined />}
            loading={approving}
            disabled={isOrderChange}
          >
            {t("confirmShipping")}
          </LoadingButton>
        </>
      );
  };

  const renderContent = () => (
    <>
      <DistributorInfo
        distributor={distributor}
        wallet={wallet}
        isDisplayWallet={isDisplayWallet}
      />

      <Grid container spacing={3} mt={3}>
        <Grid item sm={12} md={8}>
          <MainCard title={t("shippingInfo")} sx={{ height: "225px" }}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={5}>
                <Typography>
                  {t("shippingMethod")}{" "}
                  <span style={{ color: COLOR.error.base }}>*</span>:
                </Typography>
                <FormControl error={!!shippingMethodError}>
                  <RadioGroup
                    value={shippingMethod}
                    onChange={handleChangeShippingMethod}
                  >
                    {Object.values(SHIPPING_METHOD).map((value) => (
                      <FormControlLabel
                        value={value}
                        control={<Radio />}
                        label={t(SHIPPING_METHOD_LABEL[value])}
                        key={value}
                        disabled={!isEditable}
                      />
                    ))}
                  </RadioGroup>
                  <FormHelperText>{shippingMethodError}</FormHelperText>
                </FormControl>
              </Grid>
              {isDisplayShipperInfo && (
                <Grid item sm={12} md={7}>
                  <Typography mb={1}>{t("shipper")}:</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    value={shipper}
                    onChange={(e) => {
                      setShipper(e.target.value);
                      setShipperError("");
                    }}
                    error={!!shipperError}
                    helperText={shipperError}
                    disabled={!isEditableShipperInfo}
                  />
                </Grid>
              )}
            </Grid>
          </MainCard>
        </Grid>
        <Grid item sm={12} md={4}>
          <MainCard title={t("orderStatus")} sx={{ height: "225px" }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              mb={2}
            >
              <Typography fontWeight={500}>{t("status")}:</Typography>
              <DistributorOrderStatus status={order.status} />
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              mb={2}
            >
              <Typography fontWeight={500}>{t("category")}:</Typography>
              <Typography fontWeight={500}>
                {order.category?.name || "-"}
              </Typography>
            </Stack>
          </MainCard>
        </Grid>
      </Grid>
      <MainCard title={t("orderDetail")} sx={{ marginY: 3 }}>
        <StyledTable>
          <Table className="table">
            <TableHead className="table-header">
              <TableRow>
                <TableCell className="header-cell" align="center">
                  {t("no")}
                </TableCell>
                <TableCell className="header-cell cell-product" align="center">
                  {t("product")}
                </TableCell>
                <TableCell className="header-cell cell-unit" align="center">
                  {t("unit")}
                </TableCell>
                <TableCell className="header-cell" align="center">
                  {t("quantity")}
                </TableCell>
                <TableCell className="header-cell" align="center">
                  {t("price")}
                </TableCell>
                <TableCell className="header-cell" align="center">
                  {t("discount")}
                </TableCell>
                <TableCell className="header-cell" align="center">
                  {t("freightCollect")}
                </TableCell>
                <TableCell className="header-cell" align="center">
                  {t("total")}
                </TableCell>
                <TableCell className="header-cell cell-note" align="center">
                  {t("note")}
                </TableCell>
                <TableCell className="header-cell" align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {convertedItems.map((item, index, currItems) => (
                <TableRow
                  key={index}
                  className="body-row"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="body-cell cell-no" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell className="body-cell cell-product">
                    <Autocomplete
                      size="small"
                      value={item.productId}
                      options={products
                        .filter(
                          (product) =>
                            !currItems
                              .map(({ productId }) => productId)
                              .includes(product.id) &&
                            product.active &&
                            (product.productCategory?.id ===
                              order.category?.categoryId ||
                              product.productCategory?.noReport)
                        )
                        .map((product) => product.id)
                        .concat(item.productId ? [item.productId] : [])}
                      getOptionLabel={(option) => renderProductOptions(option)}
                      filterSelectedOptions
                      onChange={(event, newValue) =>
                        handleChange({
                          target: {
                            name: index + ".productId",
                            value: newValue,
                          },
                        })
                      }
                      onBlur={() => validateItem(null, index + ".productId")}
                      renderOption={(props, key) => (
                        <MenuItem value={key} {...props}>
                          {renderProductOptions(key)}
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={!!itemsError[index]?.productId}
                          helperText={itemsError[index]?.productId}
                          fullWidth
                        />
                      )}
                      disabled={!isEditable}
                    />
                  </TableCell>
                  <TableCell className="body-cell cell-unit" align="center">
                    {t("box")}
                  </TableCell>
                  <TableCell className="body-cell cell-rate">
                    <CurrencyInput
                      customInput={Input}
                      allowDecimals={false}
                      allowNegativeValue={false}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={item.quantity}
                      variant="standard"
                      disableUnderline={!itemsError[index]?.quantity}
                      error={!!itemsError[index]?.quantity}
                      name={`${index}.quantity`}
                      fullWidth
                      onChange={handleChange}
                      onBlur={validateItem}
                      disabled={!isEditable}
                    />
                  </TableCell>
                  <TableCell className="body-cell cell-rate">
                    <CurrencyInput
                      customInput={Input}
                      allowDecimals={false}
                      allowNegativeValue={false}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={item.pricePerBox}
                      variant="standard"
                      disableUnderline={!itemsError[index]?.pricePerBox}
                      error={!!itemsError[index]?.pricePerBox}
                      name={index + ".pricePerBox"}
                      fullWidth
                      onChange={handleChange}
                      onBlur={validateItem}
                      disabled={!isPriceEditable}
                      endAdornment={
                        <InputAdornment position="end">&#8363;</InputAdornment>
                      }
                    />
                  </TableCell>
                  <TableCell className="body-cell cell-rate">
                    <CurrencyInput
                      customInput={Input}
                      allowDecimals={false}
                      allowNegativeValue={false}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={item.discount}
                      variant="standard"
                      disableUnderline={true}
                      fullWidth
                      disabled={true}
                      endAdornment={
                        <InputAdornment position="end">&#8363;</InputAdornment>
                      }
                    />
                  </TableCell>
                  <TableCell className="body-cell cell-rate">
                    <CurrencyInput
                      customInput={Input}
                      allowDecimals={false}
                      allowNegativeValue={false}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={item.freightCollect}
                      variant="standard"
                      disableUnderline={true}
                      fullWidth
                      disabled={true}
                      endAdornment={
                        <InputAdornment position="end">&#8363;</InputAdornment>
                      }
                    />
                  </TableCell>
                  <TableCell className="body-cell cell-rate">
                    <CurrencyInput
                      customInput={Input}
                      allowDecimals={false}
                      allowNegativeValue={false}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={item.total}
                      variant="standard"
                      disableUnderline={true}
                      fullWidth
                      disabled={true}
                      endAdornment={
                        <InputAdornment position="end">&#8363;</InputAdornment>
                      }
                    />
                  </TableCell>
                  <TableCell className="body-cell cell-note">
                    <Input
                      value={item.note || ""}
                      variant="standard"
                      disableUnderline={true}
                      name={index + ".note"}
                      fullWidth
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell className="body-cell cell-action" align="center">
                    <Tooltip title={t("delete")}>
                      <span>
                        <IconButton
                          onClick={() => handleRemoveProduct(index)}
                          color="error"
                          disabled={!isEditable}
                        >
                          <DeleteOutlineOutlined />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTable>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          mt={2.5}
          spacing={3}
        >
          <Button
            variant="outlined"
            onClick={handleAddProduct}
            startIcon={<Add />}
            disabled={!isEditable}
          >
            {t("addProduct")}
          </Button>
        </Stack>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} sm={4} md={6} lg={8}></Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Typography fontSize="18px">{t("totalBoxes")}</Typography>
              <Typography fontWeight={500} fontSize="18px">
                {formatNumber(totalBoxes)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Typography fontSize="18px">{t("totalProductPrice")}</Typography>
              <Typography fontWeight={500} fontSize="18px">
                {formatNumber(totalProductPrice)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Typography fontSize="18px">{t("totalDiscount")}</Typography>
              <Typography fontWeight={500} fontSize="18px">
                {formatNumber(totalDiscount)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Typography fontSize="18px">
                {t("totalFreightCollect")}
              </Typography>
              <Typography fontWeight={500} fontSize="18px">
                {formatNumber(totalFreightCollect)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
              mb={2}
            >
              <Typography color="error" fontWeight={700} fontSize="20px">
                {t("totalPrice")}
              </Typography>
              <Typography color="error" fontWeight={700} fontSize="20px">
                {formatNumber(totalPrice)}
              </Typography>
            </Stack>

            {isDisplayWallet && (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Typography fontSize="18px">
                    {t("subCommodityCompensationWallet")}
                  </Typography>
                  <Typography fontWeight={500} fontSize="18px">
                    {formatNumber(
                      walletPreview.commodityCompensationWalletPayOut
                    )}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Typography fontSize="18px">
                    {t("subFreightCompensationWallet")}
                  </Typography>
                  <Typography fontWeight={500} fontSize="18px">
                    {formatNumber(
                      walletPreview.freightCompensationWalletPayOut
                    )}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Typography fontSize="18px">{t("subBonusWallet")}</Typography>
                  <Typography fontWeight={500} fontSize="18px">
                    {formatNumber(walletPreview.bonusWalletPayOut)}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Typography color="error" fontWeight={700} fontSize="20px">
                    {t("subPaymentWallet")}
                  </Typography>
                  <Typography color="error" fontWeight={700} fontSize="20px">
                    {formatNumber(walletPreview.paymentWalletPayOut)}
                  </Typography>
                </Stack>
                {distributor.creditLimit && walletPreview.creditPayOut > 0 && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={3}
                  >
                    <Typography
                      fontStyle="italic"
                      color="error"
                      fontSize="16px"
                    >
                      ({t("subCredit")}
                    </Typography>
                    <Typography
                      fontStyle="italic"
                      color="error"
                      fontWeight={500}
                      fontSize="16px"
                    >
                      {formatNumber(walletPreview.creditPayOut)})
                    </Typography>
                  </Stack>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {order.status === DISTRIBUTOR_ORDER_STATUS.AWAITING_PAYMENT &&
          walletPreview.missingAmount > 0 && (
            <Box mt={3}>
              <Typography color="error" fontSize="18px" textAlign="center">
                {t("notEnoughMoneyNotify")}
              </Typography>
              <Typography fontStyle="italic" textAlign="center">
                {t("notEnoughMoneyDesc", {
                  missingAmount: formatNumber(walletPreview.missingAmount),
                })}
              </Typography>
            </Box>
          )}
      </MainCard>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={2.5}
        spacing={3}
        mb={3}
      >
      <Button
        variant="outlined"
        onClick={() => history.push({
          pathname: prevPath || ROUTE.DISTRIBUTOR_ORDER,
          search: location.search, // Use location from useLocation hook
        })}
        startIcon={<ArrowBackIos />}
      >
        {t("back")}
      </Button>
        {renderAction()}
        {[
          DISTRIBUTOR_ORDER_STATUS.AWAITING_SHIPPING,
          DISTRIBUTOR_ORDER_STATUS.SHIPPING,
          DISTRIBUTOR_ORDER_STATUS.COMPLETED,
        ].includes(order.status) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handlePrint}
            startIcon={<LocalPrintshopOutlined />}
            disabled={!items.length}
          >
            {t("printOrder")}
          </Button>
        )}
        {![
          DISTRIBUTOR_ORDER_STATUS.COMPLETED,
          DISTRIBUTOR_ORDER_STATUS.CANCEL,
        ].includes(order.status) && (
          <LoadingButton
            variant="contained"
            color="error"
            onClick={handleCancelDistributorOrder}
            startIcon={<Clear />}
            loading={canceling}
          >
            {t("cancelOrder")}
          </LoadingButton>
        )}
      </Stack>
      <div style={{ display: "none" }}>
        <Invoice
          ref={componentRef}
          order={{ ...order, items: items }}
          distributor={distributor}
        />
      </div>
    </>
  );

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Box padding={2}>
          <Typography variant="body1" textAlign="center">
            {t("distributorOrderNotfound")} !
          </Typography>
        </Box>
      ) : (
        renderContent()
      )}
    </>
  );
};

export default DistributorOrderDetail;
