import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apis from "../../apis";
import Dialog from "../../components/Dialog";
import DateInput from "../../components/Input/DateInput";
import NumberInput from "../../components/Input/NumberInput";
import { DIALOG_TYPE } from "../../constants";
import {
  APPLIED_OBJECT_MAPPING,
  APPLIED_OBJECT_TYPE,
  DISCOUNT_TYPE,
  DISCOUNT_TYPE_MAPPING,
  VALUE_DISCOUNT_TYPE_MAPPING,
} from "../../constants/discount";
import { COLOR } from "../../styles/color";

const INIT_DISCOUNT_DATA = {
  name: "",
  conditionType: "",
  conditionAmount: 0,
  conditionProduct: null,
  valueType: "",
  valueAmount: 0,
  valueProduct: null,
  appliedObjectType: "",
  appliedObjectValue: [],
  startDate: "",
  endDate: "",
};

const INIT_DISCOUNT_DATA_ERROR = {
  name: "",
  conditionType: "",
  conditionAmount: "",
  conditionProduct: "",
  valueType: "",
  valueAmount: "",
  valueProduct: "",
  appliedObjectType: "",
  appliedObjectValue: "",
  startDate: "",
  endDate: "",
};

const convertToDiscountFormat = (discountData) => {
  return {
    name: discountData.name,
    condition: {
      type: discountData.conditionType,
      amount: discountData.conditionAmount,
      product: discountData.conditionProduct,
    },
    value: {
      type: discountData.valueType,
      amount: discountData.valueAmount,
      product: discountData.valueProduct,
    },
    appliedObject: {
      type: discountData.appliedObjectType,
      value: discountData.appliedObjectValue?.map((item) => ({
        id: item.id,
        name: item.name,
      })),
    },
    startDate: discountData.startDate || new Date(),
    endDate: discountData.endDate || new Date(),
  };
};

const InputTitle = ({
  label,
  isRequired,
  fontSize,
  fontWeight = 500,
  mb = 1,
}) => (
  <Typography fontWeight={fontWeight} mb={mb} fontSize={fontSize}>
    {label}
    {isRequired && <span style={{ color: COLOR.error.base }}>*</span>}:
  </Typography>
);

const HelperText = ({ text }) => {
  if (!text) return null;
  return (
    <Typography color="error" fontSize={12}>
      {text}
    </Typography>
  );
};

const DiscountDialog = ({
  open,
  onClose,
  initDiscount,
  dialogType,
  handleReload,
  products,
  provinces = [],
  regions = [],
  distributors = [],
  stores = [],
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [discountData, setDiscountData] = useState(INIT_DISCOUNT_DATA);
  const [error, setError] = useState(INIT_DISCOUNT_DATA_ERROR);

  const isCreateDialog = dialogType === DIALOG_TYPE.CREATE;
  const appliedOptions = {
    [APPLIED_OBJECT_TYPE.REGION]: regions,
    [APPLIED_OBJECT_TYPE.PROVINCE]: provinces,
    [APPLIED_OBJECT_TYPE.DISTRIBUTOR]: distributors,
    [APPLIED_OBJECT_TYPE.STORE]: stores,
  };

  const handleChangeDiscountData = (value, field) => {
    setError((prev) => ({ ...prev, [field]: "" }));
    if (field === "appliedObjectType")
      setDiscountData((prev) => ({
        ...prev,
        appliedObjectType: value,
        appliedObjectValue: [],
      }));
    else setDiscountData((prev) => ({ ...prev, [field]: value }));
  };

  const validateDiscountData = () => {
    const newError = { ...INIT_DISCOUNT_DATA_ERROR };
    let isValid = true;
    Object.keys(discountData).forEach((key) => {
      if (!discountData[key] || discountData[key]?.length === 0) {
        if (
          key === "conditionProduct" &&
          discountData.conditionType === DISCOUNT_TYPE.MONEY
        )
          return;
        if (
          key === "valueProduct" &&
          discountData.valueType === DISCOUNT_TYPE.MONEY
        )
          return;
        newError[key] = t("requiredField");
        isValid = false;
      }
    });
    const { startDate, endDate } = discountData;
    const startDateObj = startDate ? new Date(startDate) : new Date();
    const endDateObj = endDate ? new Date(endDate) : new Date();
    if (startDateObj >= endDateObj) {
      newError.startDate = t("startDateMustBeBeforeEndDate");
      isValid = false;
    }
    setError(newError);
    return isValid;
  };

  const handleCloseDialog = () => {
    setDiscountData(INIT_DISCOUNT_DATA);
    setError(INIT_DISCOUNT_DATA_ERROR);
    onClose();
  };

  const handleCreateDiscount = async () => {
    const isValid = validateDiscountData();
    if (!isValid) return;
    setLoading(true);
    try {
      // Call API to create discount
      const discount = convertToDiscountFormat(discountData);
      await apis.discount.createDiscount({
        ...discount,
        code: `DISCOUNT_${Date.now()}`,
      });
      toast.success(t("createDiscountSuccess"));
      handleCloseDialog();
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdateDiscount = async () => {
    const isValid = validateDiscountData();
    if (!isValid) return;
    setLoading(true);
    try {
      // Call API to update discount
      const discount = convertToDiscountFormat(discountData);
      await apis.discount.updateDiscount(initDiscount.id, discount);
      toast.success(t("updateDiscountSuccess"));
      handleCloseDialog();
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isCreateDialog && initDiscount) {
      setDiscountData({
        name: initDiscount.name,
        conditionType: initDiscount.condition?.type,
        conditionAmount: initDiscount.condition?.amount,
        conditionProduct: initDiscount.condition?.product,
        valueType: initDiscount.value?.type,
        valueAmount: initDiscount.value?.amount,
        valueProduct: initDiscount.value?.product,
        appliedObjectType: initDiscount.appliedObject?.type,
        appliedObjectValue: initDiscount.appliedObject?.value,
        startDate: initDiscount.startDate,
        endDate: initDiscount.endDate,
      });
    }
  }, [initDiscount, dialogType]);

  return (
    <Dialog
      title={
        dialogType === DIALOG_TYPE.CREATE
          ? t("createDiscount")
          : t("editDiscount")
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Grid container spacing={2} mb={0}>
          <Grid item xs={12}>
            <InputTitle label={t("discountName")} isRequired />
            <TextField
              error={!!error.name}
              helperText={error.name}
              value={discountData.name}
              size="small"
              fullWidth
              onChange={(e) => handleChangeDiscountData(e.target.value, "name")}
              placeholder={t("discountName")}
            />
          </Grid>
          <Grid item xs={12}>
            <InputTitle label={t("discountCondition")} isRequired />
            <Autocomplete
              size="small"
              filterSelectedOptions
              value={discountData.conditionType}
              options={Object.values(DISCOUNT_TYPE)}
              getOptionLabel={(option) => DISCOUNT_TYPE_MAPPING[option] || ""}
              onChange={(e, newValue) =>
                handleChangeDiscountData(newValue, "conditionType")
              }
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <HelperText text={error.conditionType} />
          </Grid>
          {discountData.conditionType && (
            <Grid
              item
              xs={12}
              sm={discountData.conditionType === DISCOUNT_TYPE.PRODUCT ? 6 : 12}
            >
              <InputTitle
                label={`${t("conditionAmount")}${
                  discountData.conditionType === DISCOUNT_TYPE.PRODUCT
                    ? ""
                    : " (vnđ)"
                }`}
                fontSize={14}
                fontWeight={400}
                isRequired
              />
              <NumberInput
                value={discountData.conditionAmount}
                onChangeValue={(value) =>
                  handleChangeDiscountData(value, "conditionAmount")
                }
                minValue={1}
                sx={{ width: "100%" }}
              />
              <HelperText text={error.conditionAmount} />
            </Grid>
          )}
          {discountData.conditionType === DISCOUNT_TYPE.PRODUCT && (
            <Grid item xs={12} sm={6}>
              <InputTitle
                label={t("product")}
                isRequired
                fontSize={14}
                fontWeight={400}
              />
              <Autocomplete
                size="small"
                filterSelectedOptions
                value={discountData.conditionProduct}
                options={products}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleChangeDiscountData(newValue, "conditionProduct")
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <HelperText text={error.conditionProduct} />
            </Grid>
          )}

          <Grid item xs={12}>
            <InputTitle label={t("discountValue")} isRequired />
            <Autocomplete
              size="small"
              filterSelectedOptions
              value={discountData.valueType}
              options={Object.values(DISCOUNT_TYPE)}
              getOptionLabel={(option) =>
                VALUE_DISCOUNT_TYPE_MAPPING[option] || ""
              }
              onChange={(e, newValue) =>
                handleChangeDiscountData(newValue, "valueType")
              }
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <HelperText text={error.valueType} />
          </Grid>
          {discountData.valueType && (
            <Grid
              item
              xs={12}
              sm={discountData.valueType === DISCOUNT_TYPE.PRODUCT ? 6 : 12}
            >
              <InputTitle
                label={`${t("valueAmount")}${
                  discountData.valueType === DISCOUNT_TYPE.PRODUCT
                    ? ""
                    : " (vnđ)"
                }`}
                isRequired
                fontSize={14}
                fontWeight={400}
              />
              <NumberInput
                value={discountData.valueAmount}
                onChangeValue={(value) =>
                  handleChangeDiscountData(value, "valueAmount")
                }
                minValue={1}
                sx={{ width: "100%" }}
              />
              <HelperText text={error.valueAmount} />
            </Grid>
          )}
          {discountData.valueType === DISCOUNT_TYPE.PRODUCT && (
            <Grid item xs={12} sm={6} sx={{ padding: "0px" }}>
              <InputTitle
                label={t("product")}
                isRequired
                fontSize={14}
                fontWeight={400}
              />
              <Autocomplete
                size="small"
                filterSelectedOptions
                value={discountData.valueProduct}
                options={products}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleChangeDiscountData(newValue, "valueProduct")
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <HelperText text={error.valueProduct} />
            </Grid>
          )}

          <Grid item xs={12}>
            <InputTitle label={t("appliedObject")} isRequired />
            <Autocomplete
              size="small"
              filterSelectedOptions
              value={discountData.appliedObjectType}
              options={Object.values(APPLIED_OBJECT_TYPE)}
              getOptionLabel={(option) => APPLIED_OBJECT_MAPPING[option] || ""}
              onChange={(e, newValue) =>
                handleChangeDiscountData(newValue, "appliedObjectType")
              }
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <HelperText text={error.appliedObjectType} />
          </Grid>
          {discountData.appliedObjectType && (
            <Box
              sx={{ paddingLeft: "16px", paddingTop: "16px", width: "100%" }}
            >
              <Autocomplete
                multiple
                size="small"
                filterSelectedOptions
                value={discountData.appliedObjectValue}
                options={appliedOptions[discountData.appliedObjectType] || []}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleChangeDiscountData(newValue, "appliedObjectValue")
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <HelperText text={error.appliedObjectValue} />
            </Box>
          )}
          <Grid item xs={12} sm={6}>
            <InputTitle label={t("startDate")} isRequired />
            <DateInput
              name="startDate"
              value={discountData.startDate || new Date()}
              onChangeValue={(value) =>
                handleChangeDiscountData(value, "startDate")
              }
              inputFormat="DD/MM/YYYY HH:mm"
              minDate={new Date()}
            />
            <HelperText text={error.startDate} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputTitle label={t("endDate")} isRequired />
            <DateInput
              name="endDate"
              value={discountData.endDate || new Date()}
              onChangeValue={(value) =>
                handleChangeDiscountData(value, "endDate")
              }
              inputFormat="DD/MM/YYYY HH:mm"
              minDate={new Date()}
            />
            <HelperText text={error.endDate} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />

      {/** Dialog actions */}
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={isCreateDialog ? handleCreateDiscount : handleUpdateDiscount}
        >
          {isCreateDialog ? t("addNew") : t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountDialog;
