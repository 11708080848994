import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import dayjs from "dayjs";

import apis from "../../apis";
import PageTitle from "../../components/PageTitle";
import MainCard from "../../components/MainCard";
import { DIALOG_TYPE, INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import OaMessageDialog from "./OaMessageDialog";
import OaMessageList from "./OaMessageList";

const INIT_MESSAGE = {
  regions: [],
  provinces: [],
  distributorIds: [],
  templateId: null,
  sendMode: "",
  scheduledAt: null,
};

const OaMessage = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [message, setMessage] = useState(INIT_MESSAGE);
  const [reload, setReload] = useState(false);
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [templates, setTemplates] = useState([]);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const handleOpenDialog = (dt, currentMessage) => {
    setDialogType(dt);
    if (currentMessage) {
      const initMessage = {
        regions: currentMessage.regions,
        provinces: currentMessage.provinces,
        distributorIds: currentMessage.distributorIds,
        templateId: currentMessage.template.id,
        sendMode: currentMessage.sendMode,
        scheduledAt: currentMessage.scheduledAt
          ? dayjs(currentMessage.scheduledAt)
          : null,
        status: currentMessage.status,
        id: currentMessage.id,
      };

      setMessage(initMessage);
    }

    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMessage(INIT_MESSAGE);
    setDialogType("");
  };

  const fetchDistributors = async () => {
    try {
      const condition = {
        pageType: PAGE_TYPE.DISTRIBUTOR,
        sort: ["code_asc"],
        active: true,
        isStopCooperate: false,
        followOa: true,
      };

      const { result = [] } = await apis.user.getUsers(condition);
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchProvinces = async () => {
    try {
      const res = await apis.address.getVnUnits();
      setProvinces(res.result.map(({ region, name }) => ({ region, name })));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchRegions = async () => {
    try {
      const { result } = await apis.region.getRegions();
      setRegions(result.map((r) => r.name));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchTemplates = async () => {
    try {
      const { result } = await apis.oaMessageTemplate.getOaMessageTemplates();
      setTemplates(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchMessages = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
      };

      const { result } = await apis.oaMessage.getOaMessages(condition);
      setMessages(result.oaMessages);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { page = INITIAL_PAGING.page } = searchParams;
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchMessages({
      page: parseInt(page, 10),
    });
  }, [location.search, reload]);

  useEffect(() => {
    fetchDistributors();
    fetchProvinces();
    fetchRegions();
    fetchTemplates();
  }, []);

  return (
    <>
      <PageTitle title={t("oaMessage")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={8} md={6} lg={4}></Grid>
          <Grid item xs={12} sm={4} md={6} lg={8}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleOpenDialog(DIALOG_TYPE.CREATE)}
              >
                {t("addOaMessage")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <OaMessageList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          messages={messages}
          handleOpenDialog={handleOpenDialog}
        />
      </MainCard>
      <OaMessageDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogType={dialogType}
        initMessage={message}
        templates={templates}
        regions={regions}
        provinces={provinces}
        distributors={distributors}
        reload={() => setReload((prev) => !prev)}
      />
    </>
  );
};

export default OaMessage;
