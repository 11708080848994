import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const DEFAULT_IMAGE_URL = "/default-image.jpg";

const Input = styled("input")({
  display: "none",
});

const UploadFile = ({
  url,
  file,
  setFile,
  error,
  setError,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [previewImg, setPreviewImg] = useState(url || DEFAULT_IMAGE_URL);

  const handlePreview = (event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
      setError("");
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreviewImg(reader.result);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleDelete = () => {
    setFile(null);
    setPreviewImg(url || DEFAULT_IMAGE_URL);
  };

  return (
    <>
      <Box textAlign={"center"} mb={2}>
        <label htmlFor="contained-button-file">
          <Input
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={(event) => handlePreview(event)}
          />
          <Button
            fullWidth
            variant="outlined"
            component="span"
            color={error ? "error" : "primary"}
            disabled={disabled}
          >
            {t("chooseImage")}
          </Button>
        </label>
      </Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <Box>
          <img src={previewImg} height="150px" />
        </Box>
        {file && !disabled && (
          <Button
            variant="text"
            color="error"
            size="small"
            onClick={handleDelete}
          >
            {t("delete")}
          </Button>
        )}
      </Stack>
      {error && (
        <Typography color="error" fontSize="12px" margin="8px 14px 0">
          {error}
        </Typography>
      )}
    </>
  );
};

export default UploadFile;
