import { SERVICE } from "../constants";
import api from "./api";

const getGroupStatements = async (params) => {
  const response = await api({
    method: "GET",
    url: `/group-statements`,
    params,
    source: SERVICE.VELA_API,
  });

  return response;
};

const getGroupStatement = async (groupStatementId) => {
  const response = await api({
    method: "GET",
    url: `/group-statements/${groupStatementId}`,
    source: SERVICE.VELA_API,
  });

  return response;
};

export { getGroupStatements, getGroupStatement };
