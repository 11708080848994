import React from "react";
import DistributorOrderContainer from "../containers/DistributorOrder";
import { DISTRIBUTOR_ORDER_STATUS } from "../constants/order";
import ROUTE from "../constants/route";

const WarehouseOrder = () => {
  return (
    <DistributorOrderContainer
      validStatus={[
        DISTRIBUTOR_ORDER_STATUS.AWAITING_SHIPPING,
        DISTRIBUTOR_ORDER_STATUS.SHIPPING,
        DISTRIBUTOR_ORDER_STATUS.COMPLETED,
      ]}
      path={ROUTE.WAREHOUSE_ORDER}
    />
  );
};

export default WarehouseOrder;
