import {
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";

import { ALL, INITIAL_PAGING, PAGE_TYPE, STATUS } from "../../constants";
import apis from "../../apis";
import StoreList from "./StoreList";
import useSearchParams from "../../hooks/useSearchParams";
import { DownloadOutlined, Search } from "@mui/icons-material";
import debounce from "../../utils/debounce";
import { downloadFile } from "../../utils/download";
import StoreDialog from "./StoreDialog";
import {
  STORE_CHANNEL_TYPE,
  STORE_STATUS,
  STORE_TYPE,
  STORE_TYPE_LABEL,
} from "../../constants/store";
import Popup from "../../components/Popup";

const INIT_FILTER = {
  search: "",
  region: ALL,
  saleId: null,
  status: ALL,
  type: ALL,
  storeStatus: ALL,
  channelType: ALL,
};

const Store = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [distributors, setDistributors] = useState([]);
  const [filter, setFilter] = useState(INIT_FILTER);
  const [regions, setRegions] = useState([]);
  const [sales, setSales] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [reload, setReload] = useState(false);
  const [item, setItem] = useState({});
  const [provinces, setProvinces] = useState([]);
  const [products, setProducts] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  const { addParams } = useSearchParams();

  const renderUserOptions = (userId) => {
    const user = sales.find((item) => item.id === userId);
    return `${user?.name} - ${user?.phoneNumber}`;
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleChangeRegion = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, region: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      region: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleChangeSaleId = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, saleId: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      saleId: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleChangeFilterField = (field, value) => {
    setFilter((prev) => ({ ...prev, [field]: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      [field]: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, status: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      status: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const getStatusCondition = (status) =>
    parseInt(status, 10) === STATUS.ACTIVE ? true : false;

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const {
        search = INIT_FILTER.search,
        region = INIT_FILTER.region,
        saleId = INIT_FILTER.saleId,
        status = INIT_FILTER.status,
      } = searchParams;

      await downloadFile({
        url: "/stores/download",
        params: {
          search: search || undefined,
          region: region === ALL ? undefined : region,
          saleId: saleId === ALL ? undefined : saleId,
          isVerified: status === ALL ? undefined : getStatusCondition(status),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `stores_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }

    setDownloading(false);
  };

  const handleDelete = async () => {
    try {
      await apis.store.deleteStore(item.id);
      setReload((prev) => !prev);
      toast.success(t("deleteStoreSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchStores = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        search: newFilter.search || undefined,
        region: newFilter.region === ALL ? undefined : newFilter.region,
        saleId: newFilter.saleId === ALL ? undefined : newFilter.saleId,
        type: newFilter.type === ALL ? undefined : newFilter.type,
        channelType:
          newFilter.channelType === ALL ? undefined : newFilter.channelType,
        status:
          newFilter.storeStatus === ALL ? undefined : newFilter.storeStatus,
        isVerified:
          newFilter.status === ALL
            ? undefined
            : getStatusCondition(newFilter.status),
      };

      const { result } = await apis.store.getStores(condition);
      setStores(Array.isArray(result.stores) ? result.stores : []);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchDistributors = async () => {
    try {
      const condition = {
        pageType: PAGE_TYPE.DISTRIBUTOR,
        sort: ["code_asc"],
      };

      const { result } = await apis.user.getUsers(condition);
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchSales = async () => {
    try {
      const condition = {
        pageType: PAGE_TYPE.SALE,
      };

      const { result } = await apis.user.getUsers(condition);
      setSales(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchRegions = async () => {
    try {
      const { result } = await apis.region.getRegions();
      setRegions(result.map((r) => r.name));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchProvinces = async () => {
    try {
      const res = await apis.address.getVnUnits();
      const { result = [] } = res;
      setProvinces(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchProducts = async () => {
    try {
      const res = await apis.product.getProducts();
      const { result = [] } = res;
      setProducts(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      search = INIT_FILTER.search,
      region = INIT_FILTER.region,
      saleId = INIT_FILTER.saleId,
      status = INIT_FILTER.status,
      type = INIT_FILTER.type,
      channelType = INIT_FILTER.channelType,
      storeStatus = INIT_FILTER.storeStatus,
    } = searchParams;
    setFilter({
      search,
      region,
      saleId,
      status,
      type,
      channelType,
      storeStatus,
    });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchStores({
      search,
      region,
      saleId,
      status,
      page: parseInt(page, 10),
      type,
      channelType,
      storeStatus,
    });
  }, [location.search, reload]);

  useEffect(() => {
    fetchDistributors();
    fetchSales();
    fetchRegions();
    fetchProvinces();
    fetchProducts();
  }, []);

  return (
    <>
      <PageTitle title={t("store")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("storeSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} lg={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.status}
                onChange={handleChangeStatus}
                size="small"
                label={t("status")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                <MenuItem value={STATUS.ACTIVE}>{t("verified")}</MenuItem>
                <MenuItem value={STATUS.INACTIVE}>{t("unverified")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("region")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.region}
                onChange={handleChangeRegion}
                size="small"
                label={t("region")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {t("region")} {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={2} lg={3}>
            <Autocomplete
              size="small"
              value={filter.saleId}
              options={sales.map((asm) => asm.id)}
              getOptionLabel={(option) => renderUserOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) =>
                handleChangeSaleId({ target: { value: newValue } })
              }
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderUserOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder={t("sale")} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} lg={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("storeStatus")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.storeStatus}
                onChange={(e) =>
                  handleChangeFilterField("storeStatus", e.target.value)
                }
                size="small"
                label={t("storeStatus")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                <MenuItem value={STORE_STATUS.NOT_COLLABORATING}>
                  {t("not_collaborating")}
                </MenuItem>
                <MenuItem value={STORE_STATUS.COLLABORATING}>
                  {t("collaborating")}
                </MenuItem>
                <MenuItem value={STORE_STATUS.STOPPED}>
                  {t("stopped_collaborating")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4} md={2} lg={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("channelType")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.channelType}
                onChange={(e) =>
                  handleChangeFilterField("channelType", e.target.value)
                }
                size="small"
                label={t("channelType")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                <MenuItem value={STORE_CHANNEL_TYPE.GT}>{t("gt")}</MenuItem>
                <MenuItem value={STORE_CHANNEL_TYPE.MT}>{t("mt")}</MenuItem>
                <MenuItem value={STORE_CHANNEL_TYPE.SPECIAL}>
                  {t("special")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4} md={2} lg={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("storeType")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.type}
                onChange={(e) =>
                  handleChangeFilterField("type", e.target.value)
                }
                size="small"
                label={t("storeType")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {Object.values(STORE_TYPE).map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(STORE_TYPE_LABEL[item])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={1}
            sx={{ alignContent: "center" }}
          >
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              alignContent="center"
              spacing={2}
            >
              <LoadingButton
                loading={downloading}
                loadingPosition="start"
                variant="outlined"
                disabled={!stores.length}
                onClick={handleDownload}
                sx={{ fontWeight: 400 }}
              >
                <DownloadOutlined sx={{ fontSize: 20 }} />
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        <StoreList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          stores={Array.isArray(stores) ? stores : []}
          distributors={distributors}
          handleOpenDialog={(currentItem) => {
            setItem(currentItem);
            setOpenDialog(true);
          }}
          handleOpenPopup={(currentItem) => {
            setItem(currentItem);
            setOpenPopup(true);
          }}
        />
        <StoreDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          data={item}
          stores={stores}
          provinces={provinces}
          sales={sales}
          distributors={distributors}
          regions={regions}
          products={products}
          reload={() => setReload((prev) => !prev)}
        />
      </MainCard>
      <Popup
        open={openPopup}
        onClose={handleClosePopup}
        onOk={handleDelete}
        okMessage={t("accept")}
        content={t("areYouSureDeleteStore", {
          name: item.name,
        })}
        title={t("deleteStore")}
      />
    </>
  );
};

export default Store;
