import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Table from "../../components/Table";
import { formatNumber } from "../../utils/number";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import ROUTE from "../../constants/route";
import DistributorOrderStatus from "../../components/DistributorOrderStatus";
import { useLocation } from "react-router-dom";

const DistributorOrderList = ({
  loading,
  paging,
  handleChangePage,
  distributorOrders,
  distributors,
  path,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const handleClickRow = (row) => {
    const searchParams = new URLSearchParams(location.search);
    history.push({
      pathname: `${path || ROUTE.DISTRIBUTOR_ORDER}/${row.id}`,
      search: searchParams.toString(),
    });
  };

  const renderDistributor = (order) => {
    const { distributorId } = order;
    const distributor = distributors.find((d) => d.id === distributorId);
    if (distributor)
      return (
        <Typography
          color="primary"
          sx={{ "&:hover": { textDecoration: "underline" } }}
        >
          {`${distributor.metadata?.name || distributor.name}`}
        </Typography>
      );
    return "-";
  };

  const columns = [
    {
      title: t("distributor"),
      align: "left",
      render: (row) => renderDistributor(row),
    },
    {
      title: t("region"),
      align: "left",
      render: (row) => (row.region ? `${t("region")} ${row.region}` : "-"),
    },
    {
      title: t("totalBox"),
      align: "center",
      field: "totalBox",
      sortable: true,
      render: (row) => row.items.reduce((acc, item) => acc + item.quantity, 0),
    },
    {
      title: t("category"),
      align: "left",
      render: (row) => row.category?.name || "-",
    },
    {
      title: t("totalPrice"),
      align: "center",
      field: "totalPrice",
      render: (row) => formatNumber(row.totalPrice),
      sortable: true,
    },
    {
      title: t("createdAt"),
      align: "left",
      render: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("updatedAt"),
      align: "left",
      render: (row) => dayjs(row.updatedAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("startShippingAt"),
      align: "left",
      render: (row) =>
        row.startShippingAt
          ? dayjs(row.startShippingAt).format("YYYY-MM-DD HH:mm")
          : "-",
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => <DistributorOrderStatus status={row.status} />,
    },
  ];

  return (
    <>
      <Table
        data={distributorOrders}
        columns={columns}
        total={paging.total}
        page={paging.page}
        limit={paging.limit}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
        onRowClick={handleClickRow}
      />
    </>
  );
};

export default DistributorOrderList;
