const ROUTE = {
  DASHBOARD: "/",
  LOGIN: "/login",
  PERMISSION: "/permission",
  ROLE: "/role",
  USER: "/user",
  DISTRIBUTOR: "/distributor",
  DISTRIBUTOR_DETAIL: "/distributor/:distributorId",
  STORE: "/store",
  TIMESHEET: "/timesheet",
  TIMESHEET_DETAIL: "/timesheet/user/:userId",
  DISCOUNT: "/discount",
  ORDER: "/order",
  PRODUCT: "/product",
  CATEGORY: "/category",
  TRANSACTION_COLLECT: "/transaction-collect",
  TRANSACTION_WITHDRAW: "/transaction-withdraw",
  PAYMENT_ACCOUNT: "/payment-account",
  DISTRIBUTOR_ORDER: "/distributor-order",
  DISTRIBUTOR_ORDER_DETAIL: "/distributor-order/:distributorOrderId",
  CREATE_DISTRIBUTOR_ORDER: "/distributor-order/create",
  WAREHOUSE_ORDER: "/warehouse/distributor-order",
  WAREHOUSE_ORDER_DETAIL: "/warehouse/distributor-order/:distributorOrderId",
  FREIGHT_COMPENSATION: "/freight-compensation",
  FREIGHT_COMPENSATION_DETAIL: "/freight-compensation/:freightCompensationId",
  BONUS: "/bonus",
  BONUS_DETAIL: "/bonus/:bonusId",
  TRANSACTION: "/transaction",
  STATEMENT: "/statement",
  STATEMENT_DETAIL: "/statement/:statementId",
  OA_MESSAGE_TEMPLATE: "/oa-message-template",
  BANNER: "/banner",
  OA_MESSAGE: "/oa-message",
  PROFILE: "/profile",
  FEEDBACK: "/feedback",
  REGISTER_BANNER: "/register-banner",
  MAINTAIN_POSM: "/maintain-posm",
  NEW_COMPETITOR_PRODUCT: "/new-competitor-product",
  UPDATE_STORE: "/update-store",
  WORK_REPORTS: "/work-reports",
};

export default ROUTE;
