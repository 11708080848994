import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Table from "../../components/Table";
import ROUTE from "../../constants/route";
import { formatNumber } from "../../utils/number";
import { Stack } from "@mui/material";

const UserList = ({ loading, paging, handleChangePage, users }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickRow = (row) =>
    history.push(`${ROUTE.TIMESHEET}/user/${row.id}`);

  const columns = [
    {
      title: t("fullName"),
      align: "left",
      render: (row) => row.metadata?.name || row.name,
      field: "fullName",
      sortable: true,
    },
    {
      title: t("phoneNumber"),
      align: "left",
      field: "phoneNumber",
      sortable: true,
    },
    {
      title: t("role"),
      align: "left",
      render: (row) => row.role.name,
    },
    {
      title: "",
      align: "center",
      render: (row) => (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Stack>
        </>
      ),
    },
    {
      title: `${t("totalTraveledDistance")} (km)`,
      align: "right",
      render: (row) =>
        formatNumber(
          Math.round((row.totalTraveledDistance / 1000) * 100) / 100
        ),
      field: "traveledDistance",
      sortable: true,
      type: "number",
    },
    {
      title: "",
      align: "center",
      render: (row) => (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Stack>
        </>
      ),
    },
    {
      title: "",
      align: "center",
      render: (row) => (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={users}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
        onRowClick={handleClickRow}
      />
    </>
  );
};

export default UserList;
