import api from "./api";

const getNotifications = async (params) => {
  const res = await api({
    method: "GET",
    url: `/notifications`,
    params,
  });
  return res;
};

const seenNotification = async (id) => {
  const res = await api({
    method: "POST",
    url: `/notifications/${id}/seen`,
  });
  return res;
};

export { getNotifications, seenNotification };
