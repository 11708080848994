import { QR_CODE_URL } from "../configs";

const getPaymentQrLink = (
  bankBin,
  bankHolder,
  bankNumber,
  amount,
  description
) => {
  const qrLink =
    `${QR_CODE_URL}/${bankBin}-${bankNumber}-print.jpg?accountName=${bankHolder}` +
    (amount ? `&amount=${amount}` : "") +
    (description ? `&addInfo=${description}` : "");
  return qrLink;
};

export { getPaymentQrLink };
