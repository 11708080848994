import { styled } from "@mui/material/styles";
import { TableContainer } from "@mui/material";
import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";

const StyledTable = styled(TableContainer)`
  background: ${COLOR.white[100]};

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b9c7;
    border-radius: 3px;
  }

  .table {
    min-width: 1266px;
  }

  .header-cell {
    background: ${COLOR.primary.light};
    padding: 6px 12px;
    height: 32px;
    border: 1px solid ${COLOR.black[16]} !important;
    font-size: 16px;
  }

  .body-row {
    &:last-child td,
    &:last-child th {
      border: 0;
    }
  }

  .body-cell {
    border: 1px solid ${COLOR.black[16]} !important;
    padding: 6px 12px;
    width: ${(props) => (props.width ? props.width : "auto")};
    font-size: 16px;
  }

  .cell-no {
    width: 30px !important;
  }

  .cell-unit {
    width: 80px !important;
  }

  .cell-product {
    min-width: 350px !important;
  }

  .cell-rate {
    min-width: 100px !important;
    &:hover {
      background: ${TRANSPARENT_COLOR.light};
    }
  }

  .cell-note {
    min-width: 200px !important;
  }

  .cell-action {
    min-width: 50px !important;
  }
`;

export { StyledTable };
