import { VELA_AUTH_ERROR_CODE, VELA_API_ERROR_CODE } from "./code";

const VELA_AUTH_ERROR_MESSAGE = {
  [VELA_AUTH_ERROR_CODE.GROUP_PERMISSION_EXIST]: "groupPermissionExists",
  [VELA_AUTH_ERROR_CODE.GROUP_PERMISSION_NOT_FOUND]: "groupPermissionNotFound",
  [VELA_AUTH_ERROR_CODE.GROUP_PERMISSION_HAS_PERMISSIONS]:
    "groupPermissionHasPermissions",
  [VELA_AUTH_ERROR_CODE.PERMISSION_EXIST]: "permissionExists",
  [VELA_AUTH_ERROR_CODE.PERMISSION_NOT_FOUND]: "permissionNotFound",
  [VELA_AUTH_ERROR_CODE.ROLE_EXIST]: "roleExists",
  [VELA_AUTH_ERROR_CODE.ROLE_NOT_FOUND]: "roleNotFound",
  [VELA_AUTH_ERROR_CODE.ROLE_ASSIGNED_FOR_USER]: "roleAssignedForUser",
  [VELA_AUTH_ERROR_CODE.CREDENTIAL_INVALID]: "invalidCredential",
  [VELA_AUTH_ERROR_CODE.USER_NOT_ACTIVE]: "userInactive",
  [VELA_AUTH_ERROR_CODE.USER_NOT_FOUND]: "userNotFound",
  [VELA_AUTH_ERROR_CODE.USER_EXIST]: "userExists",
  [VELA_AUTH_ERROR_CODE.PASSWORD_INVALID]: "passwordInvalid",
};

const VELA_API_ERROR_MESSAGE = {
  [VELA_API_ERROR_CODE.CATEGORY_EXIST]: "categoryExist",
  [VELA_API_ERROR_CODE.CATEGORY_NOT_FOUND]: "categoryNotFound",
  [VELA_API_ERROR_CODE.PRODUCT_EXIST]: "productExist",
  [VELA_API_ERROR_CODE.PRODUCT_NOT_FOUND]: "productNotFound",
  [VELA_API_ERROR_CODE.CATEGORY_IN_USE]: "categoryInUse",
  [VELA_API_ERROR_CODE.CATEGORY_INACTIVE]: "categoryInactive",
};

export { VELA_AUTH_ERROR_MESSAGE, VELA_API_ERROR_MESSAGE };
