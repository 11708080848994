import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import { validateRequired as checkRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";
import { DIALOG_TYPE } from "../../constants";
import UploadFile from "../../components/UploadFile";

const CategoryDialog = ({
  open,
  handleClose,
  dialogType,
  initCategory,
  reload,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState({});

  const handleCloseDialog = () => {
    handleClose();
    setNameError("");
    setFileError("");
    setFile(null);
  };

  const validateNameRequired = () => {
    if (!checkRequired(category.name)) {
      setNameError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validateFileRequired = () => {
    if (!category.image && !checkRequired(file)) {
      setFileError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validate = () => validateNameRequired() && validateFileRequired();

  const handleCreate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      const res = await apis.upload.uploadFile(file);
      if (res.error) throw new Error("somethingWentWrong");
      await apis.category.createCategory({
        name: category.name,
        noReport: category.noReport,
        image: res.result.urls[0],
      });
      handleCloseDialog();
      reload();
      toast.success(t("addCategorySuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      let image = category.image;
      if (file) {
        const res = await apis.upload.uploadFile(file);
        if (res.error) throw new Error("somethingWentWrong");
        image = res.result.urls[0];
      }
      await apis.category.updateCategory(category.id, {
        name: category.name,
        noReport: category.noReport,
        image,
      });
      handleCloseDialog();
      reload();
      toast.success(t("editCategorySuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) setCategory(initCategory);
  }, [open, initCategory]);

  return (
    <Dialog
      title={
        dialogType === DIALOG_TYPE.CREATE ? t("addCategory") : t("editCategory")
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(category).length && (
          <>
            <Box mb={3}>
              <Typography fontWeight={500} mb={1}>
                {t("categoryName")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={nameError}
                error={!!nameError}
                value={category.name}
                size="small"
                fullWidth
                onChange={(e) =>
                  setCategory((prev) => ({ ...prev, name: e.target.value }))
                }
                onBlur={validateNameRequired}
                placeholder={t("categoryName")}
              />
            </Box>
            <Box mb={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!category.noReport}
                    onChange={(e) =>
                      setCategory((prev) => ({
                        ...prev,
                        noReport: e.target.checked,
                      }))
                    }
                  />
                }
                label={t("noReportDesc")}
              />
            </Box>
            <Box>
              <Typography fontWeight={500} mb={1}>
                {t("image")} <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <UploadFile
                url={category.image}
                file={file}
                setFile={setFile}
                error={fileError}
                setError={setFileError}
                required={true}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {dialogType === DIALOG_TYPE.CREATE ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreate}
          >
            {t("addNew")}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdate}
          >
            {t("save")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CategoryDialog;
