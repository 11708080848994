import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Input,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

import apis from "../../../apis";
import { StyledTable } from "./index.style";
import { SaveOutlined } from "@mui/icons-material";
import { validateRequired } from "../../../utils/validate";
import CurrencyInput from "../../../components/CurrencyInput";

const INIT_RATE = {
  monthlyTarget: 0,
  monthlyBonusRate: 0,
  quarterlyTarget: 0,
  quarterlyBonusRate: 0,
  annualTarget: 0,
  annualBonusRate: 0,
};

const DistributorSales = ({ distributor }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsError, setItemsError] = useState([]);
  const [updating, setUpdating] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const newItem = { ...items[index], [field]: value };
    const newItems = [...items];
    newItems[index] = newItem;
    setItems(newItems);

    let newItemError = { ...itemsError[index], [field]: false };
    const newItemsError = [...itemsError];
    newItemsError[index] = newItemError;
    setItemsError(newItemsError);
  };

  const validateItem = (event, initName) => {
    const name = event ? event.target.name : initName;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const value = items[index][field];
    let isError = false;

    if (!validateRequired(value)) {
      isError = true;
    } else {
      if (
        ["monthlyBonusRate", "quarterlyBonusRate", "annualBonusRate"].includes(
          field
        )
      ) {
        const v = +value;
        if (v > 100) isError = true;
      }
    }

    const newItemsErrors = [...itemsError];
    newItemsErrors[index] = { ...itemsError[index], [field]: isError };
    setItemsError(newItemsErrors);

    return !isError;
  };

  const validate = () =>
    items.every(
      (item, index) =>
        Object.values(item).every((value) => !validateRequired(value)) ||
        (validateItem(null, index + ".monthlyTarget") &&
          validateItem(null, index + ".monthlyBonusRate") &&
          validateItem(null, index + ".quarterlyTarget") &&
          validateItem(null, index + ".quarterlyBonusRate") &&
          validateItem(null, index + ".annualTarget") &&
          validateItem(null, index + ".annualBonusRate"))
    );

  const handleUpdateRates = async () => {
    if (!validate()) {
      toast.error(t("dataInvalid"));
      return;
    }

    setUpdating(true);
    try {
      const data = {
        distributorId: distributor.id,
        rates: items.map((item) => ({
          categoryId: item.categoryId,
          monthlyTarget: item.monthlyTarget,
          monthlyBonusRate: item.monthlyBonusRate,
          quarterlyTarget: item.quarterlyTarget,
          quarterlyBonusRate: item.quarterlyBonusRate,
          annualTarget: item.annualTarget,
          annualBonusRate: item.annualBonusRate,
        })),
      };
      await apis.bonusRate.updateBonusRates(data);
      toast.success(t("updateSalesSuccess"));
      handleInit();
    } catch (error) {
      toast.error(t(error.message));
    }
    setUpdating(false);
  };

  const getBonusRates = async () => {
    try {
      const { result = [] } = await apis.bonusRate.getBonusRates(
        distributor.id
      );
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const getCategories = async () => {
    try {
      const { result = [] } = await apis.category.getCategories({
        noReport: false,
      });
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return null;
    }
  };

  const handleInit = async () => {
    const [initCategories, rates] = await Promise.all([
      getCategories(),
      getBonusRates(),
    ]);
    if (!initCategories || !rates) {
      setError(true);
      return;
    }

    const initItems = initCategories.map((category) => {
      const { id: categoryId, name: categoryName } = category;
      const rate = rates.find((rate) => rate.categoryId === categoryId);
      return {
        categoryId,
        categoryName,
        monthlyTarget: rate?.monthlyTarget || 0,
        monthlyBonusRate: rate?.monthlyBonusRate || 0,
        quarterlyTarget: rate?.quarterlyTarget || 0,
        quarterlyBonusRate: rate?.quarterlyBonusRate || 0,
        annualTarget: rate?.annualTarget || 0,
        annualBonusRate: rate?.annualBonusRate || 0,
      };
    });

    const initItemsError = initItems.map(() => INIT_RATE);

    setItems(initItems);
    setItemsError(initItemsError);
  };

  const init = async () => {
    setLoading(true);
    await handleInit();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  if (loading)
    return (
      <Box padding={2}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box padding={2}>
        <Typography variant="body1" textAlign="center">
          {t("noData")} !
        </Typography>
      </Box>
    );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        mb={2}
      >
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          startIcon={<SaveOutlined />}
          color="success"
          onClick={handleUpdateRates}
          loading={updating}
        >
          {t("save")}
        </LoadingButton>
      </Stack>
      <StyledTable>
        <Table className="table">
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="header-cell cell-no" align="center">
                {t("no")}
              </TableCell>
              <TableCell className="header-cell cell-product" align="center">
                {t("category")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("monthlyTarget")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("monthlyBonusRate")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("quarterlyTarget")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("quarterlyBonusRate")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("annualTarget")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("annualBonusRate")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {items.map((item, index) => (
              <TableRow
                key={index}
                className="body-row"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="body-cell" align="center">
                  {index + 1}
                </TableCell>
                <TableCell className="body-cell">{item.categoryName}</TableCell>
                <TableCell className="body-cell cell-target">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.monthlyTarget}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.monthlyTarget}
                    error={!!itemsError[index]?.monthlyTarget}
                    name={index + ".monthlyTarget"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">&#8363;</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.monthlyBonusRate}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.monthlyBonusRate}
                    error={!!itemsError[index]?.monthlyBonusRate}
                    name={index + ".monthlyBonusRate"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-target">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.quarterlyTarget}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.quarterlyTarget}
                    error={!!itemsError[index]?.quarterlyTarget}
                    name={index + ".quarterlyTarget"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">&#8363;</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.quarterlyBonusRate}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.quarterlyBonusRate}
                    error={!!itemsError[index]?.quarterlyBonusRate}
                    name={index + ".quarterlyBonusRate"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-target">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.annualTarget}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.annualTarget}
                    error={!!itemsError[index]?.annualTarget}
                    name={index + ".annualTarget"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">&#8363;</InputAdornment>
                    }
                  />
                </TableCell>
                <TableCell className="body-cell cell-rate">
                  <CurrencyInput
                    customInput={Input}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    value={item.annualBonusRate}
                    variant="standard"
                    disableUnderline={!itemsError[index]?.annualBonusRate}
                    error={!!itemsError[index]?.annualBonusRate}
                    name={index + ".annualBonusRate"}
                    fullWidth
                    onChange={handleChange}
                    onBlur={validateItem}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTable>
    </>
  );
};

export default DistributorSales;
