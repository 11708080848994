import { Edit, LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Table from "../../components/Table";
import { DIALOG_TYPE } from "../../constants";
import { BANNER_STATUS } from "../../constants/banner";
import { APPLIED_OBJECT_MAPPING } from "../../constants/discount";
import { isActiveBanner } from "../../services/banner";
import { COLOR } from "../../styles/color";

const getBannerAppliedObject = (banner = {}) => {
  const { appliedObject } = banner;
  if (!appliedObject) return "";
  const { type, value } = appliedObject;
  if (!type) return "Tất cả";
  return `${APPLIED_OBJECT_MAPPING[type] || ""} ${value
    ?.map((item) => item.metadata?.name || item.name || "")
    .join(", ")}`;
};

const BannerList = ({
  loading,
  paging,
  handleChangePage,
  banners,
  handleOpenBannerDialog,
  handleOpenActivateBannerPopup,
}) => {
  const { t } = useTranslation();

  const renderBannerStatus = (banner) => {
    const { status } = banner;
    if (status === BANNER_STATUS.INACTIVE) {
      return (
        <Typography sx={{ color: COLOR.error.base }}>
          {t("inactive")}
        </Typography>
      );
    }
    if (status === BANNER_STATUS.ACTIVE) {
      return (
        <Typography sx={{ color: COLOR.success.base }}>
          {t("active")}
        </Typography>
      );
    }
    return "--";
  };
  const columns = [
    {
      title: t("name"),
      align: "left",
      field: "name",
    },
    {
      title: t("bannerImage"),
      align: "left",
      render: (row) => (
        <img
          src={row.image}
          alt={row.name}
          style={{ width: 100, height: 100 }}
        />
      ),
    },
    {
      title: t("appliedObject"),
      align: "left",
      render: (row) => <Typography>{getBannerAppliedObject(row)}</Typography>,
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => renderBannerStatus(row),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  onClick={() =>
                    handleOpenBannerDialog(DIALOG_TYPE.UPDATE, row)
                  }
                  color="edit"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                isActiveBanner(row)
                  ? t("deactivateBanner")
                  : t("activateBanner")
              }
            >
              <span>
                <IconButton onClick={() => handleOpenActivateBannerPopup(row)}>
                  {isActiveBanner(row) ? (
                    <LockOutlined color="error" />
                  ) : (
                    <LockOpenOutlined color="info" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={banners}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default BannerList;
