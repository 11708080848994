import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import userSlice from "./userSlice";
import roleSlice from "./roleSlice";
import notificationSlice from "./notificationSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    role: roleSlice,
    notification: notificationSlice,
  },
});
