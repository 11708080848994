const QR_TYPE = {
  VERIFY_STORE: "VERIFY_STORE",
  CREATE_ASM: "CREATE_ASM",
  CREATE_SUPERVISOR: "CREATE_SUPERVISOR",
  CREATE_DISTRIBUTOR: "CREATE_DISTRIBUTOR",
  CREATE_SALE: "CREATE_SALE",
  CREATE_BUSINESS_DIRECTOR: "CREATE_BUSINESS_DIRECTOR",
};

module.exports = { QR_TYPE };
