const STORE_TYPE = {
  STORE: "STORE",
  CONVENIENCE_STORE: "CONVENIENCE_STORE",
  SUPERMARKET: "SUPERMARKET",
  GROCERY_STORE: "GROCERY_STORE",
  WHOLESALE: "WHOLESALE",
  SCHOOL_STORE: "SCHOOL_STORE",
  HOSPITAL_STORE: "HOSPITAL_STORE",
  MARKET_STORE: "MARKET_STORE",
  KA: "KA",
  CVS: "CVS",
  FACTORY: "FACTORY",
  INTERNET_SHOP: "INTERNET_SHOP",
  KTV: "KTV",
  RESTAURANT: "RESTAURANT",
  OTHER: "OTHER",
};

const STORE_TYPE_LABEL = {
  [STORE_TYPE.STORE]: "store",
  [STORE_TYPE.CONVENIENCE_STORE]: "convenienceStore",
  [STORE_TYPE.SUPERMARKET]: "supermarket",
  [STORE_TYPE.GROCERY_STORE]: "groceryStore",
  [STORE_TYPE.WHOLESALE]: "wholesale",
  [STORE_TYPE.SCHOOL_STORE]: "schoolStore",
  [STORE_TYPE.HOSPITAL_STORE]: "hospitalStore",
  [STORE_TYPE.MARKET_STORE]: "marketStore",
  [STORE_TYPE.KA]: "ka",
  [STORE_TYPE.CVS]: "cvs",
  [STORE_TYPE.FACTORY]: "factory",
  [STORE_TYPE.INTERNET_SHOP]: "internetShop",
  [STORE_TYPE.KTV]: "ktv",
  [STORE_TYPE.RESTAURANT]: "restaurant",
  [STORE_TYPE.OTHER]: "other",
};

const STORE_STATUS = {
  COLLABORATING: "COLLABORATING",
  NOT_COLLABORATING: "NOT_COLLABORATING",
  STOPPED: "STOPPED_COLLABORATING",
};

const STORE_CHANNEL_TYPE = {
  GT: "GT",
  MT: "MT",
  SPECIAL: "SPECIAL",
};

const STORE_CHANNEL_TYPE_MAPPING = {
  [STORE_CHANNEL_TYPE.GT]: [
    STORE_TYPE.SUPERMARKET,
    STORE_TYPE.GROCERY_STORE,
    STORE_TYPE.WHOLESALE,
    STORE_TYPE.SCHOOL_STORE,
    STORE_TYPE.HOSPITAL_STORE,
    STORE_TYPE.MARKET_STORE,
    STORE_TYPE.OTHER,
  ],
  [STORE_CHANNEL_TYPE.MT]: [STORE_TYPE.KA, STORE_TYPE.CVS, STORE_TYPE.OTHER],
  [STORE_CHANNEL_TYPE.SPECIAL]: [
    STORE_TYPE.FACTORY,
    STORE_TYPE.INTERNET_SHOP,
    STORE_TYPE.KTV,
    STORE_TYPE.RESTAURANT,
    STORE_TYPE.OTHER,
  ],
};

const STORE_ROUTES = {
  T2: "T2",
  T3: "T3",
  T4: "T4",
  T5: "T5",
  T6: "T6",
  T7: "T7",
  CN: "CN",
};

module.exports = {
  STORE_TYPE,
  STORE_TYPE_LABEL,
  STORE_STATUS,
  STORE_CHANNEL_TYPE,
  STORE_ROUTES,
  STORE_CHANNEL_TYPE_MAPPING,
};
