import { Autocomplete, Grid, MenuItem, Stack, TextField } from "@mui/material";
import dayjs from "dayjs";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { DownloadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import DatePickerRange from "../../components/PickDateRange";
import {
  ALL,
  INITIAL_PAGING as DEFAULT_PAGING,
  MINI_APP_ROLE_KEY,
} from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import { downloadFile } from "../../utils/download";
import WorkReportList from "./WorkReportList";

const INITIAL_PAGING = { ...DEFAULT_PAGING, limit: 10 };

const INIT_FILTER = {
  userId: null,
  role: ALL,
  startDate: dayjs().startOf("month"),
  endDate: dayjs().endOf("month"),
};

const WorkReport = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [loading, setLoading] = useState(true);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [filter, setFilter] = useState(INIT_FILTER);
  const [users, setUsers] = useState([]);
  const [reports, setReports] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const { addParams } = useSearchParams();

  const renderUserOptions = (id) => {
    const user = users.find((u) => u.id === id);
    if (!user) return "";
    return `${user.metadata?.name || user.name}`;
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const handleChangeUserId = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      userId: newValue,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      userId: newValue,
    });
  };

  const handleChangeRole = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      role: newValue,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      role: newValue,
    });
  };

  const handleChangeDate = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      startDate: newValue[0],
      endDate: newValue[1],
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(newValue[0]).format("YYYY-MM-DD"),
      endDate: dayjs(newValue[1]).format("YYYY-MM-DD"),
    });
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const {
        userId = INIT_FILTER.userId,
        role = INIT_FILTER.role,
        startDate = dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
        endDate = dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
      } = searchParams;

      await downloadFile({
        url: "/work-reports/download",
        params: {
          role: role === ALL ? undefined : role,
          userId: userId || undefined,
          startDate: dayjs(startDate).startOf("day").format(),
          endDate: dayjs(endDate).endOf("day").format(),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `work-reports_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }
    setDownloading(false);
  };

  const fetchUsers = async () => {
    try {
      const { result = [] } = await apis.user.getUsers();
      setUsers(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchReports = async (newFilter) => {
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        userId: newFilter.userId || undefined,
        role: newFilter.role === ALL ? undefined : newFilter.role,
        startDate: newFilter.startDate
          ? dayjs(newFilter.startDate).startOf("day").format()
          : undefined,
        endDate: newFilter.endDate
          ? dayjs(newFilter.endDate).endOf("day").format()
          : undefined,
      };

      const { result } = await apis.workReport.getWorkReports(condition);
      setReports(result.reports);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchData = async (newFilter) => {
    setLoading(true);
    await fetchReports(newFilter);
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      userId = INIT_FILTER.userId,
      role = INIT_FILTER.role,
      startDate = INIT_FILTER.startDate,
      endDate = INIT_FILTER.endDate,
    } = searchParams;
    setFilter({
      userId,
      role,
      startDate,
      endDate,
    });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchData({
      userId,
      role,
      startDate,
      endDate,
      page: parseInt(page, 10),
    });
  }, [location.search]);

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <PageTitle title={t("workReports")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              size="small"
              value={filter.userId}
              options={users.map((user) => user.id)}
              getOptionLabel={(option) => renderUserOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) => handleChangeUserId(newValue)}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderUserOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder={t("user")} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} lg={2}>
            <Autocomplete
              size="small"
              value={filter.role}
              options={[
                ALL,
                MINI_APP_ROLE_KEY.ASM,
                MINI_APP_ROLE_KEY.SUPERVISOR,
              ]}
              getOptionLabel={(option) => t(option)}
              filterSelectedOptions
              onChange={(event, newValue) => handleChangeRole(newValue)}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {t(key?.toLowerCase())}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder={t("role")} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} lg={2}>
            <DatePickerRange
              value={[filter.startDate, filter.endDate]}
              onChange={handleChangeDate}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} lg={3}></Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <LoadingButton
                loading={downloading}
                loadingPosition="start"
                variant="outlined"
                startIcon={<DownloadOutlined />}
                disabled={!users.length}
                onClick={handleDownload}
              >
                {t("download")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        <WorkReportList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          data={reports}
        />
      </MainCard>
    </>
  );
};

export default WorkReport;
