import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  ContactEmergency,
  EmailOutlined,
  LocalPhoneOutlined,
  LocationOn,
  Password,
  PermIdentityOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

import apis from "../../apis";
import PageTitle from "../../components/PageTitle";
import MainCard from "../../components/MainCard";
import Dialog from "../../components/Dialog";
import { validateRequired as checkRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";

const INIT_CHANGE_PASSWORD = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const INIT_CHANGE_PASSWORD_ERROR = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const Profile = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);

  const [data, setData] = useState(INIT_CHANGE_PASSWORD);
  const [dataError, setDataError] = useState(INIT_CHANGE_PASSWORD_ERROR);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenChangePasswordDialog = () => {
    setOpenChangePasswordDialog(true);
  };

  const handleCloseChangePasswordDialog = () => {
    setOpenChangePasswordDialog(false);
    setData(INIT_CHANGE_PASSWORD);
    setDataError(INIT_CHANGE_PASSWORD_ERROR);
  };

  const handleChange = (event) => {
    const { name: field, value } = event.target;
    setData((prev) => ({ ...prev, [field]: value }));
    setDataError((prev) => ({ ...prev, [field]: "" }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(data[field])) {
      setDataError((prev) => ({
        ...prev,
        [field]: t("fieldRequired"),
      }));
      return false;
    }
    return true;
  };

  const validateSameOldPassword = () => {
    if (data.newPassword === data.oldPassword) {
      setDataError((prev) => ({
        ...prev,
        newPassword: t("sameOldPassword"),
      }));
      return false;
    }
    return true;
  };

  const validateConfirmNewPassword = () => {
    if (data.newPassword && data.confirmNewPassword !== data.newPassword) {
      setDataError((prev) => ({
        ...prev,
        confirmNewPassword: t("passwordNotMatch"),
      }));
      return false;
    }
    return true;
  };

  const validate = () =>
    validateRequired("oldPassword") &&
    validateRequired("newPassword") &&
    validateSameOldPassword() &&
    validateConfirmNewPassword();

  const handleChangePassword = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await apis.user.changePassword(data);
      handleCloseChangePasswordDialog();
      toast.success(t("changePasswordSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  return (
    <>
      <PageTitle title={t("profile")} />
      <MainCard sx={{ maxWidth: "600px", margin: "0 auto" }}>
        <Box p={1} mb={3}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            mb={3}
          >
            <Avatar
              alt={currentUser.name}
              src={currentUser.avatar}
              sx={{ width: 100, height: 100 }}
            />
            <Box>
              <Typography fontWeight={600} fontSize="20px" align="center">
                {currentUser.metadata?.name || currentUser.name}
              </Typography>
              <Typography align="center">{role?.name}</Typography>
            </Box>
          </Stack>
          <Box mb={3}>
            <Divider />
          </Box>

          <Stack direction="row" alignItems="center" spacing={3} mb={2}>
            <PermIdentityOutlined color="disabled" />
            <Typography fontWeight={500}>
              {currentUser.metadata?.name || currentUser.name}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={3} mb={2}>
            <EmailOutlined color="disabled" />
            <Typography fontWeight={500}>
              {currentUser.metadata?.email || "-"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={3} mb={2}>
            <LocalPhoneOutlined color="disabled" />
            <Typography fontWeight={500}>
              {currentUser.phoneNumber || "-"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={3} mb={2}>
            <ContactEmergency color="disabled" />
            <Typography fontWeight={500}>
              {currentUser.metadata?.citizenIdentification || "-"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={3}>
            <LocationOn color="disabled" />
            <Typography fontWeight={500}>
              {[
                currentUser.metadata?.address?.addressDetail,
                currentUser.metadata?.address?.ward,
                currentUser.metadata?.address?.district,
                currentUser.metadata?.address?.province,
              ]
                .filter((item) => item)
                .join(", ") || "-"}
            </Typography>
          </Stack>
        </Box>
        <Button
          variant="outlined"
          startIcon={<Password />}
          onClick={handleOpenChangePasswordDialog}
        >
          {t("changePassword")}
        </Button>
      </MainCard>
      <Dialog
        title={t("changePassword")}
        open={openChangePasswordDialog}
        onClose={handleCloseChangePasswordDialog}
        maxWidth="xs"
      >
        <DialogContent>
          <Box mb={3}>
            <Typography fontWeight={500} mb={1}>
              {t("oldPassword")}{" "}
              <span style={{ color: COLOR.error.base }}>*</span>:
            </Typography>
            <TextField
              error={!!dataError.oldPassword}
              helperText={dataError.oldPassword}
              value={data.oldPassword}
              size="small"
              name="oldPassword"
              type="password"
              fullWidth
              onChange={handleChange}
              onBlur={() => validateRequired("oldPassword")}
            />
          </Box>
          <Box mb={3}>
            <Typography fontWeight={500} mb={1}>
              {t("newPassword")}{" "}
              <span style={{ color: COLOR.error.base }}>*</span>:
            </Typography>
            <TextField
              error={!!dataError.newPassword}
              helperText={dataError.newPassword}
              value={data.newPassword}
              size="small"
              name="newPassword"
              type="password"
              fullWidth
              onChange={handleChange}
              onBlur={() =>
                validateRequired("newPassword") &&
                validateSameOldPassword() &&
                validateConfirmNewPassword()
              }
            />
          </Box>
          <Box mb={3}>
            <Typography fontWeight={500} mb={1}>
              {t("confirmNewPassword")}{" "}
              <span style={{ color: COLOR.error.base }}>*</span>:
            </Typography>
            <TextField
              error={!!dataError.confirmNewPassword}
              helperText={dataError.confirmNewPassword}
              value={data.confirmNewPassword}
              size="small"
              name="confirmNewPassword"
              type="password"
              fullWidth
              onChange={handleChange}
              onBlur={validateConfirmNewPassword}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseChangePasswordDialog}
          >
            {t("cancel")}
          </Button>

          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleChangePassword}
          >
            {t("save")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Profile;
