import { SERVICE } from "../constants";
import api from "./api";

const createAddAsmQr = async ({ region }) => {
  const res = await api({
    method: "POST",
    url: `/qr/add-asm`,
    data: { region },
    source: SERVICE.VELA_API,
  });
  return res;
};

const createAddSupervisorQr = async ({ region, distributorIds, province }) => {
  const res = await api({
    method: "POST",
    url: `/qr/add-supervisor`,
    data: { region, distributorIds, province },
    source: SERVICE.VELA_API,
  });
  return res;
};

const createAddDistributorQr = async ({
  region,
  code,
  supervisorId,
  metadata,
  province,
}) => {
  const res = await api({
    method: "POST",
    url: `/qr/add-distributor`,
    data: { region, code, supervisorId, metadata, province },
    source: SERVICE.VELA_API,
  });
  return res;
};

const createAddBusinessDirectorQr = async () => {
  const res = await api({
    method: "POST",
    url: `/qr/add-business-director`,
    source: SERVICE.VELA_API,
  });
  return res;
};

export {
  createAddAsmQr,
  createAddSupervisorQr,
  createAddDistributorQr,
  createAddBusinessDirectorQr,
};
