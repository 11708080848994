import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import { validateRequired as checkRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";
import { DIALOG_TYPE } from "../../constants";
import UploadFile from "../../components/UploadFile";
import CurrencyInput from "../../components/CurrencyInput";

const INIT_PRODUCT_ERROR = {
  name: "",
  code: "",
  weight: "",
  productPerBox: "",
  price: "",
  productCategoryId: "",
  discountType: "",
  sizeType: "",
};

const ProductDialog = ({
  open,
  handleClose,
  dialogType,
  initProduct,
  categories = [],
  reload,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(INIT_PRODUCT_ERROR);
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState(null);
  const [product, setProduct] = useState({});

  const renderCategoryOptions = (id) => {
    const category = categories.find((c) => c.id === id);
    if (!category) return "";
    return category.name;
  };

  const handleCloseDialog = () => {
    handleClose();
    setError(INIT_PRODUCT_ERROR);
    setFileError("");
    setFile(null);
    setProduct({});
  };

  const handleChangeProduct = (event) => {
    const { name: field, value } = event.target;
    setProduct((prev) => ({ ...prev, [field]: value }));
    setError((prev) => ({ ...prev, [field]: "" }));
  };

  const handleChangeProductCategory = (newValue) => {
    setProduct((prev) => ({ ...prev, productCategoryId: newValue }));
    setError((prev) => ({ ...prev, productCategoryId: "" }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(product[field])) {
      setError((prev) => ({ ...prev, [field]: t("fieldRequired") }));
      return false;
    }
    return true;
  };

  const validateValidNumber = (field) => {
    const value = +product[field];
    if (value < 1) {
      setError((prev) => ({ ...prev, [field]: t("dataInvalid") }));
      return false;
    }
    return true;
  };

  const validateFileRequired = () => {
    if (!product.image && !checkRequired(file)) {
      setFileError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validateType = (field) => {
    const value = product[field];
    if (value.length > 1 || !/^([A-Za-z]\d*)$/.test(value)) {
      setError((prev) => ({ ...prev, [field]: t("dataInvalid") }));
      return false;
    }
    return true;
  };

  const validate = () =>
    validateRequired("name") &&
    validateRequired("code") &&
    validateRequired("weight") &&
    validateRequired("productPerBox") &&
    validateValidNumber("productPerBox") &&
    validateRequired("price") &&
    validateRequired("productCategoryId") &&
    validateRequired("discountType") &&
    validateType("discountType") &&
    validateRequired("sizeType") &&
    validateType("sizeType") &&
    validateFileRequired();

  const handleCreate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      const res = await apis.upload.uploadFile(file);
      if (res.error) throw new Error("somethingWentWrong");
      await apis.product.createProduct({
        ...product,
        image: res.result.urls[0],
      });
      handleCloseDialog();
      reload();
      toast.success(t("addProductSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      let image = product.image;
      if (file) {
        const res = await apis.upload.uploadFile(file);
        if (res.error) throw new Error("somethingWentWrong");
        image = res.result.urls[0];
      }

      const { id: productId, ...data } = product;

      await apis.product.updateProduct(productId, {
        ...data,
        image,
      });
      handleCloseDialog();
      reload();
      toast.success(t("editProductSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) setProduct(initProduct);
  }, [open, initProduct]);

  return (
    <Dialog
      title={
        dialogType === DIALOG_TYPE.CREATE ? t("addProduct") : t("editProduct")
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(product).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("productName")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={error.name}
                error={!!error.name}
                value={product.name}
                size="small"
                name="name"
                fullWidth
                onChange={handleChangeProduct}
                onBlur={() => validateRequired("name")}
                placeholder={t("productName")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("productCode")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={error.code}
                error={!!error.code}
                value={product.code}
                size="small"
                name="code"
                fullWidth
                onChange={handleChangeProduct}
                onBlur={() => validateRequired("code")}
                placeholder={t("productCode")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("weight")} (g){" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <CurrencyInput
                customInput={TextField}
                allowDecimals={false}
                allowNegativeValue={false}
                fullWidth
                value={product.weight}
                onChange={handleChangeProduct}
                onBlur={() => validateRequired("weight")}
                error={!!error.weight}
                helperText={error.weight}
                name="weight"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("productsPerBox")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <CurrencyInput
                customInput={TextField}
                allowDecimals={false}
                allowNegativeValue={false}
                fullWidth
                value={product.productPerBox}
                onChange={handleChangeProduct}
                onBlur={() =>
                  validateRequired("productPerBox") &&
                  validateValidNumber("productPerBox")
                }
                error={!!error.productPerBox}
                helperText={error.productPerBox}
                name="productPerBox"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("price")} (&#8363;){" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <CurrencyInput
                customInput={TextField}
                allowDecimals={false}
                allowNegativeValue={false}
                fullWidth
                value={product.price}
                onChange={handleChangeProduct}
                onBlur={() => validateRequired("price")}
                error={!!error.price}
                helperText={error.price}
                name="price"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("category")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <Autocomplete
                size="small"
                value={product.productCategoryId}
                options={categories.map((category) => category.id)}
                getOptionLabel={(option) => renderCategoryOptions(option)}
                filterSelectedOptions
                onChange={(event, newValue) =>
                  handleChangeProductCategory(newValue)
                }
                onBlur={() => validateRequired("productCategoryId")}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {renderCategoryOptions(key)}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error.productCategoryId}
                    helperText={error.productCategoryId}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("discountType")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={error.discountType}
                error={!!error.discountType}
                value={product.discountType}
                size="small"
                name="discountType"
                fullWidth
                onChange={handleChangeProduct}
                onBlur={() =>
                  validateRequired("discountType") &&
                  validateType("discountType")
                }
                placeholder="A, B, C"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("sizeType")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={error.sizeType}
                error={!!error.sizeType}
                value={product.sizeType}
                size="small"
                name="sizeType"
                fullWidth
                onChange={handleChangeProduct}
                onBlur={() =>
                  validateRequired("sizeType") && validateType("sizeType")
                }
                placeholder="A, B, C"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={1}>
                {t("image")} <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <UploadFile
                url={product.image}
                file={file}
                setFile={setFile}
                error={fileError}
                setError={setFileError}
                required={true}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {dialogType === DIALOG_TYPE.CREATE ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreate}
          >
            {t("addNew")}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdate}
          >
            {t("save")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ProductDialog;
