import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Add, Search } from "@mui/icons-material";

import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import Popup from "../../components/Popup";
import { ALL, DIALOG_TYPE, INITIAL_PAGING, STATUS } from "../../constants";
import apis from "../../apis";
import useSearchParams from "../../hooks/useSearchParams";
import debounce from "../../utils/debounce";
import CategoryList from "./CategoryList";
import CategoryDialog from "./CategoryDialog";

const INIT_FILTER = {
  search: "",
  status: ALL,
};

const INIT_CATEGORY = {
  image: "",
  name: "",
  noReport: false,
};

const Category = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState(INIT_FILTER);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [category, setCategory] = useState(INIT_CATEGORY);
  const [reload, setReload] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, status: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      status: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleOpenDialog = (dt, currentCategory) => {
    setDialogType(dt);
    if (currentCategory) setCategory(currentCategory);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCategory(INIT_CATEGORY);
  };

  const handleOpenPopup = (currentCategory) => {
    setCategory(currentCategory);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleChangeStatusCategory = async () => {
    try {
      await apis.category.changeStatusCategory(category.id, !category.active);
      toast.success(
        category.active ? t("lockCategorySuccess") : t("unlockCategorySuccess")
      );
      setReload((prev) => !prev);
    } catch (error) {
      toast.error(t(error.message));
    }
    setCategory(INIT_CATEGORY);
  };

  const getStatusCondition = (status) =>
    parseInt(status, 10) === STATUS.ACTIVE ? true : false;

  const fetchCategories = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        search: newFilter.search || undefined,
        active:
          newFilter.status === ALL
            ? undefined
            : getStatusCondition(newFilter.status),
      };

      const { result } = await apis.category.getCategories(condition);
      setCategories(result.categories);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      search = INIT_FILTER.search,
      status = INIT_FILTER.status,
    } = searchParams;
    setFilter({ search, status });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchCategories({
      search,
      status,
      page: parseInt(page, 10),
    });
  }, [location.search, reload]);

  return (
    <>
      <PageTitle title={t("store")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("categorySearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.status}
                onChange={handleChangeStatus}
                size="small"
                label={t("status")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                <MenuItem value={STATUS.ACTIVE}>{t("active")}</MenuItem>
                <MenuItem value={STATUS.INACTIVE}>{t("inactive")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleOpenDialog(DIALOG_TYPE.CREATE)}
              >
                {t("addCategory")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <CategoryList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          categories={categories}
          handleOpenDialog={handleOpenDialog}
          handleOpenPopup={handleOpenPopup}
        />
      </MainCard>
      <CategoryDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogType={dialogType}
        initCategory={category}
        reload={() => setReload((prev) => !prev)}
      />
      <Popup
        open={openPopup}
        onClose={handleClosePopup}
        onOk={handleChangeStatusCategory}
        okMessage={t("accept")}
        content={
          category.active
            ? t("areYouSureLockCategory", {
                name: category.name,
              })
            : t("areYouSureUnlockCategory", {
                name: category.name,
              })
        }
        title={category.active ? t("lockCategory") : t("unlockCategory")}
      />
    </>
  );
};

export default Category;
