import { SERVICE } from "../constants";
import api from "./api";

const getRates = async (distributorId) => {
  const res = await api({
    method: "GET",
    url: `/rates`,
    params: { distributorId },
    source: SERVICE.VELA_API,
  });
  return res;
};

const updateRates = async (data) => {
  const res = await api({
    method: "PUT",
    url: `/rates`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

export { getRates, updateRates };
