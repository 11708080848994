import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import UploadFile from "../../components/UploadFile";
import {
  STORE_CHANNEL_TYPE,
  STORE_CHANNEL_TYPE_MAPPING,
  STORE_ROUTES,
  STORE_STATUS,
  STORE_TYPE_LABEL,
} from "../../constants/store";
import { COLOR } from "../../styles/color";

const INIT_STORE_ERROR = {
  name: "",
  saleId: "",
  contactPhoneNumber: "",
  region: "",
  province: "",
  district: "",
  ward: "",
  address: "",
  status: "",
  routes: "",
  channelType: "",
  type: "",
};

const HelperText = ({ text }) => {
  if (!text) return null;
  return (
    <Typography color="error" fontSize={12}>
      {text}
    </Typography>
  );
};

const InputWithLabel = ({
  label,
  value,
  setValue,
  placeholder,
  error,
  inputLabel,
  isRequired,
}) => (
  <>
    {label && (
      <Typography fontWeight={500}>
        {label}{" "}
        {isRequired && <span style={{ color: COLOR.error.base }}>*</span>}:
      </Typography>
    )}
    <TextField
      label={inputLabel}
      error={!!error}
      helperText={error}
      value={value}
      size="small"
      fullWidth
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder}
    />
  </>
);

const SelectInputWithLabel = ({
  label,
  value,
  setValue,
  error,
  options = [],
  getOptionLabel,
  fullWidth = true,
  multiple,
  selectLabel,
  sx,
  isRequired,
}) => (
  <>
    {label ? (
      <Typography fontWeight={500}>
        {label}{" "}
        {isRequired && <span style={{ color: COLOR.error.base }}>*</span>}:
      </Typography>
    ) : null}
    <Select
      label={selectLabel}
      labelId={`demo-simple-select-label-${selectLabel}`}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      size="small"
      fullWidth={fullWidth}
      sx={sx}
      multiple={multiple}
      multiline
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {getOptionLabel(option)}
        </MenuItem>
      ))}
    </Select>
    <HelperText text={error} />
  </>
);

const AutocompleteWithLabel = ({
  label,
  value,
  setValue,
  error,
  options,
  getOptionLabel,
  selectLabel,
  isRequired,
}) => (
  <>
    <Typography fontWeight={500}>
      {label} {isRequired && <span style={{ color: COLOR.error.base }}>*</span>}
      :
    </Typography>
    <Select
      labelId="demo-simple-select-label"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      size="small"
      fullWidth
      label={selectLabel}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {getOptionLabel(option)}
        </MenuItem>
      ))}
    </Select>
    <HelperText text={error} />
  </>
);

const StoreDialog = ({
  open,
  handleClose,
  data,
  reload,
  distributors,
  sales,
  regions,
  provinces,
  products,
}) => {
  const { t } = useTranslation();

  const [error, setError] = useState(INIT_STORE_ERROR);
  const [storeData, setStoreData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const renderSale = (saleId) => {
    const sale = sales.find((s) => s.id === saleId);
    if (sale) return `${sale.name} - ${sale.phoneNumber}`;
    return "-";
  };

  const renderDistributor = (saleId) => {
    const distributor = distributors.find((d) => d.staffIds.includes(saleId));
    if (distributor)
      return `${distributor.metadata?.name} - ${distributor.phoneNumber}`;

    return "-";
  };

  const renderProduct = (productId) => {
    const product = products.find((p) => p.id === productId);
    if (product) return product.name;
    return "-";
  };

  const handleCloseDialog = () => {
    handleClose();
    if (data) setStoreData(data);
  };

  const handleChangeStoreField = (fieldName, value) => {
    setError({ ...error, [fieldName]: "" });
    setStoreData({ ...storeData, [fieldName]: value });
  };

  const validate = () => {
    let errorObject = {};
    Object.keys(INIT_STORE_ERROR).forEach((key) => {
      if (key === "routes" && storeData.status === STORE_STATUS.COLLABORATING) {
        if (!storeData[key]?.length) {
          errorObject[key] = t("requiredField");
        }
      } else if (!storeData[key]) {
        errorObject[key] = t("requiredField");
      }
    });
    setError(errorObject);
    return !Object.keys(errorObject).length;
  };

  const handleUpdate = async () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      let image = storeData.logo;
      if (file) {
        const res = await apis.upload.uploadFile(file);
        if (res.error) throw new Error("somethingWentWrong");
        image = res.result.urls?.[0];
      }
      await apis.store.updateStore(data.id, { ...storeData, logo: image });
      handleCloseDialog();
      reload();
      toast.success(t("editStoreSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data) setStoreData(data);
  }, [data]);

  return (
    <Dialog
      title={t("editStore")}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(data).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <InputWithLabel
                label={t("storeName")}
                value={storeData.name}
                setValue={(value) => handleChangeStoreField("name", value)}
                isRequired
                error={error.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteWithLabel
                label={t("sale")}
                value={storeData.saleId}
                setValue={(val) => handleChangeStoreField("saleId", val)}
                options={sales?.map((sale) => sale.id)}
                getOptionLabel={(id) => renderSale(id)}
                isRequired
                error={error.saleId}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputWithLabel
                label={t("contactPhoneNumber")}
                value={storeData.contactPhoneNumber}
                setValue={(value) =>
                  handleChangeStoreField("contactPhoneNumber", value)
                }
                isRequired
                error={error.contactPhoneNumber}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("distributor")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base, paddingY: "8px" }}>
                {renderDistributor(storeData?.saleId)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <SelectInputWithLabel
                value={storeData.region}
                setValue={(val) => {
                  setStoreData({
                    ...storeData,
                    region: val,
                    province: "",
                    district: "",
                    ward: "",
                  });
                  setError({ ...error, region: "" });
                }}
                label={t("region")}
                options={regions}
                getOptionLabel={(region) => `${t("region")} ${region}`}
                isRequired
                error={error.region}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectInputWithLabel
                label={t("province")}
                value={storeData.province}
                setValue={(val) => {
                  setStoreData({
                    ...storeData,
                    province: val,
                    district: "",
                    ward: "",
                  });
                  setError({ ...error, province: "" });
                }}
                options={provinces
                  ?.filter((item) => item.region === storeData.region)
                  ?.map((province) => province.name)}
                getOptionLabel={(option) => option}
                isRequired
                error={error.province}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectInputWithLabel
                label={t("district")}
                value={storeData.district}
                setValue={(val) => {
                  setStoreData({ ...storeData, district: val, ward: "" });
                  setError({ ...error, district: "" });
                }}
                options={provinces
                  ?.filter((item) => item.region === storeData.region)
                  ?.find((item) => item.name === storeData.province)
                  ?.districts?.map((district) => district.name)}
                getOptionLabel={(option) => option}
                isRequired
                error={error.district}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectInputWithLabel
                label={t("ward")}
                value={storeData.ward}
                setValue={(val) => {
                  setStoreData({ ...storeData, ward: val });
                  setError({ ...error, ward: "" });
                }}
                options={provinces
                  ?.filter((item) => item.region === storeData.region)
                  ?.find((item) => item.name === storeData.province)
                  ?.districts?.find((item) => item.name === storeData.district)
                  ?.wards?.map((ward) => ward.name)}
                getOptionLabel={(option) => option}
                isRequired
                error={error.ward}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <InputWithLabel
                label={t("address")}
                value={storeData.address}
                setValue={(val) => handleChangeStoreField("address", val)}
                isRequired
                error={error.address}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SelectInputWithLabel
                value={storeData.status}
                setValue={(val) => handleChangeStoreField("status", val)}
                label={t("storeStatus")}
                options={Object.values(STORE_STATUS)}
                getOptionLabel={(option) => `${t(option?.toLowerCase())}`}
                isRequired
                error={error.status}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {storeData.status === STORE_STATUS.COLLABORATING && (
                <SelectInputWithLabel
                  label={t("storeRoutes")}
                  value={storeData.routes?.map((item) => item?.name) || []}
                  setValue={(val) => {
                    setStoreData({
                      ...storeData,
                      routes: val.map((route) => ({
                        name: route,
                      })),
                    });
                    setError({ ...error, routes: "" });
                  }}
                  options={Object.values(STORE_ROUTES)}
                  getOptionLabel={(option) => option}
                  multiple={true}
                  isRequired
                  error={error.routes}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectInputWithLabel
                value={storeData.channelType}
                setValue={(val) => handleChangeStoreField("channelType", val)}
                label={t("channelType")}
                options={Object.values(STORE_CHANNEL_TYPE)}
                getOptionLabel={(option) => `${t(option?.toLowerCase())}`}
                isRequired
                error={error.channelType}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectInputWithLabel
                value={storeData.type}
                setValue={(val) => handleChangeStoreField("type", val)}
                label={t("storeType")}
                options={STORE_CHANNEL_TYPE_MAPPING[storeData.channelType]}
                getOptionLabel={(option) => `${t(STORE_TYPE_LABEL[option])}`}
                isRequired
                error={error.type}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SelectInputWithLabel
                label={t("sellingProducts")}
                value={storeData.sellingProducts?.map((item) => item.id) || []}
                setValue={(val) =>
                  setStoreData({
                    ...storeData,
                    sellingProducts: products
                      ?.filter((item) => val.includes(item.id))
                      ?.map((item) => ({
                        id: item.id,
                        name: item.name,
                      })),
                  })
                }
                options={products?.map((item) => item.id)}
                getOptionLabel={(option) => renderProduct(option)}
                multiple={true}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={500}>{t("storeLogo")}:</Typography>
              <UploadFile
                url={storeData.logo}
                file={file}
                setFile={setFile}
                error={false}
                setError={() => {}}
                required={true}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={handleUpdate}
        >
          {t("update")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default StoreDialog;
