import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import { ALL, INITIAL_PAGING as DEFAULT_PAGING } from "../../constants";
import {
  COMPETITOR_REPORT_STATUS,
  COMPETITOR_REPORT_STATUS_LABEL,
} from "../../constants/storeAction";
import useSearchParams from "../../hooks/useSearchParams";
import CompetitorProductDialog from "./CompetitorProductDialog";
import CompetitorProductList from "./CompetitorProductList";

const INITIAL_PAGING = { ...DEFAULT_PAGING, limit: 20 };

const INIT_FILTER = {
  userId: null,
  storeId: null,
  status: ALL,
};

const NewCompetitorProduct = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [filter, setFilter] = useState(INIT_FILTER);
  const [stores, setStores] = useState([]);
  const [users, setUsers] = useState([]);
  const [competitorReports, setCompetitorReports] = useState([]);
  const [reload, setReload] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [item, setItem] = useState({});

  const { addParams } = useSearchParams();

  const renderStoreOptions = (id) => {
    const store = stores.find((s) => s.id === id);
    return store?.name || "";
  };

  const renderUserOptions = (id) => {
    const user = users.find((u) => u.id === id);
    if (!user) return "";
    return `${user.metadata?.name || user.name}`;
  };

  const handleChangeStoreId = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      storeId: newValue,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      storeId: newValue,
    });
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const handleChangeUserId = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      userId: newValue,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      userId: newValue,
    });
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, status: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      status: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleOpenDialog = (currentItem) => {
    setItem(currentItem);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setItem({});
  };

  const fetchUsers = async () => {
    try {
      const { result = [] } = await apis.user.getUsers();
      setUsers(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchStores = async () => {
    try {
      const { result = [] } = await apis.store.getStores();
      setStores(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchMaintainPosm = async (newFilter) => {
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        status: newFilter.status === ALL ? undefined : newFilter.status,
        userId: newFilter.userId || undefined,
        storeId: newFilter.storeId || undefined,
      };

      const { result } = await apis.storeAction.getNewCompetitorProductActions(
        condition
      );
      setCompetitorReports(result.actions);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchData = async (newFilter) => {
    setLoading(true);
    await fetchMaintainPosm(newFilter);
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      userId = INIT_FILTER.userId,
      storeId = INIT_FILTER.storeId,
      status = INIT_FILTER.status,
    } = searchParams;
    setFilter({
      userId,
      storeId,
      status,
    });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchData({
      userId,
      storeId,
      status,
      page: parseInt(page, 10),
    });
  }, [location.search, reload]);

  useEffect(() => {
    fetchUsers();
    fetchStores();
  }, []);

  return (
    <>
      <PageTitle title={t("competitorProductReport")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              size="small"
              value={filter.storeId}
              options={stores.map((store) => store.id)}
              getOptionLabel={(option) => renderStoreOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) => handleChangeStoreId(newValue)}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderStoreOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder={t("store")} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              size="small"
              value={filter.userId}
              options={users.map((user) => user.id)}
              getOptionLabel={(option) => renderUserOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) => handleChangeUserId(newValue)}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderUserOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder={t("createdBy")} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.status}
                onChange={handleChangeStatus}
                size="small"
                label={t("status")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {Object.values(COMPETITOR_REPORT_STATUS).map((value) => (
                  <MenuItem key={value} value={value}>
                    {t(COMPETITOR_REPORT_STATUS_LABEL[value])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <CompetitorProductList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          data={competitorReports}
          users={users}
          stores={stores}
          handleOpenDialog={handleOpenDialog}
          reload={() => fetchMaintainPosm({ ...filter, page: paging.page })}
        />
      </MainCard>
      <CompetitorProductDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        data={item}
        users={users}
        stores={stores}
        reload={() => setReload((prev) => !prev)}
      />
    </>
  );
};

export default NewCompetitorProduct;
