import { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Dialog from "../../../components/Dialog";
import apis from "../../../apis";
import { COLOR } from "../../../styles/color";
import { validateRequired as checkRequired } from "../../../utils/validate";
import CurrencyInput from "../../../components/CurrencyInput";

const UpdateCreditLimitDialog = ({
  open,
  handleClose,
  distributor,
  reload,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [creditLimit, setCreditLimit] = useState(0);
  const [creditLimitError, setCreditLimitError] = useState("");

  const handleCloseDialog = () => {
    handleClose();
    setCreditLimit(0);
    setCreditLimitError("");
  };

  const validateRequired = () => {
    if (!checkRequired(creditLimit)) {
      setCreditLimitError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validateNumber = () => {
    const value = +creditLimit;
    if (value < 0) {
      setCreditLimitError(t("dataInvalid"));
      return false;
    }
    return true;
  };

  const validate = () => validateRequired() && validateNumber();

  const handleUpdate = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await apis.user.updateCreditLimit(distributor.id, creditLimit);
      handleCloseDialog();
      reload();
      toast.success(t("updateCreditLimitSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    setCreditLimit(distributor.creditLimit || 0);
  }, [distributor]);

  return (
    <Dialog
      title={t("updateCreditLimit")}
      maxWidth="xs"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Box>
          <Typography fontWeight={500} mb={1}>
            {t("creditLimit")}{" "}
            <span style={{ color: COLOR.error.base }}>*</span>:
          </Typography>
          <CurrencyInput
            customInput={TextField}
            allowDecimals={false}
            allowNegativeValue={false}
            fullWidth
            value={creditLimit}
            onChange={(e) => setCreditLimit(e.target.value)}
            onBlur={() => validateRequired() && validateNumber()}
            error={!!creditLimitError}
            helperText={creditLimitError}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("close")}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={handleUpdate}
        >
          {t("confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCreditLimitDialog;
