import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DeleteOutline, EditNote } from "@mui/icons-material";
import Table from "../../components/Table";
import { STORE_TYPE_LABEL } from "../../constants/store";
import { COLOR } from "../../styles/color";
import ImagePreview from "../../components/ImagePreview";

const StoreList = ({
  loading,
  paging,
  handleChangePage,
  stores,
  distributors,
  handleOpenDialog,
  handleOpenPopup,
}) => {
  const { t } = useTranslation();

  const renderDistributor = (store) => {
    const { saleId } = store;
    const distributor = distributors.find((d) => d.staffIds.includes(saleId));
    if (distributor)
      return `${distributor.metadata?.name} - ${distributor.phoneNumber}`;

    return "-";
  };

  const renderSellingProducts = (store) => {
    if (!store.sellingProducts || store.sellingProducts.length === 0) {
      return "-";
    }

    return store.sellingProducts.map((product) => product.name).join(", ");
  };

  const columns = [
    {
      title: t("storeName"),
      align: "left",
      field: "name",
      sortable: true,
    },
    {
      title: t("image"),
      align: "center",
      field: "image",
      render: (row) => (
        <ImagePreview
          galleryID="custom-image-gallery-12345"
          images={
            Array.isArray(row.logo)
              ? row.logo.map((url) => ({ url }))
              : [{ url: row.logo }]
          }
        />
      ),
    },
    {
      title: t("region"),
      align: "left",
      render: (row) => `${t("region")} ${row.region}`,
      field: "region",
      sortable: true,
    },
    {
      title: t("address"),
      align: "left",
      render: (row) =>
        `${row.address}, ${row.ward}, ${row.district}, ${row.province}`,
      field: "addressDetail",
      sortable: true,
    },
    {
      title: t("sale"),
      align: "left",
      render: (row) =>
        `${row?.salePerson[0]?.name} - ${row?.salePerson[0]?.phoneNumber}`,
    },
    {
      title: t("distributor"),
      align: "left",
      render: (row) => renderDistributor(row),
    },
    {
      title: t("contactPhoneNumber"),
      align: "left",
      field: "contactPhoneNumber",
    },
    {
      title: t("storeStatus"),
      align: "left",
      render: (row) => t(row.status?.toLowerCase()),
    },
    {
      title: t("storeRoutes"),
      align: "left",
      render: (row) => t(row.routes?.map((item) => item.name)?.join(", ")),
    },
    {
      title: t("channelType"),
      align: "left",
      render: (row) => t(row.channelType?.toLowerCase()),
    },
    {
      title: t("storeType"),
      align: "left",
      render: (row) => t(STORE_TYPE_LABEL[row.type]),
    },
    {
      title: t("sellingProducts"),
      align: "left",
      render: (row) => renderSellingProducts(row),
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => (
        <>
          {row.isVerified ? (
            <Typography sx={{ color: COLOR.success.base }}>
              {t("verified")}
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.error.base }}>
              {t("unverified")}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  onClick={() => {
                    handleOpenDialog(row);
                  }}
                  color="primary"
                >
                  <EditNote />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("delete")}>
              <span>
                <IconButton
                  onClick={() => {
                    handleOpenPopup(row);
                  }}
                  color="error"
                >
                  <DeleteOutline />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={stores}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default StoreList;
