import { useState } from "react";
import {
  Button,
  Box,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

import apis from "../apis";
import Dialog from "./Dialog";
import { WALLET_TYPE } from "../constants/wallet";

const Input = styled("input")({
  display: "none",
});

const UploadGroupTransactionDialog = ({
  open,
  handleClose,
  walletType,
  reload,
}) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);

  const getTemplateFile = () => {
    switch (walletType) {
      case WALLET_TYPE.BONUS:
        return "https://velafoods.sgp1.digitaloceanspaces.com/velafoods/2024/12/09/07e296dc-8b1e-4460-8147-3db85f88449b.xlsx";
      case WALLET_TYPE.FREIGHT_COMPENSATION:
        return "https://velafoods.sgp1.digitaloceanspaces.com/velafoods/2024/12/09/215a47b0-a86a-4218-80b1-46503f430ca4.xlsx";

      default:
        return "";
    }
  };

  console.log("getTemplateFile", getTemplateFile());

  const createGroupTransaction = (file) => {
    switch (walletType) {
      case WALLET_TYPE.BONUS:
        return apis.groupTransaction.createBonusGroupTransaction(file);
      case WALLET_TYPE.FREIGHT_COMPENSATION:
        return apis.groupTransaction.createFCGroupTransaction(file);
      default:
        return;
    }
  };

  const handleUpload = async (event) => {
    if (event.target.files[0]) {
      setUploading(true);
      try {
        await createGroupTransaction(event.target.files[0]);
        handleClose();
        reload();
        toast.success(t("uploadTransactionSuccess"));
      } catch (error) {
        toast.error(t(error.message));
      }
      setUploading(false);
    }
  };
  return (
    <Dialog
      title={t("uploadTransaction")}
      maxWidth="xs"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Box textAlign={"center"} mb={2}>
          <label htmlFor="contained-button-file">
            <Input
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              id="contained-button-file"
              type="file"
              onChange={(event) => handleUpload(event)}
            />
            <LoadingButton
              fullWidth
              component="span"
              variant="outlined"
              loading={uploading}
            >
              {t("chooseFile")}
            </LoadingButton>
            {/* <Button fullWidth variant="outlined" component="span">
              {t("chooseFile")}
            </Button> */}
          </label>
        </Box>
        <Typography fontSize="16px" align="center">
          {t("download")}{" "}
          <Link
            color="primary"
            href={getTemplateFile()}
            target="_blank"
            rel="noopener"
          >
            {t("templateFile")}
          </Link>
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadGroupTransactionDialog;
