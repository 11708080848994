import { Add, Search } from "@mui/icons-material";
import { Button, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import Popup from "../../components/Popup";
import { DIALOG_TYPE, INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import BannerDialog from "./BannerDialog";
import BannerList from "./BannerList";
import { BANNER_STATUS } from "../../constants/banner";
import { isActiveBanner } from "../../services/banner";
import debounce from "../../utils/debounce";

const INIT_BANNER = {
  name: "",
  code: "",
  status: BANNER_STATUS.ACTIVE,
  condition: {},
  value: {},
  appliedObject: {},
};

const INIT_FILTER = {
  search: "",
};

const Banner = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [banners, setBanners] = useState([]);
  const [banner, setBanner] = useState(INIT_BANNER);
  const [openBannerDialog, setOpenBannerDialog] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dialogType, setDialogType] = useState();
  const [provinces, setProvinces] = useState([]);
  const [regions, setRegions] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [openDeleteBannerPopup, setOpenDeleteBannerPopup] = useState(false);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleOpenUserDialog = (dialogType, currBanner = INIT_BANNER) => {
    setDialogType(dialogType);
    setBanner(currBanner);
    setOpenBannerDialog(true);
  };

  const handleCloseBannerDialog = () => {
    setOpenBannerDialog(false);
    setBanner(INIT_BANNER);
  };

  const handleOpenDeleteBannerPopup = (currBanner) => {
    setBanner(currBanner);
    setOpenDeleteBannerPopup(true);
  };

  const handleCloseDeleteBannerPopup = () => {
    setOpenDeleteBannerPopup(false);
  };

  const handleReload = () => {
    setReload((prev) => !prev);
  };

  const fetchBanners = async ({ page, search }) => {
    setLoading(true);
    try {
      const condition = {
        page: page || paging.page,
        limit: paging.limit,
        search: search || undefined,
      };
      const { result } = await apis.banner.getBanners(condition);
      setBanners(result.banners);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchProvinces = async () => {
    try {
      const res = await apis.address.getVnUnits();
      const { result = [] } = res;
      setProvinces(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchRegions = async () => {
    try {
      const res = await apis.region.getRegions();
      const { result = [] } = res;
      setRegions(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchDistributors = async () => {
    try {
      const res = await apis.user.getUsers({
        pageType: PAGE_TYPE.DISTRIBUTOR,
        sort: ["code_asc"],
      });
      const { result = [] } = res;
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchStores = async () => {
    try {
      const res = await apis.store.getStores();
      const { result = [] } = res;
      setStores(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchProducts = async () => {
    try {
      const res = await apis.product.getProducts();
      const { result = [] } = res;
      setProducts(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const handleDeleteBanner = async () => {
    try {
      const res = await apis.banner.updateBanner(banner.id, {
        status:
          banner.status === BANNER_STATUS.ACTIVE
            ? BANNER_STATUS.INACTIVE
            : BANNER_STATUS.ACTIVE,
      });
      if (!res) throw new Error();
      toast.success(t("updateBannerSuccess"));
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setBanner(INIT_BANNER);
  };

  useEffect(() => {
    fetchProvinces();
    fetchRegions();
    fetchDistributors();
    fetchStores();
    fetchProducts();
  }, []);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { page = INITIAL_PAGING.page } = searchParams;
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchBanners({ page: parseInt(page, 10), search: filter.search });
  }, [location.search, reload]);

  return (
    <>
      <PageTitle title={t("bannerManagement")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("bannerSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}></Grid>
          <Grid item xs={12} sm={3} md={3} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleOpenUserDialog(DIALOG_TYPE.CREATE)}
              >
                {t("createBanner")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <BannerList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          banners={banners}
          handleOpenBannerDialog={handleOpenUserDialog}
          handleOpenActivateBannerPopup={handleOpenDeleteBannerPopup}
        />
      </MainCard>
      <BannerDialog
        open={openBannerDialog}
        onClose={handleCloseBannerDialog}
        initBanner={banner}
        handleReload={handleReload}
        provinces={provinces}
        regions={regions}
        distributors={distributors.filter(
          (distributor) => distributor.active && !distributor.stopCooperatedAt
        )}
        stores={stores}
        products={products}
        dialogType={dialogType}
      />
      <Popup
        open={openDeleteBannerPopup}
        onClose={handleCloseDeleteBannerPopup}
        onOk={handleDeleteBanner}
        okMessage={t("accept")}
        content={t(
          `${
            isActiveBanner(banner)
              ? "areYouSureDeactivateBanner"
              : "areYouSureActivateBanner"
          }`,
          {
            banner: banner.name,
          }
        )}
        title={t(`${isActiveBanner(banner) ? "deactivate" : "activate"}Banner`)}
      />
    </>
  );
};

export default Banner;
