import React, { useState, useEffect, cloneElement } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { CircularProgress, Typography } from "@mui/material";

import apis from "../../apis";
import tabs from "./config";
import useSearchParams from "../../hooks/useSearchParams";
import ROUTE from "../../constants/route";
import { toast } from "react-toastify";
import { MINI_APP_ROLE_KEY } from "../../constants";
import PageTitle from "../../components/PageTitle";

const INIT_TAB = tabs[0].value;

const DistributorDetail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { distributorId } = useParams();

  const [value, setValue] = useState(INIT_TAB);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [distributor, setDistributor] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([
    { title: t("distributor"), path: ROUTE.DISTRIBUTOR },
  ]);

  const { addParams } = useSearchParams();

  const handleChange = (event, newValue) => {
    addParams({ tab: newValue });
  };

  const getDistributor = async () => {
    try {
      const { result } = await apis.user.getUser(distributorId);
      if (result.role.key !== MINI_APP_ROLE_KEY.DISTRIBUTOR)
        throw new Error("distributorNotfound");
      setDistributor(result);
      setBreadcrumbs((prev) => [
        prev[0],
        { title: result.metadata?.name || result.name },
      ]);
    } catch (error) {
      setError(true);
      setBreadcrumbs([{ title: t("back"), path: ROUTE.DISTRIBUTOR }]);
      toast.error(t(error.message));
    }
  };

  const fetchDistributor = async () => {
    setLoading(true);
    await getDistributor();
    setLoading(false);
  };

  useEffect(() => {
    fetchDistributor();
  }, []);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { tab = INIT_TAB } = searchParams;
    if (!tabs.some((t) => t.value === tab)) {
      history.push(ROUTE.DISTRIBUTOR);
      return;
    }
    setValue(tab);
  }, [location.search]);

  const renderContent = () => (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabs.map((tab) => (
              <Tab label={t(tab.name)} value={tab.value} key={tab.value} />
            ))}
          </TabList>
        </Box>
        {tabs.map((tab) => (
          <TabPanel
            key={tab.value}
            value={tab.value}
            sx={{ paddingY: 2, paddingX: 0 }}
          >
            {cloneElement(tab.component, {
              distributor,
              reload: getDistributor,
            })}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Box padding={2}>
          <Typography variant="body1" textAlign="center">
            {t("distributorNotfound")} !
          </Typography>
        </Box>
      ) : (
        renderContent()
      )}
    </>
  );
};

export default DistributorDetail;
