import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";

const DateInput = ({
  name,
  value,
  onChangeValue,
  inputFormat = "DD/MM/YYYY",
  minDate = null,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        name={name}
        inputFormat={inputFormat}
        value={value}
        onChange={onChangeValue}
        renderInput={(params) => <TextField size="small" {...params} />}
        fullWidth
        minDate={minDate}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
