import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  PermIdentityOutlined,
  EmailOutlined,
  LocalPhoneOutlined,
  ContactEmergency,
  LocationOn,
  Edit,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import MainCard from "../../../components/MainCard";
import { COLOR } from "../../../styles/color";
import UpdateDistributorDialog from "./UpdateDistributorDialog";

const DistributorInfo = ({ distributor, reload }) => {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Box mb={3}>
        <Grid container spacing={{ xs: 3, sm: 3, md: 3 }}>
          <Grid item xs={12} sm={12} md={4}>
            <MainCard sx={{ height: "100%" }}>
              <Box p={1}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  mb={3}
                >
                  <Avatar
                    alt={distributor.name}
                    src={distributor.avatar}
                    sx={{ width: 100, height: 100 }}
                  />
                  <Box>
                    <Typography fontWeight={600} fontSize="20px" align="center">
                      {distributor.metadata?.name || distributor.name}
                    </Typography>
                    <Typography align="center">{t("distributor")}</Typography>
                  </Box>
                </Stack>
                <Box mb={3}>
                  <Divider />
                </Box>

                <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
                  <PermIdentityOutlined color="disabled" fontSize="small" />
                  <Typography fontWeight={500}>
                    {distributor.metadata?.name || distributor.name}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
                  <EmailOutlined color="disabled" fontSize="small" />
                  <Typography fontWeight={500}>
                    {distributor.metadata?.email || "-"}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
                  <LocalPhoneOutlined color="disabled" fontSize="small" />
                  <Typography fontWeight={500}>
                    {distributor.phoneNumber || "-"}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
                  <ContactEmergency color="disabled" fontSize="small" />
                  <Typography fontWeight={500}>
                    {distributor.metadata?.citizenIdentification || "-"}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <LocationOn color="disabled" fontSize="small" />
                  <Typography fontWeight={500}>
                    {[
                      distributor.metadata?.address?.addressDetail,
                      distributor.metadata?.address?.ward,
                      distributor.metadata?.address?.district,
                      distributor.metadata?.address?.province,
                    ]
                      .filter((item) => item)
                      .join(", ")}
                  </Typography>
                </Stack>
              </Box>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <MainCard title={t("info")} sx={{ height: "100%" }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Typography color={COLOR.black[64]} fontSize="14px">
                          {t("distributorCode")}
                        </Typography>
                        <Typography fontWeight={500}>
                          {distributor.code || "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography color={COLOR.black[64]} fontSize="14px">
                          {t("cooperatedAt")}
                        </Typography>
                        <Typography fontWeight={500}>
                          {distributor.metadata?.cooperatedAt
                            ? dayjs(distributor.metadata?.cooperatedAt).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography color={COLOR.black[64]} fontSize="14px">
                          {t("region")}
                        </Typography>
                        <Typography fontWeight={500}>
                          {t("region")} {distributor.region}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography color={COLOR.black[64]} fontSize="14px">
                          {t("supervisor")}
                        </Typography>
                        <Typography fontWeight={500}>
                          {distributor.supervisor?.metadata?.name ||
                            distributor.supervisor?.name ||
                            "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography color={COLOR.black[64]} fontSize="14px">
                          {t("businessProvince")}
                        </Typography>
                        <Typography fontWeight={500}>
                          {distributor.province || "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </MainCard>
          </Grid>
        </Grid>
      </Box>
      <Stack justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          startIcon={<Edit />}
          onClick={handleOpenDialog}
        >
          {t("edit")}
        </Button>
      </Stack>
      <UpdateDistributorDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        reload={reload}
        distributor={{
          id: distributor.id,
          region: distributor.region,
          name: distributor.metadata?.name || distributor.name,
          email: distributor.metadata?.email,
          phoneNumber:
            distributor.metadata?.phoneNumber || distributor.phoneNumber,
          province: distributor.metadata?.address?.province,
          district: distributor.metadata?.address?.district,
          ward: distributor.metadata?.address?.ward,
          addressDetail: distributor.metadata?.address?.addressDetail,
          citizenIdentification: distributor.metadata?.citizenIdentification,
          cooperatedAt: distributor.metadata?.cooperatedAt
            ? dayjs(distributor.metadata?.cooperatedAt)
            : null,
          code: distributor.code,
          businessProvince: distributor.province,
          supervisorId: distributor.supervisorId,
        }}
      />
    </>
  );
};

export default DistributorInfo;
