import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import apis from "../../../apis";
import Dialog from "../../../components/Dialog";
import { COLOR } from "../../../styles/color";
import {
  validateRequired as checkRequired,
  validatePhoneNumber as checkPhoneNumber,
  validateEmail as checkEmail,
  validateDate as checkDate,
  validateNumeric as checkNumeric,
} from "../../../utils/validate";
import { PAGE_TYPE, ROLE_TYPE } from "../../../constants";

const INIT_ERROR = {
  region: "",
  name: "",
  email: "",
  phoneNumber: "",
  province: "",
  district: "",
  ward: "",
  addressDetail: "",
  citizenIdentification: "",
  cooperatedAt: "",
  code: "",
  businessProvince: "",
};

const UpdateDistributorDialog = ({
  open,
  handleClose,
  distributor,
  reload,
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [error, setError] = useState(INIT_ERROR);
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [update, setUpdate] = useState(false);

  const handleCloseDialog = () => {
    handleClose();
    setData({});
    setError(INIT_ERROR);
    setRegions([]);
    setSupervisors([]);
    setProvinces([]);
    setDistricts([]);
    setWards([]);
  };

  const renderSupervisorOptions = (id) => {
    const supervisor = supervisors.find((d) => d.id === id);
    if (!supervisor) return "";
    return `${supervisor.name} - ${supervisor.phoneNumber}`;
  };

  const handleChangeRegion = (newValue) => {
    setData((prev) => ({
      ...prev,
      region: newValue,
      supervisorId: "",
      businessProvince: "",
      province: "",
      district: "",
      ward: "",
    }));
    setError((prev) => ({ ...prev, region: "" }));
    if (!newValue) {
      setSupervisors([]);
      setProvinces([]);
      setDistricts([]);
      setWards([]);
    }
  };

  const handleChangeSupervisorId = (newValue) => {
    setData((prev) => ({ ...prev, supervisorId: newValue }));
  };

  const handleChangeBusinessProvince = (newValue) => {
    setData((prev) => ({
      ...prev,
      businessProvince: newValue,
    }));
    setError((prev) => ({ ...prev, businessProvince: "" }));
  };

  const handleChangeProvince = (newValue) => {
    setData((prev) => ({
      ...prev,
      province: newValue,
      district: "",
      ward: "",
    }));
    setWards([]);
    setError((prev) => ({ ...prev, province: "" }));
    if (!newValue) setDistricts([]);
  };

  const handleChangeDistrict = (newValue) => {
    setData((prev) => ({ ...prev, district: newValue, ward: "" }));
    setError((prev) => ({ ...prev, district: "" }));
    if (!newValue) setWards([]);
  };

  const handleChangeWard = (newValue) => {
    setData((prev) => ({ ...prev, ward: newValue }));
    setError((prev) => ({ ...prev, ward: "" }));
  };

  const handleChange = (event) => {
    const { name: field, value } = event.target;
    setData((prev) => ({ ...prev, [field]: value }));
    setError((prev) => ({ ...prev, [field]: "" }));
  };

  const handleChangeDate = (newValue) => {
    setData((prev) => ({ ...prev, cooperatedAt: newValue }));
    setError((prev) => ({ ...prev, cooperatedAt: false }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(data[field])) {
      setError((prev) => ({
        ...prev,
        [field]: t("fieldRequired"),
      }));
      return false;
    }
    return true;
  };

  const validateEmail = (field) => {
    if (data[field] && !checkEmail(data[field])) {
      setError((prev) => ({
        ...prev,
        [field]: t("emailInvalid"),
      }));
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (field) => {
    if (data[field] && !checkPhoneNumber(data[field])) {
      setError((prev) => ({
        ...prev,
        [field]: t("phoneNumberInvalid"),
      }));
      return false;
    }
    return true;
  };

  const validateDate = (field) => {
    if (data[field] && !checkDate(data[field], "DD/MM/YYYY")) {
      setError((prev) => ({
        ...prev,
        [field]: t("dateInvalid"),
      }));
      return false;
    }

    return true;
  };

  const validateNumeric = (field, length) => {
    if (data[field] && !checkNumeric(data[field], length)) {
      setError((prev) => ({
        ...prev,
        [field]: t("dataInvalid"),
      }));
      return false;
    }

    return true;
  };

  const validate = () =>
    validateRequired("name") &&
    validateNumeric("code") &&
    validateEmail("email") &&
    validateRequired("phoneNumber") &&
    validatePhoneNumber("phoneNumber") &&
    validateRequired("citizenIdentification") &&
    validateNumeric("citizenIdentification", 12) &&
    validateRequired("cooperatedAt") &&
    validateDate("cooperatedAt") &&
    validateRequired("region") &&
    validateRequired("businessProvince") &&
    validateRequired("province") &&
    validateRequired("district") &&
    validateRequired("ward") &&
    validateRequired("addressDetail");

  const handleUpdate = async () => {
    if (!validate()) return;
    setUpdate(true);
    try {
      const {
        region,
        supervisorId,
        name,
        email,
        phoneNumber,
        businessProvince,
        province,
        district,
        ward,
        addressDetail,
        citizenIdentification,
        cooperatedAt,
        code,
      } = data;
      await apis.user.updateUser(distributor.id, {
        roleType: ROLE_TYPE.MINI_APP,
        region,
        supervisorId: supervisorId || "",
        code,
        province: businessProvince || province,
        metadata: {
          name,
          email,
          phoneNumber,
          address: {
            province,
            district,
            ward,
            addressDetail,
          },
          citizenIdentification,
          cooperatedAt: dayjs(cooperatedAt).startOf("day"),
        },
      });
      toast.success(t("updateDistributorSuccess"));
      reload();
      handleCloseDialog();
    } catch (error) {
      toast.error(t(error.message));
    }
    setUpdate(false);
  };

  const getRegions = async () => {
    setLoading(true);
    try {
      const res = await apis.region.getRegions();
      setRegions(res.result);
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const getProvinces = async () => {
    try {
      const res = await apis.address.getVnUnits(data.region);
      setProvinces(res.result);
      if (data.province) getDistricts(res.result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const getDistricts = (defaultProvinces) => {
    let prs = provinces;
    if (defaultProvinces) prs = defaultProvinces;
    const province = prs.find((p) => p.name === data.province);
    setDistricts(province?.districts || []);
    if (data.district) getWards(province?.districts || []);
  };

  const getWards = (defaultDistricts) => {
    let ds = districts;
    if (defaultDistricts) ds = defaultDistricts;
    const district = ds.find((d) => d.name === data.district);
    setWards(district?.wards || []);
  };

  const getSupervisors = async () => {
    try {
      const res = await apis.user.getUsers({
        pageType: PAGE_TYPE.SUPERVISOR,
        region: data.region,
        active: true,
      });
      setSupervisors(res.result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  useEffect(() => {
    if (data.region) {
      getSupervisors();
      getProvinces();
    }
  }, [data.region]);

  useEffect(() => {
    if (data.province) getDistricts();
  }, [data.province]);

  useEffect(() => {
    if (data.district) getWards();
  }, [data.district]);

  useEffect(() => {
    if (open) {
      setData(distributor);
      getRegions();
    }
  }, [open, distributor]);

  return (
    <Dialog
      title={t("updateDistributor")}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {loading ? (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="400px"
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container spacing={2} mb={3}>
            <Grid item xs={12} sm={8}>
              <Typography fontWeight={500} mb={1}>
                {t("fullName")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                value={data.name}
                size="small"
                fullWidth
                name="name"
                onChange={handleChange}
                onBlur={() => validateRequired("name")}
                error={!!error.name}
                helperText={error.name}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("distributorCode")} :
              </Typography>
              <TextField
                value={data.code}
                size="small"
                fullWidth
                name="code"
                onChange={handleChange}
                onBlur={() => validateNumeric("code")}
                error={!!error.code}
                helperText={error.code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("email")}:
              </Typography>
              <TextField
                value={data.email}
                size="small"
                fullWidth
                name="email"
                onChange={handleChange}
                onBlur={() => validateEmail("email")}
                error={!!error.email}
                helperText={error.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("phoneNumber")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                value={data.phoneNumber}
                size="small"
                fullWidth
                name="phoneNumber"
                onChange={handleChange}
                onBlur={() =>
                  validateRequired("phoneNumber") &&
                  validatePhoneNumber("phoneNumber")
                }
                error={!!error.phoneNumber}
                helperText={error.phoneNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("citizenIdentification")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                value={data.citizenIdentification}
                size="small"
                fullWidth
                name="citizenIdentification"
                onChange={handleChange}
                onBlur={() =>
                  validateRequired("citizenIdentification") &&
                  validateNumeric("citizenIdentification", 12)
                }
                error={!!error.citizenIdentification}
                helperText={error.citizenIdentification}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("cooperatedAt")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="cooperatedAt"
                  inputFormat="DD/MM/YYYY"
                  value={data.cooperatedAt}
                  onChange={(newValue) => handleChangeDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      {...params}
                      onBlur={() =>
                        validateRequired("cooperatedAt") &&
                        validateDate("cooperatedAt")
                      }
                      error={!!error.cooperatedAt}
                      helperText={error.cooperatedAt}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("chooseRegion")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <Autocomplete
                size="small"
                value={data.region || null}
                options={regions.map((region) => region.name)}
                getOptionLabel={(option) => `${t("region")} ${option}`}
                filterSelectedOptions
                onChange={(event, newValue) => handleChangeRegion(newValue)}
                onBlur={() => validateRequired("region")}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {`${t("region")} ${key}`}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error.region}
                    helperText={error.region}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("businessProvince")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <Autocomplete
                size="small"
                value={data.businessProvince || null}
                options={provinces.map((province) => province.name)}
                filterSelectedOptions
                onChange={(event, newValue) =>
                  handleChangeBusinessProvince(newValue)
                }
                onBlur={() => validateRequired("businessProvince")}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {key}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error.businessProvince}
                    helperText={error.businessProvince}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("chooseSupervisor")}:
              </Typography>
              <Autocomplete
                size="small"
                value={data.supervisorId || null}
                options={supervisors
                  ?.filter(
                    (supervisor) =>
                      !supervisor.province ||
                      supervisor.province === data.businessProvince
                  )
                  .map((supervisor) => supervisor.id)}
                getOptionLabel={(option) => renderSupervisorOptions(option)}
                filterSelectedOptions
                onChange={(event, newValue) =>
                  handleChangeSupervisorId(newValue)
                }
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {renderSupervisorOptions(key)}
                  </MenuItem>
                )}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("province")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <Autocomplete
                size="small"
                value={data.province || null}
                options={provinces.map((province) => province.name)}
                filterSelectedOptions
                onChange={(event, newValue) => handleChangeProvince(newValue)}
                onBlur={() => validateRequired("province")}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {key}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error.province}
                    helperText={error.province}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("district")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <Autocomplete
                size="small"
                value={data.district || null}
                options={districts.map((district) => district.name)}
                filterSelectedOptions
                onChange={(event, newValue) => handleChangeDistrict(newValue)}
                onBlur={() => validateRequired("district")}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {key}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error.district}
                    helperText={error.district}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("ward")} <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <Autocomplete
                size="small"
                value={data.ward || null}
                options={wards.map((ward) => ward.name)}
                filterSelectedOptions
                onChange={(event, newValue) => handleChangeWard(newValue)}
                onBlur={() => validateRequired("ward")}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {key}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error.ward}
                    helperText={error.ward}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} mb={1}>
                {t("addressDetail")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                value={data.addressDetail || ""}
                size="small"
                name="addressDetail"
                fullWidth
                onChange={handleChange}
                onBlur={() => validateRequired("addressDetail")}
                multiline={true}
                rows={2}
                error={!!error.addressDetail}
                helperText={error.addressDetail}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("close")}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={update}
          onClick={handleUpdate}
        >
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDistributorDialog;
