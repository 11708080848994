import { SERVICE } from "../constants";
import api from "./api";

const getCategories = async (params) => {
  const res = await api({
    method: "GET",
    url: `/categories`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

const createCategory = async (data) => {
  const res = await api({
    method: "POST",
    url: `/categories`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const updateCategory = async (categoryId, data) => {
  const res = await api({
    method: "PUT",
    url: `/categories/${categoryId}`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const changeStatusCategory = async (categoryId, active) => {
  const res = await api({
    method: "PUT",
    url: `/categories/${categoryId}/change-status`,
    data: { active },
    source: SERVICE.VELA_API,
  });
  return res;
};

export { getCategories, createCategory, updateCategory, changeStatusCategory };
