import { SERVICE } from "../constants";
import api from "./api";

const getStatements = async (params) => {
  const response = await api({
    method: "GET",
    url: `/statements`,
    params,
    source: SERVICE.VELA_API,
  });

  return response;
};

export { getStatements };
