import React, { useEffect } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import "../index.css";

export default function ImagePreview(props) {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#" + props.galleryID,
      children: "a",
      pswpModule: () => import("photoswipe"),
      preloadFirstSlide: false,
      padding: { top: 200, bottom: 200 },
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, [props.galleryID]);

  return (
    <div className="pswp-gallery" id={props.galleryID}>
      {props.images.map((image, index, currentImages) => (
        <a
          href={image.url}
          data-pswp-width={props.previewWidth || 1000}
          data-pswp-height={props.previewHeight || 1000}
          key={props.galleryID + "-" + index}
          target="_blank"
          rel="noreferrer"
          style={{ marginRight: currentImages.length ? "10px" : 0 }}
        >
          <img
            src={image.url}
            style={{ height: props.height || "60px", objectFit: "contain" }}
            alt=""
          />
        </a>
      ))}
    </div>
  );
}