import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { Box, ClickAwayListener, IconButton, Typography } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

import {
  BOX_SHADOW,
  FULL_SIDEBAR_WIDTH,
  MINI_SIDEBAR_WIDTH,
} from "../../styles/config";
import { COLOR } from "../../styles/color";
import ROUTE from "../../constants/route";

const stylesMenuItem = {
  height: 30,
  padding: 1.5,
  display: "flex",
  alignItems: "center",
  gap: 2,
  cursor: "pointer",
};

const isMatchPath = (location, path) => {
  return (
    (path === ROUTE.DASHBOARD &&
      matchPath(location.pathname, {
        path,
        exact: true,
      }) !== null) ||
    (path !== ROUTE.DASHBOARD &&
      matchPath(location.pathname, {
        path,
        exact: false,
      }) !== null)
  );
};

const SubItem = ({ item, onClickItem, isCollapse }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = isMatchPath(location, item.path);

  const handleItemClick = () => {
    onClickItem(item.path);
  };

  return (
    <Box
      sx={{
        color: isActive ? COLOR.blue[80] : COLOR.black[64],
        backgroundColor: isActive ? COLOR.darkBlue[8] : "transparent",
        "&:hover": {
          backgroundColor: isActive ? COLOR.darkBlue[8] : COLOR.darkBlue[4],
        },
        borderRight: isActive ? `2px solid ${COLOR.blue[100]}` : "none",
        ...stylesMenuItem,
      }}
      onClick={handleItemClick}
    >
      {!isCollapse && <Box width={48} />}
      <Typography marginRight="auto" fontSize="16px">
        {t(item.label)}
      </Typography>
    </Box>
  );
};

const SubMenu = ({ subMenu, onClickItem, isCollapse }) => (
  <>
    {subMenu.map((subItem) => (
      <SubItem
        key={subItem.label}
        item={subItem}
        onClickItem={onClickItem}
        isCollapse={isCollapse}
      />
    ))}
  </>
);

const Item = ({ item, isCollapse, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);

  const handleRedirect = (path) => {
    history.push(path);
    if (onClose) onClose();
  };

  const handleClick = (path) => {
    if (item.subMenu && item.subMenu.length > 0) {
      setIsShowSubMenu(!isShowSubMenu);
    } else {
      handleRedirect(path);
      setIsShowSubMenu(!isShowSubMenu);
    }
  };

  const handleClickAway = () => {
    setIsShowSubMenu(false);
  };

  const isActive =
    isMatchPath(location, item.path) ||
    item?.subMenu?.some((subItem) => isMatchPath(location, subItem.path));

  const handleChangeIsShowSubMenu = () => {
    if (isActive && !isCollapse) {
      setIsShowSubMenu(true);
      return;
    }
    if (!isActive) setIsShowSubMenu(false);
  };

  useEffect(() => {
    handleChangeIsShowSubMenu();
  }, [history.location.pathname, isCollapse]);

  const backgroundColor =
    isShowSubMenu && item.subMenu && item.subMenu.length > 0
      ? "transparent"
      : COLOR.pastelBlue[80];

  const stylesActive = {
    color: COLOR.blue[80],
    backgroundColor: isCollapse ? COLOR.pastelBlue[80] : backgroundColor,
    borderRight:
      isActive && !item.subMenu && !isCollapse
        ? `2px solid ${COLOR.blue[100]}`
        : "none",
  };
  const stylesHover = { "&:hover": { backgroundColor: COLOR.darkBlue[4] } };

  return (
    <>
      <Box
        color={COLOR.black[64]}
        sx={{
          ...(isActive && stylesActive),
          ...(!isActive && stylesHover),
          ...stylesMenuItem,
          justifyContent: isCollapse ? "center" : "none",
          padding: isCollapse ? 0 : 1.5,
          margin: isCollapse ? 1 : 0,
          borderRadius: isCollapse ? 1 : 0,
          height: isCollapse ? 52 : 30,
        }}
        onClick={() => handleClick(item.path)}
      >
        {item.icon}
        {!isCollapse && (
          <Typography marginRight="auto" fontSize="16px">
            {t(item.label)}
          </Typography>
        )}
        {item.subMenu && item.subMenu.length > 0 && !isCollapse && (
          <IconButton>
            {isShowSubMenu ? (
              <ArrowDropUp
                sx={{ color: isActive ? COLOR.blue[80] : COLOR.black[64] }}
              />
            ) : (
              <ArrowDropDown
                sx={{ color: isActive ? COLOR.blue[80] : COLOR.black[64] }}
              />
            )}
          </IconButton>
        )}
        {item.subMenu && isShowSubMenu && isCollapse && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box
              position="absolute"
              left={MINI_SIDEBAR_WIDTH + 4}
              width={FULL_SIDEBAR_WIDTH}
              zIndex={1}
              display="flex"
              flexDirection="column"
              padding={1}
              boxShadow={BOX_SHADOW}
              bgcolor={COLOR.white[100]}
            >
              <SubMenu
                subMenu={item.subMenu}
                onClickItem={handleRedirect}
                isCollapse={isCollapse}
              />
            </Box>
          </ClickAwayListener>
        )}
      </Box>
      {item.subMenu && isShowSubMenu && !isCollapse && (
        <SubMenu
          subMenu={item.subMenu}
          onClickItem={handleRedirect}
          isCollapse={isCollapse}
        />
      )}
    </>
  );
};

export default Item;
