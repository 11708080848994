import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import { DIALOG_TYPE } from "../../constants";
import { COLOR } from "../../styles/color";
import { validateRequired as checkRequired } from "../../utils/validate";

const INIT_PAYMENT_ACCOUNT_ERROR = {
  bankBin: "",
  bankNumber: "",
  bankHolder: "",
};

const PaymentAccountDialog = ({
  open,
  handleClose,
  dialogType,
  initPaymentAccount = {},
  banks = [],
  reload,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(INIT_PAYMENT_ACCOUNT_ERROR);
  const [paymentAccount, setPaymentAccount] = useState({});

  const renderBankOptions = (bin) => {
    const bank = banks.find((c) => c.bin === bin);
    if (!bank) return "";
    return bank.name;
  };

  const handleCloseDialog = () => {
    handleClose();
    setError(INIT_PAYMENT_ACCOUNT_ERROR);
    setPaymentAccount({});
  };

  const handleChangePaymentAccount = (event) => {
    const { name: field, value } = event.target;
    setPaymentAccount((prev) => ({ ...prev, [field]: value }));
    setError((prev) => ({ ...prev, [field]: "" }));
  };

  const handleChangePaymentAccountBank = (newValue) => {
    const bank = banks.find((c) => c.bin === newValue);
    setPaymentAccount((prev) => ({
      ...prev,
      bankBin: newValue,
      bankName: bank.name,
    }));
    setError((prev) => ({ ...prev, bankBin: "" }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(paymentAccount[field])) {
      setError((prev) => ({ ...prev, [field]: t("fieldRequired") }));
      return false;
    }
    return true;
  };

  const validateBankNumber = () => {
    const number = paymentAccount.bankNumber || "";
    if (!/^\d+$/.test(number)) {
      setError((prev) => ({
        ...prev,
        bankNumber: t("bankNumberInvalid"),
      }));
      return false;
    }
    return true;
  };

  const validate = () =>
    validateRequired("bankBin") &&
    validateRequired("bankNumber") &&
    validateRequired("bankHolder") &&
    validateBankNumber();

  const handleCreate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.paymentAccount.createPaymentAccount(paymentAccount);
      handleCloseDialog();
      reload();
      toast.success(t("addPaymentAccountSuccess"));
    } catch (error) {
      console.log("error", error);
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      const { id: paymentAccountId, ...data } = paymentAccount;

      await apis.paymentAccount.updatePaymentAccount(paymentAccountId, data);
      handleCloseDialog();
      reload();
      toast.success(t("editPaymentAccountSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) setPaymentAccount(initPaymentAccount);
  }, [open, initPaymentAccount]);

  return (
    <Dialog
      title={
        dialogType === DIALOG_TYPE.CREATE
          ? t("addPaymentAccount")
          : t("editPaymentAccount")
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(paymentAccount).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12}>
              <Typography fontWeight={500} mb={1}>
                {t("bank")} <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <Autocomplete
                size="small"
                value={paymentAccount.bankBin}
                options={banks.map((category) => category.bin)}
                getOptionLabel={(option) => renderBankOptions(option)}
                filterSelectedOptions
                onChange={(event, newValue) =>
                  handleChangePaymentAccountBank(newValue)
                }
                onBlur={() => validateRequired("bankBin")}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {renderBankOptions(key)}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error.bankBin}
                    helperText={error.bankBin}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} mb={1}>
                {t("bankNumber")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={error.bankNumber}
                error={!!error.bankNumber}
                value={paymentAccount.bankNumber}
                size="small"
                name="bankNumber"
                fullWidth
                onChange={handleChangePaymentAccount}
                onBlur={() => validateRequired("bankNumber")}
                placeholder={t("bankNumber")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} mb={1}>
                {t("bankHolder")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={error.bankHolder}
                error={!!error.bankHolder}
                value={paymentAccount.bankHolder}
                size="small"
                name="bankHolder"
                fullWidth
                onChange={handleChangePaymentAccount}
                onBlur={() => validateRequired("bankHolder")}
                placeholder={t("bankHolder")}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {dialogType === DIALOG_TYPE.CREATE ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreate}
          >
            {t("addNew")}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdate}
          >
            {t("save")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PaymentAccountDialog;
