import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Table from "../../components/Table";
import { WALLET_TYPE } from "../../constants/wallet";
import { formatNumber } from "../../utils/number";

const TransactionList = ({
  loading,
  paging,
  handleChangePage,
  statements,
  distributors,
}) => {
  const { t } = useTranslation();

  const renderWallet = (walletType) => {
    switch (walletType) {
      case WALLET_TYPE.PAYMENT:
        return t("paymentWallet");
      case WALLET_TYPE.COMMODITY_COMPENSATION:
        return t("commodityCompensationWallet");
      case WALLET_TYPE.FREIGHT_COMPENSATION:
        return t("freightCompensationWallet");
      case WALLET_TYPE.BONUS:
        return t("bonusWallet");

      default:
        return "";
    }
  };

  const renderDistributor = (distributorId) => {
    const distributor = distributors.find((d) => d.id === distributorId);
    if (distributor) return `${distributor.metadata?.name || distributor.name}`;
    return "-";
  };

  const columns = [
    {
      title: t("walletType"),
      align: "left",
      render: (row) => renderWallet(row.walletType),
    },
    {
      title: t("distributor"),
      align: "left",
      render: (row) => renderDistributor(row.userId),
    },
    {
      title: t("closingBalance"),
      align: "left",
      render: (row) => formatNumber(row.amount),
    },
    {
      title: t("createdAt"),
      align: "left",
      width: "135px",
      render: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm"),
    },
  ];

  return (
    <Table
      data={statements}
      columns={columns}
      total={paging.total}
      page={paging.page}
      loading={loading}
      onChangePage={handleChangePage}
      showNumber={true}
    />
  );
};

export default TransactionList;
