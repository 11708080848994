import { SERVICE } from "../constants";
import api from "./api";

const getBonusRates = async (distributorId) => {
  const res = await api({
    method: "GET",
    url: `/bonus-rates`,
    params: { distributorId },
    source: SERVICE.VELA_API,
  });
  return res;
};

const updateBonusRates = async (data) => {
  const res = await api({
    method: "PUT",
    url: `/bonus-rates`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

export { getBonusRates, updateBonusRates };
