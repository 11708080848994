import { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Dialog from "../../../components/Dialog";
import apis from "../../../apis";
import { COLOR } from "../../../styles/color";
import { validateRequired as checkRequired } from "../../../utils/validate";
import CurrencyInput from "../../../components/CurrencyInput";

const UpdateCommodityCompensationBalanceDialog = ({
  open,
  handleClose,
  distributor,
  initBalance,
  reload,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balanceError, setBalanceError] = useState("");

  const handleCloseDialog = () => {
    handleClose();
    setBalance(0);
    setBalanceError("");
  };

  const validateRequired = () => {
    if (!checkRequired(balance)) {
      setBalanceError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validateNumber = () => {
    const value = +balance;
    if (value < 0) {
      setBalanceError(t("dataInvalid"));
      return false;
    }
    return true;
  };

  const validate = () => validateRequired() && validateNumber();

  const handleUpdate = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await apis.wallet.createCommodityCompensationTransaction({
        userId: distributor.id,
        amount: balance,
      });
      handleCloseDialog();
      reload();
      toast.success(t("updateCommodityCompensationBalanceSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    setBalance(initBalance || 0);
  }, [initBalance]);

  return (
    <Dialog
      title={t("updateCommodityCompensationBalance")}
      maxWidth="xs"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Box>
          <Typography fontWeight={500} mb={1}>
            {t("balance")} <span style={{ color: COLOR.error.base }}>*</span>:
          </Typography>
          <CurrencyInput
            customInput={TextField}
            allowDecimals={false}
            allowNegativeValue={false}
            fullWidth
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
            onBlur={() => validateRequired() && validateNumber()}
            error={!!balanceError}
            helperText={balanceError}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("close")}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={handleUpdate}
        >
          {t("confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCommodityCompensationBalanceDialog;
