import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Table from "../../components/Table";
import { formatNumber } from "../../utils/number";
import { useState } from "react";
import TimesheetDetailDialog from "./TimesheetDetailDialog";
import { Stack } from "@mui/material";

const TimesheetList = ({ loading, paging, handleChangePage, timesheets }) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [timesheet, setTimesheet] = useState({});

  const handleClickRow = (row) => {
    setTimesheet(row);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTimesheet({});
  };

  const columns = [
    {
      title: "",
      align: "center",
      render: (row) => (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Stack>
        </>
      ),
    },
    {
      title: t("date"),
      align: "left",
      render: (row) => dayjs(row.date).format("DD-MM-YYYY"),
    },
    {
      title: "",
      align: "center",
      render: (row) => (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Stack>
        </>
      ),
    },
    {
      title: `${t("traveledDistance")} (km)`,
      align: "left",
      render: (row) =>
        formatNumber(Math.round((row.distance / 1000) * 100) / 100),
    },
    {
      title: "",
      align: "center",
      render: (row) => (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Stack>
        </>
      ),
    },
    {
      title: "",
      align: "center",
      render: (row) => (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={timesheets}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
        onRowClick={handleClickRow}
      />
      {openDialog && (
        <TimesheetDetailDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          timesheet={timesheet}
        />
      )}
    </>
  );
};

export default TimesheetList;
