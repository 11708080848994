import { useTranslation } from "react-i18next";

import ImagePreview from "../../components/ImagePreview";
import Table from "../../components/Table";
import { getPaymentQrLink } from "../../services/paymentQr";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { DIALOG_TYPE } from "../../constants";
import { Delete, Edit } from "@mui/icons-material";
import { useState } from "react";
import Popup from "../../components/Popup";
import apis from "../../apis";
import { toast } from "react-toastify";

const PaymentAccountList = ({
  loading,
  paging,
  handleChangePage,
  handleOpenDialog,
  paymentAccounts,
  refetch,
}) => {
  const { t } = useTranslation();

  const [popup, setPopup] = useState(false);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState({});

  const handleOpenPopup = (row) => {
    setSelectedPaymentAccount(row);
    setPopup(true);
  };

  const handleClosePopup = () => {
    setSelectedPaymentAccount({});
    setPopup(false);
  };

  const handleDeletePaymentAccount = (id) => async () => {
    try {
      await apis.paymentAccount.deletePaymentAccount(id);
      toast.success(t("deletePaymentAccountSuccess"));
      refetch && refetch();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const columns = [
    {
      title: t("bankName"),
      field: "bankName",
    },
    {
      title: t("bankHolder"),
      field: "bankHolder",
    },
    {
      title: t("bankNumber"),
      field: "bankNumber",
    },
    {
      title: t("qr"),
      align: "center",
      render: (row) => (
        <ImagePreview
          galleryID="custom-image-gallery-12345"
          previewWidth={1000}
          previewHeight={1000}
          images={[
            {
              url: getPaymentQrLink(
                row.bankBin,
                row.bankHolder,
                row.bankNumber
              ),
            },
          ]}
        />
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  onClick={() => handleOpenDialog(DIALOG_TYPE.UPDATE, row)}
                  color="edit"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("delete")}>
              <span>
                <IconButton onClick={() => handleOpenPopup(row)} color="error">
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={paymentAccounts}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
      <Popup
        open={popup}
        onClose={handleClosePopup}
        onOk={handleDeletePaymentAccount(selectedPaymentAccount.id)}
        okMessage={t("delete")}
        content={t("areYouSureDeletePaymentAccount", {
          bankNumber: selectedPaymentAccount.bankNumber,
          bankName: selectedPaymentAccount.bankName,
        })}
        title={t("deletePaymentAccount")}
      />
    </>
  );
};

export default PaymentAccountList;
