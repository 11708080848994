import { EditNote } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import apis from "../../apis";
import Table from "../../components/Table";
import {
  COMPETITOR_REPORT_STATUS,
  COMPETITOR_REPORT_STATUS_LABEL,
} from "../../constants/storeAction";
import ImagePreview from "../../components/ImagePreview";

const CompetitorProductList = ({
  loading,
  paging,
  handleChangePage,
  data,
  users,
  stores,
  handleOpenDialog,
  reload,
}) => {
  const { t } = useTranslation();

  const renderUser = ({ userId }) => {
    const user = users.find((u) => u.id === userId);
    if (user) return `${user.metadata?.name || user.name}`;

    return "-";
  };

  const renderStore = ({ storeId }) => {
    const store = stores.find((s) => s.id === storeId);
    return store?.name || "-";
  };

  const handleSeenAction = async (row) => {
    try {
      if (row.detail.status === COMPETITOR_REPORT_STATUS.NEW) {
        await apis.storeAction.seenStoreAction(row.id);
        reload();
      }
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const columns = [
    {
      title: t("store"),
      align: "left",
      render: (row) => renderStore(row),
    },
    {
      title: t("createdBy"),
      align: "left",
      render: (row) => renderUser(row),
    },
    {
      title: t("productName"),
      align: "left",
      render: (row) => row.detail.productName,
    },
    {
      title: t("brandName"),
      align: "left",
      render: (row) => row.detail.brandName,
    },
    // {
    //   title: t("image"),
    //   align: "left",
    //   render: (row) => (
    //     <img
    //       src={row.detail?.productImages?.[0]}
    //       alt={row.detail?.productName}
    //       style={{ width: 100, height: 100 }}
    //     />
    //   ),
    // },
    {
      title: t("image"),
      align: "center",
      field: "image",
      render: (row) => (
        <ImagePreview
          galleryID="custom-image-gallery-12345"
          images={
            [
              ...(row.detail?.productImages || []),
              ...(row.detail?.displayProductImages || []),
            ]?.map((item) => ({ url: item })) || []
          }
        />
      ),
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => t(COMPETITOR_REPORT_STATUS_LABEL[row.detail?.status]),
    },
    {
      title: t("createdAt"),
      align: "left",
      render: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("review")}>
              <span>
                <IconButton
                  onClick={() => {
                    handleSeenAction(row);
                    handleOpenDialog(row);
                  }}
                  color="primary"
                >
                  <EditNote />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        total={paging.total}
        page={paging.page}
        limit={paging.limit}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default CompetitorProductList;
