import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Backdrop, CircularProgress } from "@mui/material";

import Layout from "../containers/Layout";
import appRoutes from "./appRoutes";
import ROUTE from "../constants/route";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import axiosClient from "../apis/api";
import apis from "../apis";
import { setCurrentUser } from "../stores/userSlice";
import { setCurrentRole } from "../stores/roleSlice";
import { PERMISSION_TYPE } from "../constants";

const PrivateApp = () => {
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const { isMasterRole, permissions = [] } = useSelector((state) => state.role);
  const privateRoutes = appRoutes.filter((route) => route.isPrivate);
  (() => {
    axiosClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  })();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const init = async () => {
    setLoading(true);
    await Promise.all([getMe()]);
    setLoading(false);
  };

  const getMe = async () => {
    try {
      const res = await apis.auth.getMe();
      const { role, ...user } = res.result;
      dispatch(setCurrentUser(user));
      dispatch(
        setCurrentRole({
          ...role,
          permissions: role.permissions
            .filter((permission) => permission.type === PERMISSION_TYPE.MENU)
            .map((permission) => permission.path),
          apiPermissions: role.permissions
            .filter((permission) => permission.type === PERMISSION_TYPE.API)
            .map((permission) => ({
              method: permission.method,
              path: permission.path,
            })),
        })
      );
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (loading) {
    return (
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#ffffff",
        }}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <Layout>
      <Switch>
        {privateRoutes
          .filter(
            (privateRoute) =>
              isMasterRole ||
              permissions.includes(privateRoute.path) ||
              [ROUTE.DASHBOARD, ROUTE.PROFILE].includes(privateRoute.path)
          )
          .map((privateRoute) => (
            <PrivateRoute
              path={privateRoute.path}
              component={privateRoute.component}
              exact
              key={privateRoute.path}
            />
          ))}
      </Switch>
    </Layout>
  );
};

const AppRouter = () => {
  const publicRoutes = appRoutes.filter((route) => !route.isPrivate);

  return (
    <BrowserRouter>
      <Switch>
        {publicRoutes.map((publicRoute) => (
          <PublicRoute
            exact
            path={publicRoute.path}
            component={publicRoute.component}
            restricted={publicRoute.restricted}
            key={publicRoute.path}
          />
        ))}

        <PrivateRoute component={PrivateApp} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
