import { SERVICE } from "../constants";
import api from "./api";

const getGroupTransactions = async (params) => {
  const response = await api({
    method: "GET",
    url: `/group-transactions`,
    params,
    source: SERVICE.VELA_API,
  });

  return response;
};

const getGroupTransaction = async (groupTransactionId) => {
  const response = await api({
    method: "GET",
    url: `/group-transactions/${groupTransactionId}`,
    source: SERVICE.VELA_API,
  });

  return response;
};

const reCalculate = async (groupTransactionId) => {
  const response = await api({
    method: "PUT",
    url: `/group-transactions/${groupTransactionId}/re-calculate`,
    source: SERVICE.VELA_API,
  });

  return response;
};

const createBonusGroupTransaction = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await api({
    method: "POST",
    url: `/group-transactions/bonus`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    source: SERVICE.VELA_API,
  });
  return res;
};

const createFCGroupTransaction = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await api({
    method: "POST",
    url: `/group-transactions/freight-compensation`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    source: SERVICE.VELA_API,
  });
  return res;
};

const deleteGroupTransaction = async (groupTransactionId) => {
  const res = await api({
    method: "DELETE",
    url: `/group-transactions/${groupTransactionId}`,
    source: SERVICE.VELA_API,
  });
  return res;
};

export {
  getGroupTransactions,
  getGroupTransaction,
  reCalculate,
  createBonusGroupTransaction,
  createFCGroupTransaction,
  deleteGroupTransaction,
};
