import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apis from "../../apis";
import Dialog from "../../components/Dialog";
import UploadFile from "../../components/UploadFile";
import { ALL, DIALOG_TYPE } from "../../constants";
import {
  APPLIED_OBJECT_MAPPING,
  APPLIED_OBJECT_TYPE,
} from "../../constants/discount";
import { COLOR } from "../../styles/color";

const INIT_BANNER_DATA = {
  name: "",
  appliedObjectType: "",
  appliedObjectValue: [],
};

const INIT_BANNER_DATA_ERROR = {
  name: "",
  appliedObjectType: "",
  appliedObjectValue: "",
};

const convertToBannerFormat = (bannerData) => {
  return {
    name: bannerData.name,
    appliedObject:
      bannerData.appliedObjectType === ALL
        ? {}
        : {
            type: bannerData.appliedObjectType,
            value: bannerData.appliedObjectValue?.map((item) => ({
              id: item.id,
              name: item.name,
            })),
          },
  };
};

const InputTitle = ({
  label,
  isRequired,
  fontSize,
  fontWeight = 500,
  mb = 1,
}) => (
  <Typography fontWeight={fontWeight} mb={mb} fontSize={fontSize}>
    {label}
    {isRequired && <span style={{ color: COLOR.error.base }}>*</span>}:
  </Typography>
);

const HelperText = ({ text }) => {
  if (!text) return null;
  return (
    <Typography color="error" fontSize={12}>
      {text}
    </Typography>
  );
};

const BannerDialog = ({
  open,
  onClose,
  initBanner,
  dialogType,
  handleReload,
  provinces = [],
  regions = [],
  distributors = [],
  stores = [],
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState(INIT_BANNER_DATA);
  const [error, setError] = useState(INIT_BANNER_DATA_ERROR);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const isCreateDialog = dialogType === DIALOG_TYPE.CREATE;
  const appliedOptions = {
    [APPLIED_OBJECT_TYPE.REGION]: regions,
    [APPLIED_OBJECT_TYPE.PROVINCE]: provinces,
    [APPLIED_OBJECT_TYPE.DISTRIBUTOR]: distributors,
    [APPLIED_OBJECT_TYPE.STORE]: stores,
  };

  const handleChangeBannerData = (value, field) => {
    setError((prev) => ({ ...prev, [field]: "" }));
    if (field === "appliedObjectType")
      setBannerData((prev) => ({
        ...prev,
        appliedObjectType: value,
        appliedObjectValue: [],
      }));
    else setBannerData((prev) => ({ ...prev, [field]: value }));
  };

  const validateBannerData = () => {
    const newError = { ...INIT_BANNER_DATA_ERROR };
    let isValid = true;
    Object.keys(bannerData).forEach((key) => {
      if (key === "appliedObjectType" && bannerData[key] === ALL) return;
      if (key === "appliedObjectValue" && bannerData.appliedObjectType === ALL)
        return;
      if (!bannerData[key] || bannerData[key]?.length === 0) {
        newError[key] = t("requiredField");
        isValid = false;
      }
    });
    setError(newError);
    return isValid;
  };

  const handleCloseDialog = () => {
    setBannerData(INIT_BANNER_DATA);
    setError(INIT_BANNER_DATA_ERROR);
    onClose();
  };

  const handleCreateBanner = async () => {
    const isValid = validateBannerData();
    if (!isValid) return;
    if (!file) {
      setError((prev) => ({ ...prev, image: t("requiredField") }));
      return;
    }
    setLoading(true);
    try {
      // Call API to create banner
      const banner = convertToBannerFormat(bannerData);
      const res = await apis.upload.uploadFile(file);
      if (res.error) throw new Error("somethingWentWrong");

      await apis.banner.createBanner({
        ...banner,
        image: res.result.urls?.[0],
      });
      toast.success(t("createBannerSuccess"));
      handleCloseDialog();
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdateBanner = async () => {
    const isValid = validateBannerData();
    if (!isValid) return;
    setLoading(true);
    try {
      let image = initBanner.image;
      if (file) {
        const res = await apis.upload.uploadFile(file);
        if (res.error) throw new Error("somethingWentWrong");
        image = res.result.urls?.[0];
      }
      const banner = convertToBannerFormat(bannerData);
      await apis.banner.updateBanner(initBanner.id, {
        ...banner,
        image,
      });
      toast.success(t("updateBannerSuccess"));
      handleCloseDialog();
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isCreateDialog && initBanner) {
      setBannerData({
        name: initBanner.name,
        appliedObjectType: initBanner.appliedObject?.type || ALL,
        appliedObjectValue: initBanner.appliedObject?.value,
      });
    }
  }, [initBanner, dialogType]);

  useEffect(() => {
    setError((prev) => ({ ...prev, image: "" }));
  }, [file]);

  return (
    <Dialog
      title={
        dialogType === DIALOG_TYPE.CREATE ? t("createBanner") : t("editBanner")
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Grid container spacing={2} mb={0}>
          <Grid item xs={12}>
            <InputTitle label={t("bannerName")} isRequired />
            <TextField
              error={!!error.name}
              helperText={error.name}
              value={bannerData.name}
              size="small"
              fullWidth
              onChange={(e) => handleChangeBannerData(e.target.value, "name")}
              placeholder={t("bannerName")}
            />
          </Grid>

          <Grid item xs={12}>
            <InputTitle label={t("bannerImage")} isRequired />
            <UploadFile
              url={bannerData.image}
              file={file}
              setFile={setFile}
              error={fileError}
              setError={setFileError}
              required={true}
            />
            <HelperText text={error.image} />
          </Grid>

          <Grid item xs={12}>
            <InputTitle label={t("appliedObject")} isRequired />
            <Autocomplete
              size="small"
              filterSelectedOptions
              value={bannerData.appliedObjectType}
              options={[ALL, ...Object.values(APPLIED_OBJECT_TYPE)]}
              getOptionLabel={(option) =>
                APPLIED_OBJECT_MAPPING[option] || t(option)
              }
              onChange={(e, newValue) =>
                handleChangeBannerData(newValue, "appliedObjectType")
              }
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <HelperText text={error.appliedObjectType} />
          </Grid>
          {bannerData.appliedObjectType &&
            bannerData.appliedObjectType !== ALL && (
              <Box
                sx={{ paddingLeft: "16px", paddingTop: "16px", width: "100%" }}
              >
                <Autocomplete
                  multiple
                  size="small"
                  filterSelectedOptions
                  value={bannerData.appliedObjectValue}
                  options={appliedOptions[bannerData.appliedObjectType] || []}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) =>
                    handleChangeBannerData(newValue, "appliedObjectValue")
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <HelperText text={error.appliedObjectValue} />
              </Box>
            )}
        </Grid>
      </DialogContent>
      <Divider />

      {/** Dialog actions */}
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={isCreateDialog ? handleCreateBanner : handleUpdateBanner}
        >
          {isCreateDialog ? t("addNew") : t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BannerDialog;
