import { Autorenew, DownloadOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import { ALL, INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  WALLET_TYPE,
} from "../../constants/wallet";
import useSearchParams from "../../hooks/useSearchParams";
import TransactionList from "./TransactionList";
import { LoadingButton } from "@mui/lab";
import { downloadFile } from "../../utils/download";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import DatePickerRange from "../../components/PickDateRange";

const INIT_FILTER = {
  status: ALL,
  walletType: ALL,
  userId: null,
  type: ALL,
  startDate: dayjs().startOf("month"),
  endDate: dayjs().endOf("month"),
  region: ALL,
};

const Transaction = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [regions, setRegions] = useState([]);

  const { addParams } = useSearchParams();

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.metadata?.name || distributor?.name}`;
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const fetchTransactions = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        userId: newFilter.userId || undefined,
        status: newFilter.status === ALL ? undefined : newFilter.status,
        walletType:
          newFilter.walletType === ALL ? undefined : newFilter.walletType,
        type: newFilter.type === ALL ? undefined : newFilter.type,
        startTime: dayjs(newFilter.startDate).startOf("day").format(),
        endTime: dayjs(newFilter.endDate).endOf("day").format(),
        region: newFilter.region === ALL ? undefined : newFilter.region,
      };

      const { result } = await apis.transaction.getTransactions(condition);
      setTransactions(result.transactions);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchDistributors = async () => {
    try {
      const { result } = await apis.user.getUsers({
        pageType: PAGE_TYPE.DISTRIBUTOR,
        sort: ["code_asc"],
      });
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchRegions = async () => {
    try {
      const { result } = await apis.region.getRegions();
      setRegions(result.map((r) => r.name));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, status: value }));
    addParams({ status: value });
  };

  const handleChangeWalletType = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, walletType: value }));
    addParams({ walletType: value });
  };

  const handleChangeType = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, type: value }));
    addParams({ type: value });
  };

  const handleChangeRegion = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, region: value }));
    addParams({ region: value });
  };

  const handleChangeUserId = (value) => {
    setFilter((prev) => ({ ...prev, userId: value }));
    addParams({ userId: value });
  };

  const handleChangeDate = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      startDate: newValue[0],
      endDate: newValue[1],
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(newValue[0]).format("YYYY-MM-DD"),
      endDate: dayjs(newValue[1]).format("YYYY-MM-DD"),
    });
  };

  const handleRefresh = () => {
    setFilter((prev) => ({
      ...prev,
      startDate: INIT_FILTER.startDate,
      endDate: INIT_FILTER.endDate,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const {
        status = INIT_FILTER.status,
        walletType = INIT_FILTER.walletType,
        userId = INIT_FILTER.userId,
        type = INIT_FILTER.type,
        startDate = dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
        endDate = dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
        region = INIT_FILTER.region,
      } = searchParams;

      await downloadFile({
        url: "/transactions/download",
        params: {
          type: type === ALL ? undefined : type,
          status: status === ALL ? undefined : status,
          walletType: walletType === ALL ? undefined : walletType,
          userId: userId || undefined,
          startTime: dayjs(startDate).startOf("day").format(),
          endTime: dayjs(endDate).endOf("day").format(),
          region: region === ALL ? undefined : region,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `transactions_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }
    setDownloading(false);
  };

  useEffect(() => {
    fetchDistributors();
    fetchRegions();
  }, []);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      status = INIT_FILTER.status,
      walletType = INIT_FILTER.walletType,
      userId = INIT_FILTER.userId,
      type = INIT_FILTER.type,
      startDate = dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
      endDate = dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
      region = INIT_FILTER.region,
    } = searchParams;
    setFilter({ type, status, walletType, userId, startDate, endDate, region });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchTransactions({
      type,
      status,
      walletType,
      userId,
      page: parseInt(page, 10),
      startDate,
      endDate,
      region,
    });
  }, [location.search]);

  return (
    <>
      <PageTitle title={t("transaction")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Autocomplete
              size="small"
              value={filter.userId}
              options={distributors.map((distributor) => distributor.id)}
              getOptionLabel={(option) => renderDistributorOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) => handleChangeUserId(newValue)}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderDistributorOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder={t("distributor")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("walletType")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.walletType}
                onChange={handleChangeWalletType}
                size="small"
                label={t("walletType")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                <MenuItem value={WALLET_TYPE.PAYMENT}>
                  {t("paymentWallet")}
                </MenuItem>
                <MenuItem value={WALLET_TYPE.COMMODITY_COMPENSATION}>
                  {t("commodityCompensationWallet")}
                </MenuItem>
                <MenuItem value={WALLET_TYPE.FREIGHT_COMPENSATION}>
                  {t("freightCompensationWallet")}
                </MenuItem>
                <MenuItem value={WALLET_TYPE.BONUS}>
                  {t("bonusWallet")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("transactionType")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.type}
                onChange={handleChangeType}
                size="small"
                label={t("transactionType")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {Object.values(TRANSACTION_TYPE).map((type) => (
                  <MenuItem key={type} value={type}>
                    {t(type.toLowerCase())}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.status}
                onChange={handleChangeStatus}
                size="small"
                label={t("status")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {Object.values(TRANSACTION_STATUS).map((status) => (
                  <MenuItem key={status} value={status}>
                    {t(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("region")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.region}
                onChange={handleChangeRegion}
                size="small"
                label={t("region")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {t("region")} {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8}>
            <Box display="flex">
              <DatePickerRange
                value={[filter.startDate, filter.endDate]}
                onChange={handleChangeDate}
              />
              <Tooltip title={t("refresh")}>
                <IconButton aria-label="refresh" onClick={handleRefresh}>
                  <Autorenew />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <LoadingButton
                loading={downloading}
                loadingPosition="start"
                variant="outlined"
                startIcon={<DownloadOutlined />}
                disabled={!transactions.length}
                onClick={handleDownload}
              >
                {t("download")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        <TransactionList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          transactions={transactions}
        />
      </MainCard>
    </>
  );
};

export default Transaction;
