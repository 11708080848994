import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { formatPhoneNumber } from "../../utils/phoneNumber";

const styles = {
  tableHeader: {
    border: "1px solid black",
    padding: "5px",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    border: "1px solid black",
    padding: "5px",
    fontSize: "14px",
    textAlign: "center",
  },
  tableCellProductName: {
    border: "1px solid black",
    padding: "5px",
    fontSize: "14px",
    textAlign: "left",
  },
  tableCellQuantity: {
    border: "1px solid black",
    padding: "5px",
    fontSize: "20px",
    textAlign: "center",
  },
  tableTotalLabel: {
    border: "1px solid black",
    padding: "5px",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableTotalValue: {
    border: "1px solid black",
    padding: "5px",
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "center",
  },
  customerInfoLabel: {
    fontSize: "14px",
    fontWeight: "normal",
    textAlign: "left",
    lineHeight: 1.2, // Giảm khoảng cách dòng
  },
  customerInfoValue: {
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    textTransform: "uppercase",
    lineHeight: 1.2, // Giảm khoảng cách dòng
  },
};

const Invoice = React.forwardRef(({ order, distributor }, ref) => {
  return (
    <div ref={ref} style={{ width: "14cm", height: "24cm" }}>
      <Box>
        {/* Header */}
        <Typography
          align="center"
          fontWeight={700}
          fontSize={18}
          sx={{ textTransform: "uppercase" }}
          paddingBottom={1}
        >
          Vela Foods
        </Typography>
        <Divider />
        <Typography
          align="center"
          fontWeight={700}
          fontSize={20}
          textTransform="uppercase"
          paddingY={1}
        >
          Phiếu xuất hàng
        </Typography>

        {/* Customer Information */}
        <Box sx={{ flexGrow: 1 }} mb={2}>
          <Grid container spacing={0.5}>
            {/* Row 1: Khách hàng và Mã KH */}
            <Grid item xs={10}>
              <Grid container spacing={0.5} alignItems="left">
                <Grid item xs={3}>
                  <Typography sx={styles.customerInfoLabel}>Khách hàng:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={styles.customerInfoValue}>
                    {distributor?.metadata?.name || distributor?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container spacing={0.5} alignItems="left">
                <Grid item xs={5}>
                  <Typography sx={styles.customerInfoLabel}>Mã:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={styles.customerInfoValue}>{distributor?.code}</Typography>
                </Grid>
              </Grid>
            </Grid>
        
            {/* Row 2: Địa chỉ */}
            <Grid item xs={12}>
              <Grid container spacing={0.5} alignItems="left">
                <Grid item xs={2}>
                  <Typography sx={styles.customerInfoLabel}>Địa chỉ:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={styles.customerInfoValue}>
                    {[
                      distributor?.metadata?.address?.addressDetail,
                      distributor?.metadata?.address?.ward,
                      distributor?.metadata?.address?.district,
                      distributor?.metadata?.address?.province,
                    ]
                      .filter((item) => item)
                      .join(", ")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
        
            {/* Row 3: Điện thoại */}
            <Grid item xs={12}>
              <Grid container spacing={0.5} alignItems="left">
                <Grid item xs={2}>
                  <Typography sx={styles.customerInfoLabel}>Điện thoại:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={styles.customerInfoValue}>
                    {formatPhoneNumber(distributor?.phoneNumber)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
        
            {/* Row 4: Ngày giao và Mã ĐH */}
            <Grid item xs={12}>
              <Grid container spacing={0.5} alignItems="left">
                <Grid item xs={2}>
                  <Typography sx={styles.customerInfoLabel}>Ngày giao:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={styles.customerInfoValue}>
                    {dayjs(order?.startShippingAt).format("YYYY-MM-DD HH:mm:ss")}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={styles.customerInfoLabel}>Mã ĐH:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={styles.customerInfoValue}>
                    {order?.distributorOrderId}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Table */}
        <Box sx={{ flexGrow: 1 }} mb={3}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "none",
            }}
          >
            <thead>
              <tr>
                <th style={styles.tableHeader}>STT</th>
                <th style={styles.tableHeader}>Tên sản phẩm</th>
                <th style={styles.tableHeader}>Đơn vị</th>
                <th style={styles.tableHeader}>Thùng</th>
              </tr>
            </thead>
            <tbody>
              {order?.items?.map((item, index) => (
                <tr key={item.productId}>
                  <td style={styles.tableCell}>{index + 1}</td>
                  <td style={styles.tableCellProductName}>{item.name}</td>
                  <td style={styles.tableCell}>Thùng</td>
                  <td style={styles.tableCellQuantity}>{item.quantity}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={3} style={styles.tableTotalLabel}>
                  Tổng
                </td>
                <td style={styles.tableTotalValue}>
                  {order?.items?.reduce((acc, item) => acc + +item.quantity, 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>

        {/* Footer */}
        <Box sx={{ flexGrow: 1 }} mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography align="center">Người nhận</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="center">Người giao</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="center">Kế toán</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
});

export default Invoice;
