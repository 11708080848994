import { SERVICE } from "../constants";
import api from "./api";

const getOaMessageTemplates = async (params) => {
  const res = await api({
    method: "GET",
    url: `/oa-message-templates`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

const createOaMessageTemplate = async (data) => {
  const res = await api({
    method: "POST",
    url: `/oa-message-templates`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const updateOaMessageTemplate = async (id, data) => {
  const res = await api({
    method: "PUT",
    url: `/oa-message-templates/${id}`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

export {
  getOaMessageTemplates,
  createOaMessageTemplate,
  updateOaMessageTemplate,
};
