import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import apis from "../../apis";
import { logout } from "../../stores/authSlice";
import CustomMenu from "../CustomMenu";
import ROUTE from "../../constants/route";

const User = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await apis.auth.logout();
      dispatch(logout());
      toast.success(t("logoutSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
    >
      <Box>
        <IconButton onClick={handleMenu} color="inherit">
          <AccountCircle color="black" fontSize="large" />
        </IconButton>
        <CustomMenu anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem onClick={() => history.push(ROUTE.PROFILE)}>
            {t("profile")}
          </MenuItem>
          <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
        </CustomMenu>
      </Box>
      <Typography fontSize="16px">{currentUser?.name}</Typography>
    </Stack>
  );
};

export default User;
