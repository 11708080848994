import { SERVICE } from "../constants";
import api from "./api";

export const getMaintainPosmActions = async (params) => {
  const res = await api({
    method: "GET",
    url: `/store-actions/maintain-posm`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

export const getCheckStorageActions = async (params) => {
  const res = await api({
    method: "GET",
    url: `/store-actions/check-storage`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

export const getNewCompetitorProductActions = async (params) => {
  const res = await api({
    method: "GET",
    url: `/store-actions/new-competitor-product`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

export const getCollectFeedbackActions = async (params) => {
  const res = await api({
    method: "GET",
    url: `/store-actions/collect-feedback`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

export const getUpdateStoreActions = async (params) => {
  const res = await api({
    method: "GET",
    url: `/store-actions/update-store`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

export const getRegisterBannerActions = async (params) => {
  const res = await api({
    method: "GET",
    url: `/store-actions/register-banner`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

export const seenStoreAction = async (storeActionId) => {
  const res = await api({
    method: "PUT",
    url: `/store-actions/${storeActionId}/seen`,
    source: SERVICE.VELA_API,
  });
  return res;
};

export const changeStatusStoreAction = async (storeActionId, data) => {
  const res = await api({
    method: "PUT",
    url: `/store-actions/${storeActionId}/change-status`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};
