import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import { validateRequired as checkRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";
import {
  UPDATE_STORE_STATUS,
  UPDATE_STORE_STATUS_LABEL,
  VISIT_FREQUENCY,
  STORE_ROUTES,
} from "../../constants/storeAction";
import { STORE_STATUS } from "../../constants/store";
import UploadFile from "../../components/UploadFile";

const INIT_DATA = {
  name: "",
  address: "",
  logo: "",
  contactPhoneNumber: "",
  storeStatus: "",
  routes: "",
  visitFrequency: "",
  deletedDisplayItems: "",
};

const UpdateStoreDialog = ({
  open,
  handleClose,
  updateStore,
  reload,
  users,
  stores,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");
  const [data, setData] = useState(INIT_DATA);
  const [dataError, setDataError] = useState(INIT_DATA);
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState(null);

  const renderUserOptions = (id) => {
    const user = users.find((u) => u.id === id);
    if (!user) return "";
    return `${user.metadata?.name || user.name}`;
  };

  const handleCloseDialog = () => {
    handleClose();
    setData(INIT_DATA);
    setDataError(INIT_DATA);
    setStatus("");
    setStatusError("");
    setFile(null);
    setFileError("");
  };

  const handleChange = (event) => {
    const { name: field, value } = event.target;
    setData((prev) => ({ ...prev, [field]: value }));
    setDataError((prev) => ({ ...prev, [field]: "" }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(data[field])) {
      setDataError((prev) => ({ ...prev, [field]: t("fieldRequired") }));
      return false;
    }
    return true;
  };

  const validateStatusRequired = () => {
    if (!checkRequired(status)) {
      setStatusError(t("fieldRequired"));
      return false;
    }
    return true;
  };

  const validateRoutesRequired = () => {
    if (!data.routes.length) {
      setDataError((prev) => ({ ...prev, routes: t("fieldRequired") }));
      return false;
    }
    return true;
  };

  const validate = () =>
    validateRequired("name") &&
    validateRequired("contactPhoneNumber") &&
    validateRequired("address") &&
    (data.storeStatus !== STORE_STATUS.COLLABORATING ||
      validateRoutesRequired()) &&
    validateStatusRequired();

  const isEditable = useMemo(() => {
    return updateStore?.detail?.status === UPDATE_STORE_STATUS.NEW;
  }, [updateStore]);

  const store = useMemo(() => {
    return stores.find((s) => s.id === updateStore.storeId) || {};
  }, [stores, updateStore]);

  const handleUpdate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      let logo = data.logo;
      if (file && status === UPDATE_STORE_STATUS.APPROVED) {
        const res = await apis.upload.uploadFile(file);
        if (res.error) throw new Error("somethingWentWrong");
        logo = res.result.urls[0];
      }

      const detail = {
        ...data,
        logo,
        displayItems:
          store?.displayItems.filter(
            (item) => !data?.deletedDisplayItems?.includes(item.code)
          ) || undefined,
        ...(data.storeStatus === STORE_STATUS.COLLABORATING
          ? { routes: data.routes?.map((route) => ({ name: route })) }
          : { routes: undefined }),
        ...(data.storeStatus === STORE_STATUS.COLLABORATING
          ? { visitFrequency: data.visitFrequency }
          : { visitFrequency: undefined }),
      };

      await apis.storeAction.changeStatusStoreAction(updateStore.id, {
        status,
        ...(status === UPDATE_STORE_STATUS.APPROVED ? { detail } : {}),
      });
      handleCloseDialog();
      reload();
      toast.success(t("updateStoreSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const renderDisplayItems = (code) => {
    const item = store?.displayItems?.find((item) => item.code === code);
    return item ? `${item.itemName} (${item.code})` : code;
  };

  useEffect(() => {
    if (open) {
      const initData = { ...updateStore.detail };
      if (initData.routes)
        initData.routes = initData.routes.map((route) => route.name);

      setData(initData);
    }
  }, [open]);

  return (
    <Dialog
      title={t("updateStore")}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(updateStore).length && (
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("storeName")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={dataError.name}
                error={!!dataError.name}
                value={data.name}
                size="small"
                name="name"
                fullWidth
                onChange={handleChange}
                onBlur={() => validateRequired("name")}
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("contactPhoneNumber")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={dataError.contactPhoneNumber}
                error={!!dataError.contactPhoneNumber}
                value={data.contactPhoneNumber}
                size="small"
                name="contactPhoneNumber"
                fullWidth
                onChange={handleChange}
                onBlur={() => validateRequired("contactPhoneNumber")}
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={1}>
                {t("address")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={dataError.address}
                error={!!dataError.address}
                value={data.address}
                size="small"
                name="address"
                fullWidth
                onChange={handleChange}
                onBlur={() => validateRequired("address")}
                multiline
                rows={2}
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={1}>
                {t("storeStatus")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <FormControl fullWidth error={!!dataError.storeStatus}>
                <Select
                  value={data.storeStatus}
                  size="small"
                  name="storeStatus"
                  onChange={handleChange}
                  disabled={!isEditable}
                >
                  <MenuItem value={STORE_STATUS.COLLABORATING}>
                    {t("collaborating")}
                  </MenuItem>
                  <MenuItem value={STORE_STATUS.NOT_COLLABORATING}>
                    {t("not_collaborating")}
                  </MenuItem>
                  <MenuItem value={STORE_STATUS.STOPPED}>
                    {t("stopped_collaborating")}
                  </MenuItem>
                </Select>
                <FormHelperText>{dataError.storeStatus}</FormHelperText>
              </FormControl>
            </Grid>
            {data.storeStatus === STORE_STATUS.COLLABORATING && (
              <>
                <Grid item xs={12} sm={8}>
                  <Typography fontWeight={500} mb={1}>
                    {t("routes")}{" "}
                    <span style={{ color: COLOR.error.base }}>*</span>:
                  </Typography>
                  <Autocomplete
                    multiple
                    size="small"
                    value={data.routes || []}
                    options={Object.values(STORE_ROUTES)}
                    filterSelectedOptions
                    onChange={(event, newValue) => {
                      setData((prev) => ({ ...prev, routes: newValue }));
                      setDataError((prev) => ({ ...prev, routes: "" }));
                    }}
                    onBlur={validateRoutesRequired}
                    renderOption={(props, key) => (
                      <MenuItem value={key} {...props}>
                        {key}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!dataError.routes}
                        helperText={dataError.routes}
                        fullWidth
                      />
                    )}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography fontWeight={500} mb={1}>
                    {t("visitFrequency")}{" "}
                    <span style={{ color: COLOR.error.base }}>*</span>:
                  </Typography>
                  <FormControl fullWidth error={!!dataError.visitFrequency}>
                    <Select
                      value={data.visitFrequency}
                      size="small"
                      name="visitFrequency"
                      onChange={handleChange}
                      disabled={!isEditable}
                    >
                      {Object.values(VISIT_FREQUENCY).map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{dataError.visitFrequency}</FormHelperText>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={1}>
                {t("frontImage")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <UploadFile
                url={data.logo}
                file={file}
                setFile={setFile}
                error={fileError}
                setError={setFileError}
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500}>
                {t("deleteDisplayItems")}:
              </Typography>
              <Autocomplete
                multiple
                size="small"
                value={data.deletedDisplayItems || []}
                options={
                  store?.displayItems
                    ?.filter((item) => item.code)
                    ?.map((item) => item.code) || []
                }
                getOptionLabel={(option) => renderDisplayItems(option)}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  setData((prev) => ({
                    ...prev,
                    deletedDisplayItems: newValue,
                  }));
                  setDataError((prev) => ({
                    ...prev,
                    deletedDisplayItems: "",
                  }));
                }}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {renderDisplayItems(key)}
                  </MenuItem>
                )}
                renderInput={(params) => <TextField {...params} fullWidth />}
                disabled={!isEditable}
              />
            </Grid>
            {data.reason && (
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500}>{t("reason")}:</Typography>
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {data.reason}
                </Typography>
              </Grid>
            )}
            {data.note && (
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500}>{t("note")}:</Typography>
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {data.note}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500}>{t("createdBy")}:</Typography>
              <Typography sx={{ color: COLOR.secondary.base }}>
                {renderUserOptions(updateStore.userId)}
              </Typography>
            </Grid>
            {!isEditable && (
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500}>{t("status")}:</Typography>
                <Typography sx={{ color: COLOR.secondary.base }}>
                  {t(UPDATE_STORE_STATUS_LABEL[data.status])}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {isEditable && (
          <>
            <Divider />
            <Box mt={3}>
              <Typography fontWeight={500} mb={1}>
                {t("review")} <span style={{ color: COLOR.error.base }}>*</span>
                :
              </Typography>
              <FormControl fullWidth error={!!statusError}>
                <Select
                  value={status}
                  size="small"
                  onChange={(event) => {
                    setStatus(event.target.value);
                    setStatusError("");
                  }}
                >
                  <MenuItem value={UPDATE_STORE_STATUS.APPROVED}>
                    {t(UPDATE_STORE_STATUS_LABEL[UPDATE_STORE_STATUS.APPROVED])}
                  </MenuItem>
                  <MenuItem value={UPDATE_STORE_STATUS.REJECTED}>
                    {t(UPDATE_STORE_STATUS_LABEL[UPDATE_STORE_STATUS.REJECTED])}
                  </MenuItem>
                </Select>
                <FormHelperText>{statusError}</FormHelperText>
              </FormControl>
            </Box>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {isEditable && (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdate}
          >
            {t("confirm")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UpdateStoreDialog;
