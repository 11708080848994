import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Add, Search } from "@mui/icons-material";
import { Button, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import apis from "../../apis";
import PageTitle from "../../components/PageTitle";
import MainCard from "../../components/MainCard";
import { DIALOG_TYPE, INITIAL_PAGING } from "../../constants";
import debounce from "../../utils/debounce";
import useSearchParams from "../../hooks/useSearchParams";
import TemplateDialog from "./TemplateDialog";
import OaMessageTemplateList from "./OaMessageTemplateList";

const INIT_FILTER = {
  search: "",
};

const INIT_TEMPLATE = {
  title: "",
  description: "",
  items: [],
  openMiniApp: false,
};

const OaMessageTemplate = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [filter, setFilter] = useState(INIT_FILTER);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [template, setTemplate] = useState(INIT_TEMPLATE);
  const [reload, setReload] = useState(false);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleOpenDialog = (dt, currentTemplate) => {
    setDialogType(dt);
    if (currentTemplate) {
      const initTemplate = {
        title: currentTemplate.title,
        description: currentTemplate.description,
        items: currentTemplate.items,
        openMiniApp: currentTemplate.openMiniApp,
        id: currentTemplate.id,
      };
      setTemplate(initTemplate);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTemplate(INIT_TEMPLATE);
    setDialogType("");
  };

  const fetchTemplates = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        search: newFilter.search || undefined,
      };

      const { result } = await apis.oaMessageTemplate.getOaMessageTemplates(
        condition
      );
      setTemplates(result.oaMessageTemplates);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { page = INITIAL_PAGING.page, search = INIT_FILTER.search } =
      searchParams;
    setFilter({ search });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchTemplates({
      search,
      page: parseInt(page, 10),
    });
  }, [location.search, reload]);

  return (
    <>
      <PageTitle title={t("oaMessageTemplate")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("search")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={6} lg={8}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleOpenDialog(DIALOG_TYPE.CREATE)}
              >
                {t("addTemplate")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <OaMessageTemplateList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          templates={templates}
          handleOpenDialog={handleOpenDialog}
        />
      </MainCard>
      <TemplateDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogType={dialogType}
        initTemplate={template}
        reload={() => setReload((prev) => !prev)}
      />
    </>
  );
};

export default OaMessageTemplate;
