const ORDER_STATUS = {
  AWAITING_CONFIRMATION: "AWAITING_CONFIRMATION",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  CANCEL: "CANCEL",
};

const ORDER_STATUS_MAPPING = {
  [ORDER_STATUS.AWAITING_CONFIRMATION]: "awaiting_confirmation",
  [ORDER_STATUS.IN_PROGRESS]: "in_progress",
  [ORDER_STATUS.COMPLETED]: "completed",
  [ORDER_STATUS.CANCEL]: "canceled",
};

const DISTRIBUTOR_ORDER_STATUS = {
  INIT: "INIT",
  AWAITING_ASM_CONFIRMATION: "AWAITING_ASM_CONFIRMATION",
  AWAITING_ADMIN_CONFIRMATION: "AWAITING_ADMIN_CONFIRMATION",
  AWAITING_PAYMENT: "AWAITING_PAYMENT",
  AWAITING_SHIPPING: "AWAITING_SHIPPING",
  SHIPPING: "SHIPPING",
  COMPLETED: "COMPLETED",
  CANCEL: "CANCEL",
};

const DISTRIBUTOR_ORDER_STATUS_LABEL = {
  [DISTRIBUTOR_ORDER_STATUS.INIT]: "initOrder",
  [DISTRIBUTOR_ORDER_STATUS.AWAITING_ADMIN_CONFIRMATION]:
    "waitSaleAdminConfirm",
  [DISTRIBUTOR_ORDER_STATUS.AWAITING_ASM_CONFIRMATION]: "waitAsmConfirm",
  [DISTRIBUTOR_ORDER_STATUS.AWAITING_PAYMENT]: "waitPayment",
  [DISTRIBUTOR_ORDER_STATUS.AWAITING_SHIPPING]: "waitShipment",
  [DISTRIBUTOR_ORDER_STATUS.SHIPPING]: "shipping",
  [DISTRIBUTOR_ORDER_STATUS.COMPLETED]: "completed",
  [DISTRIBUTOR_ORDER_STATUS.CANCEL]: "cancelled",
};

const DISTRIBUTOR_ORDER_STATUS_COLORS = {
  [DISTRIBUTOR_ORDER_STATUS.INIT]: "#FF7800",
  [DISTRIBUTOR_ORDER_STATUS.AWAITING_DISTRIBUTOR_CONFIRMATION]: "#FF7800",
  [DISTRIBUTOR_ORDER_STATUS.AWAITING_ADMIN_CONFIRMATION]: "#FF7800",
  [DISTRIBUTOR_ORDER_STATUS.AWAITING_ASM_CONFIRMATION]: "#FF7800",
  [DISTRIBUTOR_ORDER_STATUS.AWAITING_PAYMENT]: "#DC1F18",
  [DISTRIBUTOR_ORDER_STATUS.AWAITING_SHIPPING]: "#006AF5",
  [DISTRIBUTOR_ORDER_STATUS.SHIPPING]: "#3DAC2B",
  [DISTRIBUTOR_ORDER_STATUS.COMPLETED]: "#3DAC2B",
  [DISTRIBUTOR_ORDER_STATUS.CANCEL]: "#B9BDC1",
};

const SHIPPING_METHOD = {
  SEND: "SEND",
  SELF_PICK_UP: "SELF_PICK_UP",
};

const SHIPPING_METHOD_LABEL = {
  [SHIPPING_METHOD.SEND]: "sendGoods",
  [SHIPPING_METHOD.SELF_PICK_UP]: "selfPickUpGoods",
};

export {
  ORDER_STATUS,
  ORDER_STATUS_MAPPING,
  DISTRIBUTOR_ORDER_STATUS,
  DISTRIBUTOR_ORDER_STATUS_LABEL,
  DISTRIBUTOR_ORDER_STATUS_COLORS,
  SHIPPING_METHOD,
  SHIPPING_METHOD_LABEL,
};
