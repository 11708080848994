import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { EditNote } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";

import Table from "../../components/Table";
import { REGISTER_BANNER_STATUS_LABEL } from "../../constants/storeAction";
import ImagePreview from "../../components/ImagePreview";

const RegisterBannerList = ({
  loading,
  paging,
  handleChangePage,
  registerBanners,
  users,
  stores,
  handleOpenDialog,
}) => {
  const { t } = useTranslation();

  const renderUser = ({ userId }) => {
    const user = users.find((u) => u.id === userId);
    if (user) return `${user.metadata?.name || user.name}`;

    return "-";
  };

  const renderStore = ({ storeId }) => {
    const store = stores.find((s) => s.id === storeId);
    return store?.name || "-";
  };

  const columns = [
    {
      title: t("store"),
      align: "left",
      render: (row) => renderStore(row),
    },
    {
      title: t("createdBy"),
      align: "left",
      render: (row) => renderUser(row),
    },
    {
      title: t("contactInfo"),
      align: "left",
      render: (row) => row.detail.contact,
    },
    {
      title: t("image"),
      align: "center",
      render: (row) => (
        <ImagePreview
          galleryID="custom-image-gallery-12345"
          images={[
            ...(row.detail?.primaryImage
              ? [
                  {
                    url: row.detail?.primaryImage,
                  },
                ]
              : []),
          ]}
        />
      ),
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => t(REGISTER_BANNER_STATUS_LABEL[row.detail.status]),
    },
    {
      title: t("createdAt"),
      align: "left",
      render: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("review")}>
              <span>
                <IconButton
                  onClick={() => {
                    handleOpenDialog(row);
                  }}
                  color="primary"
                >
                  <EditNote />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={registerBanners}
        columns={columns}
        total={paging.total}
        page={paging.page}
        limit={paging.limit}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default RegisterBannerList;
