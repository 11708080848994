import { SERVICE } from "../constants";
import api from "./api";

const getExceptionRates = async (distributorId) => {
  const res = await api({
    method: "GET",
    url: `/exception-rates`,
    params: { distributorId },
    source: SERVICE.VELA_API,
  });
  return res;
};

const updateExceptionRates = async (data) => {
  const res = await api({
    method: "PUT",
    url: `/exception-rates`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

export { getExceptionRates, updateExceptionRates };
