import { useTranslation } from "react-i18next";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import { formatNumber } from "../../utils/number";
import { Edit, LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { DIALOG_TYPE } from "../../constants";
import ImagePreview from "../../components/ImagePreview";

const ProductList = ({
  loading,
  paging,
  handleChangePage,
  handleOpenDialog,
  handleOpenPopup,
  products,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("image"),
      align: "center",
      field: "image",
      render: (row) => (
        <ImagePreview
          galleryID="custom-image-gallery-12345"
          images={[
            {
              url: row.image,
            },
          ]}
        />
      ),
    },
    {
      title: t("code"),
      align: "left",
      field: "code",
      sortable: true,
    },
    {
      title: t("name"),
      align: "left",
      field: "name",
      sortable: true,
    },
    {
      title: t("category"),
      align: "left",
      render: (row) => row.productCategory?.name || "-",
    },
    {
      title: t("weight"),
      align: "center",
      render: (row) => `${row.weight}g`,
      field: "weight",
      sortable: true,
    },
    {
      title: t("productsPerBox"),
      align: "center",
      field: "productPerBox",
      sortable: true,
    },
    {
      title: t("price"),
      align: "right",
      render: (row) => formatNumber(row.price),
      sortable: true,
    },
    {
      title: t("discountType"),
      align: "center",
      field: "discountType",
    },
    {
      title: t("sizeType"),
      align: "center",
      field: "sizeType",
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => (
        <>
          {row.active ? (
            <Typography sx={{ color: COLOR.success.base }}>
              {t("active")}
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.error.base }}>
              {t("inactive")}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  onClick={() => handleOpenDialog(DIALOG_TYPE.UPDATE, row)}
                  color="edit"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={row.active ? t("lock") : t("unlock")}>
              <span>
                <IconButton
                  onClick={() => handleOpenPopup(row)}
                  color={row.active ? "error" : "success"}
                >
                  {row.active ? <LockOutlined /> : <LockOpenOutlined />}
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={products}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default ProductList;
