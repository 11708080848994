import { SERVICE } from "../constants";
import api from "./api";

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await api({
    method: "POST",
    url: `/uploads/file`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    source: SERVICE.VELA_UPLOAD,
  });
  return res;
};
